import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components/macro";
import useWindowSize from "helpers/useWindowSize";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import LeftNav from "./LeftNav";
import RightNav from "./RightNav";
import Fullscreen from "./Fullscreen";
import useIntersection from "helpers/useIntersection";

const CustomImageGallery = ({ items }) => {
  const windowSize = useWindowSize();

  const [hovered, setHovered] = useState(false);
  const [galleryHeight, setGalleryHeight] = useState();

  const galleryWrapperRef = useRef();
  const galleryRef = useRef();
  const inViewport = useIntersection(galleryWrapperRef, "0px", 0.9);

  useEffect(() => {
    const size = windowSize;
    setGalleryHeight(galleryWrapperRef.current.offsetWidth * 0.8);
  }, [windowSize]);

  const onClickImage = () => {
    galleryRef.current.fullScreen();
  };

  const onHover = () => {
    setHovered(true);
  };

  const onLeaveHover = () => {
    setHovered(false);
  };

  const onImageLoad = (e) => {};

  return (
    <ImageGalleryWrapper
      ref={galleryWrapperRef}
      hovered={hovered}
      height={galleryHeight}
    >
      <ImageGallery
        ref={galleryRef}
        items={items}
        disableKeyDown={!inViewport}
        showPlayButton={false}
        lazyLoad={true}
        // onImageLoad={onImageLoad}
        renderLeftNav={(onClick, disabled) => (
          <StyledLeftNav onClick={onClick} disabled={disabled} />
        )}
        renderRightNav={(onClick, disabled) => (
          <StyledRightNav onClick={onClick} disabled={disabled} />
        )}
        renderFullscreenButton={(onClick, isFullscreen) => (
          <Fullscreen onClick={onClick} isFullscreen={isFullscreen} />
        )}
        onClick={onClickImage}
        onMouseOver={onHover}
        onMouseLeave={onLeaveHover}
      />
    </ImageGalleryWrapper>
  );
};

const ImageGalleryWrapper = styled.div`
  .image-gallery-content:not(.fullscreen) {
    .image-gallery-slides {
      height: ${({ height }) => `${height - 100}px`};

      @media screen and (max-width: 768px) {
        height: ${({ height }) => `${height - 60}px`};
      }
    }
  }

  .image-gallery-content :not(.fullscreen) {
    .image-gallery-slide,
    .image-gallery-image {
      max-height: unset;
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }

  && .image-gallery-icon {
    filter: drop-shadow(0px 0px 2px rgb(0 0 0 / 0.6));

    :hover {
      color: black;
      /* filter: drop-shadow(0px 0px 5px rgb(255 255 255 / 1)); */
    }
  }

  .image-gallery-content {
    .image-gallery-slide .image-gallery-image {
      max-height: calc(100vh - 120px); // 80 px for the thumbnail space
    }
  }

  .image-gallery-content.fullscreen {
    background: white;
  }

  .image-gallery.fullscreen-modal {
    background: white;
  }

  .image-gallery-content:not(.fullscreen) {
    .image-gallery-slide.center {
      cursor: pointer;

      .image-gallery-image {
        transform: scale(${({ hovered }) => (hovered ? 1.1 : 1)});
        transition: all 1s;
      }
    }
  }

  && .image-gallery-thumbnail {
    height: 100px;
    border: 2px solid transparent;

    @media screen and (max-width: 768px) {
      height: 78px;
    }

    .image-gallery-thumbnail-inner {
      height: 96px;
      padding: 2px;

      @media screen and (max-width: 768px) {
        height: 74px;
      }

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        object-position: 50% 50%;
      }
    }
  }
  && .image-gallery-thumbnail.active {
    border: 2px solid black;
  }
`;

const StyledLeftNav = styled(LeftNav)``;
const StyledRightNav = styled(RightNav)``;

export default CustomImageGallery;
