import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";
import useIntersection from "helpers/useIntersection";
import { useTrail, animated } from "react-spring";
import { Repeat } from "@mui/icons-material";

const KeyTakeaways = ({ takeaways }) => {
  const { i18n } = useTranslation();

  const ref = useRef();
  const inViewport = useIntersection(ref, "0px", 0.4);

  const cardAnimation = useTrail(takeaways?.length || 0, {
    from: { translateY: "100vh" },
    to: { translateY: "0" },
    pause: !inViewport,
    delay: 200
  });

  return (
    <Wrapper ref={ref}>
      <Title>
        {i18n.language === "en" ? (
          <>
            KEY <span>TAKEAWAYS</span>
          </>
        ) : (
          <>
            Principaux <span>points à retenir</span>
          </>
        )}
      </Title>
      <Body count={takeaways?.length || 0}>
        {takeaways != null &&
          cardAnimation.map((animations, animationIndex) => (
            <AnimatedCard
              key={takeaways?.[animationIndex].title}
              style={animations}
            >
              <CardImageWrapper>
                <img src={takeaways?.[animationIndex].imageURL} alt='' />
              </CardImageWrapper>
              <CardTitle>{takeaways?.[animationIndex].title}</CardTitle>
              <CardPoints>
                <ul>
                  {takeaways?.[animationIndex].keyPoints.map(
                    (item, keyPointsIndex) => {
                      return item.subBullets == null ? (
                        <li
                          key={`${takeaways?.[animationIndex].title}-${keyPointsIndex}-bullet`}
                        >
                          {item.bullet}
                        </li>
                      ) : (
                        <li
                          key={`${takeaways[animationIndex].title}-${keyPointsIndex}-bulletWithSubBullets`}
                        >
                          {item.bullet}
                          <ul>
                            {item.subBullets.map((subItem, subItemIndex) => (
                              <li
                                key={`${takeaways[animationIndex].title}-${subItemIndex}-subbullet`}
                              >
                                {subItem.bullet}
                              </li>
                            ))}
                          </ul>
                        </li>
                      );
                    }
                  )}
                </ul>
              </CardPoints>
            </AnimatedCard>
          ))}
      </Body>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  padding: 50px 0;
  display: grid;
  grid-template-rows: auto 1fr;

  /* @media screen and (max-width: 900px) {
    padding: 10px 0;
  } */
`;

const Title = styled.div`
  text-align: center;
  font-size: 5vw;
  padding: 50px;
  margin-bottom: 20px;
  color: white;
  background-color: black;
  text-transform: uppercase;

  span {
    font-family: "adiNeue PRO Condensed Light";
  }

  @media screen and (max-width: 900px) {
    font-size: 12vw;
  }
`;

const Body = styled.div`
  display: grid;
  grid-template-columns: ${({ count }) => `repeat(${count}, 1fr)`};
  grid-gap: 20px;
  padding: 0 50px;

  @media screen and (max-width: 900px) {
    grid-template-columns: unset;
    padding: 10px 0;
  }
`;

const Card = styled.div`
  justify-self: center;
  max-width: 33vw;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width: 900px) {
    :not(:last-child) {
      border-bottom: 1px solid black;
    }
  }
`;
const AnimatedCard = animated(Card);

const CardImageWrapper = styled.div`
  width: 100%;

  img {
    width: 100%;
  }
`;

const CardTitle = styled.div`
  font-size: 3vw;

  @media screen and (max-width: 900px) {
    font-size: 8vw;
  }
`;

const CardPoints = styled.div`
  width: 75%;
  padding: 15px;
  font-family: "adiNeue PRO Condensed Regular";
  font-size: 2.6vh;

  @media screen and (max-width: 1500px) {
    width: 100%;
  }

  ul,
  li {
    font-family: "adiNeue PRO Condensed Regular";
    /* font-size: 2vh; */
  }

  ul {
    padding: 0 25px;
  }

  li {
    padding: 3px 0px;
  }
`;

export default KeyTakeaways;
