import styled from "styled-components/macro";
import {
  COLORS,
  CONSTANTS,
  FONTSIZE,
  FONTWEIGHT
} from "helpers/designConstants";

export const Button = styled.button`
  flex-shrink: 0;
  text-decoration: none;
  border: none;
  text-transform: uppercase;
  opacity: ${({ disabled }) => {
    return disabled && 0.3;
  }};
  background: ${({ dark, destructive, cancel, highlighted }) => {
    return (
      (destructive && COLORS.primaryInverted) ||
      (cancel && COLORS.primaryInverted) ||
      (highlighted && COLORS.green) ||
      (dark && COLORS.darkButton) ||
      COLORS.primaryInverted
    );
  }};
  color: ${({ destructive, cancel, highlighted }) => {
    return (
      (destructive && COLORS.alertRed) ||
      (highlighted && COLORS.primaryInverted) ||
      (cancel && COLORS.primary) ||
      COLORS.primary
    );
  }};
  border: 1px solid
    ${({ destructive, cancel, highlighted }) => {
      return (
        (destructive && COLORS.alertRed) ||
        (cancel && COLORS.primaryInverted) ||
        (highlighted && COLORS.green) ||
        COLORS.primaryInverted
      );
    }};
  border-radius: ${CONSTANTS.borderRadius};
  padding: 13px 12px 11px 12px;
  font-size: ${(props) =>
    props.fontsize ? FONTSIZE[props.fontsize] : FONTSIZE.small};
  font-weight: ${FONTWEIGHT.heavy};
  height: var(--height);
  width: var(--width);
  margin: var(--margin);
  transition: 0.3s;
  cursor: pointer;
  cursor: ${({ disabled }) => {
    return disabled ? "not-allowed" : "pointer";
  }};
  &:hover {
    background: ${({ disabled, destructive, cancel, highlighted }) => {
      return (
        (destructive && COLORS.alertRed) ||
        (cancel && COLORS.primary) ||
        (highlighted && COLORS.green) ||
        (disabled && COLORS.primaryInverted) ||
        COLORS.primary
      );
    }};
    border-color: ${({ cancel }) => {
      return (cancel && COLORS.primary) || COLORS.primaryInverted;
    }};
    color: ${({ disabled, destructive, cancel }) => {
      return (
        (destructive && COLORS.primaryInverted) ||
        (cancel && COLORS.primaryInverted) ||
        (disabled && COLORS.primary) ||
        COLORS.primaryInverted
      );
    }};
  }
`;
