import React, { useRef, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";

import { useDatabase } from "data/useDatabase";
import { useLocation, useNavigate } from "react-router-dom";

import { useSpring, animated } from "react-spring";
import useIntersection from "helpers/useIntersection";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";

const NavFooter = ({ className }) => {
  const { i18n } = useTranslation();
  const { pages } = useDatabase();
  const location = useLocation();
  const navigate = useNavigate();

  const ref = useRef();
  const inViewport = useIntersection(ref, "0px");

  const [pageIndex, setPageIndex] = useState(0);

  useEffect(() => {
    setPageIndex(pages.findIndex((page) => page.link === location.pathname));
  }, [location.pathname, pages]);

  const leftSlide = useSpring({
    to: { translateX: "0" },
    from: { translateX: "-60vw" },
    config: { mass: 1, tension: 180, friction: 20 },
    pause: !inViewport,
    delay: 800
  });

  const rightSlide = useSpring({
    to: { translateX: "0" },
    from: { translateX: "60vw" },
    config: { mass: 1, tension: 180, friction: 20 },
    pause: !inViewport,
    delay: 400
  });

  const onUserSelectedItem = (index) => {
    navigate(pages[index].link);
  };

  return (
    <Wrapper ref={ref} className={className}>
      {pageIndex > 0 ? (
        <AminatedLeftSide
          style={leftSlide}
          onClick={() => onUserSelectedItem(pageIndex - 1)}
        >
          <ArrowLeftIcon sx={{ fontSize: "40px" }} />
          <NavText side='left'>
            {
              pages[pageIndex - 1]?.[
                i18n.language === "en" ? "name" : "name_fr"
              ]
            }
          </NavText>
        </AminatedLeftSide>
      ) : (
        <div></div>
      )}
      {pageIndex !== pages.length - 1 ? (
        <AnimatedRightSide
          style={rightSlide}
          onClick={() => onUserSelectedItem(pageIndex + 1)}
        >
          <NavText side='right'>
            {
              pages[pageIndex + 1]?.[
                i18n.language === "en" ? "name" : "name_fr"
              ]
            }
          </NavText>
          <ArrowRightIcon sx={{ fontSize: "40px" }} />
        </AnimatedRightSide>
      ) : (
        <div></div>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 35px;
  background-color: black;
`;

const NavText = styled.div`
  transition: all 0.3s;
  :hover {
    padding-left: 30px;
    padding-right: 30px;
  }

  @media screen and (max-width: 500px) {
    font-size: 25px;
    :hover {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
`;

const LeftSide = styled.div`
  width: 40%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: white;
  padding: 40px 0px 40px 30px;
  filter: drop-shadow(0px 0px 2px rgb(0 0 0 / 0.8));
  cursor: pointer;
  text-transform: uppercase;

  @media screen and (max-width: 500px) {
    padding: 40px 0px 40px 15px;
  }
`;
const AminatedLeftSide = animated(LeftSide);

const RightSide = styled.div`
  width: 40%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  text-align: right;
  color: white;
  padding: 40px 15px 40px 0px;
  filter: drop-shadow(0px 0px 2px rgb(0 0 0 / 0.8));
  cursor: pointer;
  text-transform: uppercase;

  @media screen and (max-width: 500px) {
    padding: 40px 15px 40px 0px;
  }
`;
const AnimatedRightSide = animated(RightSide);

export default NavFooter;
