import React from "react";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";

const Fullscreen = ({ onClick, isFullscreen, className }) => {
  return (
    <button
      type='button'
      className={`image-gallery-icon image-gallery-fullscreen-button ${className}`}
      onClick={onClick}
      aria-label='Open Fullscreen'
    >
      {isFullscreen ? (
        <FullscreenExitIcon sx={{ fontSize: "50px" }} />
      ) : (
        <FullscreenIcon sx={{ fontSize: "45px" }} />
      )}
    </button>
  );
};

export default Fullscreen;
