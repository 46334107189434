import { hsla } from "@react-spring/shared";
import React from "react";
import styled from "styled-components/macro";

const ImageMask = ({ maskOpacity = 0.98, className }) => {
  return <Mask className={className} maskOpacity={maskOpacity} />;
};

const Mask = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  /* background-color: hsla(0, 0%, 0%, 0.98);
  mask-image: linear-gradient(transparent, black 40% 60%, transparent); */
  background-color: ${({ maskOpacity }) => `hsla(0, 0%, 0%, ${maskOpacity})`};
  mask-image: radial-gradient(circle at center, transparent 0px, black 80%);

  /* div {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  } */
`;

export default ImageMask;
