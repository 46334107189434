import React, { useRef, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components/macro";
import { COLORS, FONTWEIGHT, CONSTANTS } from "helpers/designConstants";
import useHover from "helpers/useHover";
import useOnClickOutside from "helpers/useOnClickOutside";

import { animated, useSpring } from "react-spring";

import { useDatabase } from "data/useDatabase";

const topLines = [
  "M0,0L0,10L78,10L78,0L0,0Z",
  "M48.9,0 L34.2,8.5 L54.7,44 L74.4,44 L48.9,0Z"
];
const middleLines = [
  "M0,22L0,32L78,32L78,22L0,22Z",
  "M31.9,15.5 L17.2,23.9 L28.8,44 L48.5,44 L31.9,15.5Z"
];
const bottomLines = [
  "M0,44L0,54L78,54L78,44L0,44Z",
  "M14.7,30.5 L0,39 L2.9,44 L22.5,44 L14.7,30.5Z"
];

const NavMenu = ({ className }) => {
  //STATE
  const [isActive, setIsActive] = useState(false);

  const menuRef = useRef();

  //HOOKS
  const { i18n } = useTranslation();
  useOnClickOutside(menuRef, () => setIsActive(false));
  const location = useLocation();
  const navigate = useNavigate();
  const { pages } = useDatabase();

  const [hoverRef, isHovered] = useHover();
  const topLineAnimationProps = useSpring({
    topLine: topLines[isHovered || isActive ? 0 : 1],
    config: { duration: 200 }
  });
  const middleLineAnimationProps = useSpring({
    middleLine: middleLines[isHovered || isActive ? 0 : 1],
    config: { duration: 200 }
  });
  const bottomLineAnimationProps = useSpring({
    bottomLine: bottomLines[isHovered || isActive ? 0 : 1],
    config: { duration: 200 }
  });

  const menuSlide = useSpring({
    to: { translateX: isActive ? "0" : "-100vw" },
    from: { translateX: isActive ? "-100vw" : "0" },
    config: { mass: 1, tension: 180, friction: 20 }
  });

  //REFS
  // const dropdownRef = useRef(null);

  const onUserSelectedItem = (index) => {
    navigate(pages[index].link);
    setIsActive(false);
  };

  const userClicked = () => {
    setIsActive(!isActive);
  };

  return (
    <OuterContainer>
      <Wrapper ref={hoverRef} className={className}>
        <DropDownContainer ref={menuRef}>
          {/* <div onClick={() => setIsActive(!isActive)}> */}
          <MenuIcon onClick={userClicked}>
            <svg
              version='1.1'
              id='Layer_1'
              xmlns='http://www.w3.org/2000/svg'
              x='0px'
              y='0px'
              viewBox='0 0 74.4 80'
              enableBackground='new 0 0 74.4 80'
            >
              <animated.path fill='#FFFFFF' d={topLineAnimationProps.topLine} />
              <animated.path
                fill='#FFFFFF'
                d={middleLineAnimationProps.middleLine}
              />
              <animated.path
                fill='#FFFFFF'
                d={bottomLineAnimationProps.bottomLine}
              />
            </svg>
          </MenuIcon>
          <AnimatedContent
            // ref={dropdownRef}
            isActive={isActive}
            style={menuSlide}
          >
            <ul>
              {pages?.map((option, index) => (
                <MenuItem
                  key={option.link}
                  selected={location.pathname === option.link}
                  onClick={() => onUserSelectedItem(index)}
                >
                  {i18n.language === "en" ? option.name : option.name_fr}
                </MenuItem>
              ))}
            </ul>
          </AnimatedContent>
        </DropDownContainer>
      </Wrapper>
    </OuterContainer>
  );
};

const OuterContainer = styled.div`
  position: fixed;
  top: 20px;
  left: 20px;
  z-index: 1000;
  color: white;
`;

const Wrapper = styled.div`
  width: 40px;
  height: 40px;

  opacity: 0.9;
  cursor: pointer;
`;

const MenuIcon = styled.div`
  svg {
    filter: drop-shadow(0px 0px 2px rgb(0 0 0 / 1));
  }
`;

const DropDownContainer = styled.div`
  position: relative;
`;

const Content = styled.div`
  text-align: left;
  background: #ffffff;
  border-radius: 2px;
  position: absolute;
  z-index: 1000;
  top: 35px;
  left: 0;
  box-shadow: 0 0px 4px rgba(0, 0, 0, 1);
  white-space: nowrap;
  overflow: scroll;
  max-height: 70vh;

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
`;
const AnimatedContent = animated(Content);

const MenuItem = styled.li`
  padding: 15px 20px;
  cursor: pointer;
  color: ${COLORS.primary};
  font-size: 25px;
  text-transform: uppercase;
  background-color: ${({ selected }) => selected && COLORS.text};

  :not(:last-child) {
    border-bottom: 1px solid ${COLORS.text};
  }

  &:hover {
    background-color: ${COLORS.primary};
    color: ${COLORS.primaryInverted};
    transition: all 0.3s;
  }
  a {
    text-decoration: none;
    padding: 15px 20px;
    display: block;
  }
`;

export default NavMenu;
