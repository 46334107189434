import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";

import useIntersection from "helpers/useIntersection";
import { useTrail, useSprings, animated } from "react-spring";

import { Parallax } from "react-scroll-parallax";
import AmbilightVideo from "components/shared/AmbilightVideo";

const ZG23 = ({ pageType = "product" }) => {
  const { i18n } = useTranslation();
  const shoeWrapperRef = useRef();

  const zgPoints = ["TRACTION", "COMFORT", "STABILITY"];
  const zgPoints_fr = ["TRACTION", "CONFORT", "STABILITÉ"];

  const zg23SectionRef = useRef();
  const zgPointsInViewport = useIntersection(zg23SectionRef, "0px", 0.4);

  const zgPointsAnimation = useTrail(zgPoints.length, {
    from: { translateX: "100vw" },
    to: { translateX: "0" },
    pause: !zgPointsInViewport,
    delay: 200
  });

  const zg23ExplodedRef = useRef();
  const zgExplodedInViewport = useIntersection(zg23ExplodedRef, "0px", 0.4);

  const explodedImageAnimation = useSprings(
    6,
    [...Array(6)].map((item, index) => ({
      from: { translateY: "100vh", opacity: 0 },
      to: { translateY: "0", opacity: 1.0 },
      pause: !zgExplodedInViewport,
      delay: (index + 1) * 500
    }))
  );

  const explodedViewPoints = [
    "STABILITY WING",
    "LIGHTSTRIKE PRO",
    "LIGHTSTRIKE FRAME",
    "DUAL-DENSITY OUTSOLE",
    "6-CLEAT + GENERATIVE TRAXION OUTSOLE"
  ];

  const explodedViewPoints_fr = [
    "AILE DE STABILITÉ",
    '"LIGHTSTRIKE PRO"',
    'CADRE "LIGHTSTRIKE"',
    "SEMELLE DOUBLE DENSITÉ",
    "SEMELLE EXTÉRIEURE 6-CRAMPONS + TRAXION GÉNÉRATIVE"
  ];

  const explodedViewPointsAnimation = useSprings(
    explodedViewPoints.length,
    explodedViewPoints.map((item, index) => ({
      from: { translateY: "100vh", opacity: 0 },
      to: { translateY: "0", opacity: 1.0 },
      pause: !zgExplodedInViewport,
      delay: (index + 1) * 500
    }))
  );

  return (
    <>
      <ZG23Wrapper
        backgroundURL={`https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Ffootwear%2Farticle_assets%2Fzg23%2FFeathers.jpg?alt=media`}
      >
        {/* <BackgroundOverlay /> */}
        <ZGContent>
          <ZG23Header>
            <ZGLogo
              src={`https://firebasestorage.googleapis.com/v0/b/${
                process.env.REACT_APP_FIREBASE_STORAGE_ROOT
              }.appspot.com/o/sho%2Ffootwear%2Farticle_assets%2Fzg23%2FZG_logo${
                i18n.language === "en" ? "" : "_fr"
              }.png?alt=media`}
              alt=''
            />
          </ZG23Header>
          <Section ref={zg23SectionRef}>
            <ShoeImageSectionMesh>
              <img
                src={`https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Ffootwear%2Farticle_assets%2Fzg23%2Fmesh.png?alt=media`}
                alt=''
              />
            </ShoeImageSectionMesh>
            <ZGShoeImageWrapper ref={shoeWrapperRef}>
              <Parallax
                translateY={[30, -10]}
                targetElement={shoeWrapperRef.current}
              >
                <ZGShoeImage
                  src={`https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Ffootwear%2Farticle_assets%2Fzg23%2FZG23_Hero_M_Laced.png?alt=media`}
                  alt=''
                />
              </Parallax>
              <ZGShoeTechWrapper>
                <img
                  src={`https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Ffootwear%2Farticle_assets%2Fzg23%2FtechImageWrapper.png?alt=media`}
                  alt=''
                />
              </ZGShoeTechWrapper>
            </ZGShoeImageWrapper>
            <ZGShoeTextWrapper>
              {zgPointsAnimation.map((animations, animationIndex) => (
                <ZGShoeTextBlock
                  key={zgPoints[animationIndex]}
                  style={animations}
                >
                  {i18n.language === "en"
                    ? zgPoints[animationIndex]
                    : zgPoints_fr[animationIndex]}
                </ZGShoeTextBlock>
              ))}
            </ZGShoeTextWrapper>
          </Section>
          {pageType === "overview" && (
            <VideoSection>
              <VideoWrapper>
                <AmbilightVideo
                  videoSrc='https://stream.mux.com/GtWBpsDd47UA8B6c8XRyMTSAf5DoDXL2Cu401Jzwj6yg.m3u8'
                  thumbnail='https://image.mux.com/GtWBpsDd47UA8B6c8XRyMTSAf5DoDXL2Cu401Jzwj6yg/thumbnail.jpg?time=28'
                  subtitles={[
                    {
                      kind: "subtitles",
                      label: "English",
                      src: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fcaptions%2FSS23-ZG_sell-in_en.vtt?alt=media`,
                      srcLang: "en"
                    },
                    {
                      kind: "subtitles",
                      label: "French",
                      src: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fcaptions%2FSS23-ZG_sell-in_fr.vtt?alt=media`,
                      srcLang: "fr"
                    }
                  ]}
                />
              </VideoWrapper>
            </VideoSection>
          )}
          <Section>
            <ExplodedView ref={zg23ExplodedRef}>
              {explodedImageAnimation.map((animations, index) => (
                <StyledExplodedImage
                  key={`exploded-${index}`}
                  style={animations}
                  src={`https://firebasestorage.googleapis.com/v0/b/${
                    process.env.REACT_APP_FIREBASE_STORAGE_ROOT
                  }.appspot.com/o/sho%2Ffootwear%2Farticle_assets%2Fzg23%2Fexploded_${
                    index + 1
                  }.png?alt=media`}
                  alt=''
                />
              ))}
            </ExplodedView>
            <ExplodedViewText>
              {explodedViewPointsAnimation.map((animations, index) => (
                <ExplodedViewTextBlock
                  key={explodedViewPoints[index]}
                  style={animations}
                >
                  {i18n.language === "en"
                    ? explodedViewPoints[index]
                    : explodedViewPoints_fr[index]}
                </ExplodedViewTextBlock>
              ))}
            </ExplodedViewText>
          </Section>
          {pageType === "overview" && (
            <MorikowaSection>
              <MorikowaVideoWrapper>
                <AmbilightVideo
                  videoSrc='https://stream.mux.com/otzhnWLorB7B500ARqg0200Af019NvZfGVHen9EEtYlODk00.m3u8'
                  thumbnail='https://image.mux.com/otzhnWLorB7B500ARqg0200Af019NvZfGVHen9EEtYlODk00/thumbnail.jpg?time=28'
                  subtitles={[
                    {
                      kind: "subtitles",
                      label: "English",
                      src: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fcaptions%2FCollin_ZG_Interview_en.vtt?alt=media`,
                      srcLang: "en"
                    },
                    {
                      kind: "subtitles",
                      label: "French",
                      src: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fcaptions%2FCollin_ZG_Interview_fr.vtt?alt=media`,
                      srcLang: "fr"
                    }
                  ]}
                />
              </MorikowaVideoWrapper>
              <MorikowaText>COLIN MORIKOWA</MorikowaText>
            </MorikowaSection>
          )}
        </ZGContent>
        {pageType === "product" && <BlackToWhiteTransition />}
      </ZG23Wrapper>
      {pageType === "product" && (
        <>
          <ZG23Render>
            <img
              src={`https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Ffootwear%2Farticle_assets%2Fzg23%2FZCONCEPT.jpg?alt=media`}
              alt=''
            />
          </ZG23Render>
          <ZG23Videos>
            <TechVideoWithNoteWrapper>
              <TechVideoWrapper>
                <AmbilightVideo
                  videoSrc='https://stream.mux.com/yjQQtHMaA01LiWr2xtS7EbH6cDnbuMC92iEepfE9spJI.m3u8'
                  thumbnail='https://image.mux.com/yjQQtHMaA01LiWr2xtS7EbH6cDnbuMC92iEepfE9spJI/thumbnail.jpg?time=24'
                  subtitles={[
                    {
                      kind: "subtitles",
                      label: "English",
                      src: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fcaptions%2FZG23-Tech-Video_en.vtt?alt=media`,
                      srcLang: "en"
                    },
                    {
                      kind: "subtitles",
                      label: "French",
                      src: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fcaptions%2FZG23-Tech-Video_fr.vtt?alt=media`,
                      srcLang: "fr"
                    }
                  ]}
                  showLighting={false}
                />
              </TechVideoWrapper>
              <VideoNote>
                {i18n.language === "en"
                  ? "*REFER TO TOOLKIT FOR CANADIAN COLOURWAYS AND PRICING"
                  : "*SE RÉFÉRER À LA BOÎTE À OUTILS POUR LES COULEURS ET LES PRIX CANADIENS"}
              </VideoNote>
            </TechVideoWithNoteWrapper>
            <TechVideoWithNoteWrapper>
              <TechVideoWrapper>
                <AmbilightVideo
                  videoSrc='https://stream.mux.com/yettB2sS7qCPFp1vh3aCH6zxzBFF01KkvWpWyr76jRzM.m3u8'
                  thumbnail='https://image.mux.com/yettB2sS7qCPFp1vh3aCH6zxzBFF01KkvWpWyr76jRzM/thumbnail.jpg?time=46'
                  subtitles={[
                    {
                      kind: "subtitles",
                      label: "English",
                      src: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fcaptions%2FZG23-Boa-Tech-Video_en.vtt?alt=media`,
                      srcLang: "en"
                    },
                    {
                      kind: "subtitles",
                      label: "French",
                      src: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fcaptions%2FZG23-Boa-Tech-Video_fr.vtt?alt=media`,
                      srcLang: "fr"
                    }
                  ]}
                  showLighting={false}
                />
              </TechVideoWrapper>
              <VideoNote>
                {i18n.language === "en"
                  ? "*REFER TO TOOLKIT FOR CANADIAN COLOURWAYS AND PRICING"
                  : "*SE RÉFÉRER À LA BOÎTE À OUTILS POUR LES COULEURS ET LES PRIX CANADIENS"}
              </VideoNote>
            </TechVideoWithNoteWrapper>
          </ZG23Videos>
        </>
      )}
    </>
  );
};

const ZG23Wrapper = styled.div`
  width: 100%;
  position: relative;
  background-color: black;
  background-image: url(${({ backgroundURL }) => backgroundURL});
  background-position: center center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const BackgroundOverlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: black;
  opacity: 0.65;
`;

const ZGContent = styled.div`
  width: 100%;
  padding: 0 10vw;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ZG23Header = styled.div`
  height: 300px;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 30px 0;
  margin-bottom: 120px;

  @media screen and (max-width: 700px) {
    height: unset;
    width: 100%;
    margin-bottom: 20px;
  }
`;

const ZGLogo = styled.img`
  object-fit: contain;
  max-width: 100%;
  max-height: 100%;
  filter: drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4));
`;

const Section = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  /* width: 100%; */
  position: relative;
  margin: 60px 0;
`;

const VideoSection = styled.div`
  padding: 100px 0;
`;

const MorikowaSection = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 0.666667fr 0.3333333fr;
  position: relative;
  margin: 100px 0;
`;

const MorikowaVideoWrapper = styled.div`
  max-height: 100%;
  width: 90%;
  aspect-ratio: 16 / 9;
  background-color: black;
  z-index: 3;
`;

const MorikowaText = styled.div`
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: "adiNeue PRO Condensed Bold";
  letter-spacing: 10px;
  font-size: 5vw;
`;

const ShoeImageSectionMesh = styled.div`
  position: absolute;
  top: 0;
  /* left: 30vw; */
  bottom: 0;
  right: 0;
  opacity: 0.5;

  img {
    max-width: 100%;
    max-height: 100%;
  }
`;

const ZGShoeImageWrapper = styled.div`
  width: 35vw;
  height: 35vw;
  overflow: hidden;
  position: relative;
  display: flex;
  justify-content: center;
  padding: 30px 30px;
`;

const ZGShoeImage = styled.img`
  max-width: 100%;
  max-height: 100%;
`;

const ZGShoeTechWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  /* background: red; */

  img {
    height: 100%;
    width: 100%;
  }
`;

const ZGShoeTextWrapper = styled.div`
  /* height: 100%; */
  color: white;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: "adiNeue PRO Condensed Black";
  font-size: 5vw;
`;

const ZGShoeTextBlock = animated(styled.div``);

const VideoWrapper = styled.div`
  height: 60vh;
  max-width: 98vw;
  aspect-ratio: 16 / 9;
  background-color: black;
  z-index: 3;
`;

const ExplodedView = styled.div`
  justify-self: end;
  width: 400px;
  display: flex;
  flex-direction: column;
  padding-bottom: 80px;

  @media screen and (max-width: 1000px) {
    width: 250px;
  }

  img:nth-child(1) {
    z-index: 1;
  }
  img:nth-child(2) {
    margin-top: -35px;
  }
  img:nth-child(3) {
    margin-top: -22px;
  }
  img:nth-child(4) {
    margin-top: -25px;
  }
  img:nth-child(5) {
    margin-top: -60px;
  }
  img:nth-child(6) {
    margin-top: -20px;
  }

  @media screen and (max-width: 1000px) {
    width: 250px;

    img:nth-child(1) {
      z-index: 1;
    }
    img:nth-child(2) {
      margin-top: -20px;
    }
    img:nth-child(3) {
      margin-top: -10px;
    }
    img:nth-child(4) {
      margin-top: -10px;
    }
    img:nth-child(5) {
      margin-top: -20px;
    }
    img:nth-child(6) {
      margin-top: -10px;
    }
  }
`;

const StyledExplodedImage = animated(styled.img``);

const ExplodedViewText = styled.div`
  height: 100%;
  color: white;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* justify-content: center; */
  font-family: "adiNeue PRO Condensed Black";
  font-size: 50px;

  div:nth-child(1) {
  }
  div:nth-child(2) {
    margin-top: 20px;
  }
  div:nth-child(3) {
    margin-top: 25px;
  }
  div:nth-child(4) {
    margin-top: 25px;
  }
  div:nth-child(5) {
    margin-top: 25px;
  }

  @media screen and (max-width: 1000px) {
    font-size: 30px;

    div:nth-child(1) {
    }
    div:nth-child(2) {
      margin-top: 20px;
    }
    div:nth-child(3) {
      margin-top: 25px;
    }
    div:nth-child(4) {
      margin-top: 25px;
    }
    div:nth-child(5) {
      margin-top: 25px;
    }
  }
`;

const ExplodedViewTextBlock = animated(styled.div``);

const BlackToWhiteTransition = styled.div`
  width: 100%;
  height: 300px;
  background: linear-gradient(transparent, white 90%);
  /* background: white; */
  z-index: 1;
`;

const ZG23Render = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  /* padding-bottom: 150px; */
  /* margin-bottom: 100px; */

  img {
    width: 70%;
  }
`;

const ZG23Videos = styled.div`
  /* position: absolute;
  bottom: 0; */
  width: 100%;
  display: flex;
  justify-content: space-around;
  padding-bottom: 120px;

  @media screen and (max-width: 700px) {
    flex-direction: column;
    justify-content: unset;
    align-items: center;
    padding-bottom: 60px;
  }
`;

const TechVideoWithNoteWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const VideoNote = styled.div`
  padding: 0 20px;
`;

const TechVideoWrapper = styled.div`
  height: 40vh;
  max-width: 40vw;
  /* height: 60vh;
  max-width: 98vw; */
  aspect-ratio: 16 / 9;
`;

export default ZG23;
