import React from "react";
import styled from "styled-components/macro";
import { ParallaxBanner } from "react-scroll-parallax";
import ImageMask from "../ImageMask";

const SectionHeader = ({
  title,
  imageURL,
  backgroundColor = "black",
  shouldScaleImage = true
}) => {
  return (
    <Wrapper backgroundColor={backgroundColor}>
      <StyledImageMask maskOpacity={0.5} style={{ zIndex: 10 }} />
      <StyledParallaxBanner
        layers={[
          {
            image: imageURL,
            speed: -15,
            scale: [0.9, 1.2, 0.9, 1.2]
          }
        ]}
      >
        <SectionHeaderTextWrapper>
          <SectionHeaderText>{title}</SectionHeaderText>
        </SectionHeaderTextWrapper>
      </StyledParallaxBanner>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
  /* height: 600px; */
  display: flex;
  background-color: ${({ backgroundColor }) => backgroundColor};
  padding: 10px 10vw;

  @media screen and (max-width: 500px) {
    padding: 10px;
  }
`;

const StyledImageMask = styled(ImageMask)`
  z-index: 2;
`;

const StyledParallaxBanner = styled(ParallaxBanner)`
  height: 55vh;

  @media screen and (max-width: 500px) {
    height: 35vh;
  }
`;

const SectionHeaderTextWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  color: white;
  font-size: 10vw;
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

const SectionHeaderText = styled.div`
  opacity: 0.9;
  text-shadow: 3px 5px 2px rgb(0 0 0 / 0.4);
  mix-blend-mode: screen;
  font-size: 10vw;
  text-transform: uppercase;

  @media screen and (max-width: 500px) {
    font-size: 15vw;
  }
`;

export default SectionHeader;
