import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";

import { useDatabase } from "data/useDatabase";
import { useAuth } from "components/auth/useAuth";

import CategoryHeader from "components/shared/productComponents/CategoryHeader";
import BounceArrow from "components/shared/BounceArrow";
import NavFooter from "components/shared/NavFooter";
import SectionHeader from "components/shared/productComponents/SectionHeader";
import ProductCard from "./ProductCard";
import KeyTakeaways from "components/shared/productComponents/KeyTakeaways";

import ZG23 from "./ZG23";

const Footwear = () => {
  const { i18n } = useTranslation();
  const { userType } = useAuth();
  const { getPageData } = useDatabase();

  const [data, setData] = useState();

  useEffect(() => {
    getPageData("footwear").then((data) => setData(data));
  }, [getPageData]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const divRef = useRef();
  const scrollToBottom = () => {
    window.scrollTo({
      top: divRef.current.offsetTop,
      behavior: "smooth"
    });
  };

  return (
    <Wrapper>
      <CategoryHeader
        imageURL={data?.headerBackgroundImage}
        overlayImageURL={data?.headerOverlayImage}
        title={i18n.language === "en" ? data?.pageTitle : data?.pageTitle_fr}
      >
        <BounceArrow onClick={scrollToBottom} />
      </CategoryHeader>
      <div ref={divRef}>
        <ZG23 />
      </div>
      <SectionWrapper>
        <SectionHeader
          title={i18n.language === "en" ? "SOLARMOTION BOA" : "SOLARMOTION BOA"}
          imageURL={`https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Ffootwear%2Fpage_assets%2Fsolarmotion_section_header.jpg?alt=media`}
        />
        <div>
          <ProductCard
            pageID='footwear'
            productID='solarmotion_boa'
            imageCount={7}
            videoURL={
              "https://stream.mux.com/2bEZnl6tNWRpxBwD4v5KrwIalDe4yWFOQq8U3SMQfwI.m3u8"
            }
            thumbnailURL={
              "https://image.mux.com/2bEZnl6tNWRpxBwD4v5KrwIalDe4yWFOQq8U3SMQfwI/thumbnail.jpg?time=16.5"
            }
            subtitles={[
              {
                kind: "subtitles",
                label: "English",
                src: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fcaptions%2FZG23-Solarmotion-Boa-Tech-Video_en.vtt?alt=media`,
                srcLang: "en"
              },
              {
                kind: "subtitles",
                label: "French",
                src: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fcaptions%2FZG23-Solarmotion-Boa-Tech-Video_fr.vtt?alt=media`,
                srcLang: "fr"
              }
            ]}
          />
        </div>
      </SectionWrapper>
      <SectionWrapper>
        <SectionHeader
          title={i18n.language === "en" ? "GO-TO" : "GO-TO"}
          imageURL={`https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Ffootwear%2Fpage_assets%2Fgoto_section_header_2.png?alt=media`}
          backgroundColor={"#949080"}
        />
        <div>
          <ProductCard
            pageID='footwear'
            productID='go_to'
            imageCount={8}
            videoURL={
              "https://stream.mux.com/GSOiPEn2mGoSJHBVtprPDarzXvS2JtK3zhnMUhmC4vA.m3u8"
            }
            thumbnailURL={
              "https://image.mux.com/GSOiPEn2mGoSJHBVtprPDarzXvS2JtK3zhnMUhmC4vA/thumbnail.jpg?time=35"
            }
            subtitles={[
              {
                kind: "subtitles",
                label: "English",
                src: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fcaptions%2FGo-To-Tech-Video_en.vtt?alt=media`,
                srcLang: "en"
              },
              {
                kind: "subtitles",
                label: "French",
                src: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fcaptions%2FGo-To-Tech-Video_fr.vtt?alt=media`,
                srcLang: "fr"
              }
            ]}
          />
        </div>
      </SectionWrapper>
      <SectionWrapper>
        <SectionHeader
          title={i18n.language === "en" ? "ZOYSIA" : "ZOYSIA"}
          imageURL={`https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Ffootwear%2Fpage_assets%2Fzoysia_section_header.png?alt=media`}
          backgroundColor={"#cb2b9d"}
        />
        <div>
          <ProductCard
            pageID='footwear'
            productID='zoysia'
            imageCount={6}
            videoURL={
              "https://stream.mux.com/SltXqCWwRqxPP6EH8Mi7XQWGs02xGlMKDbVWmcrUvbLI.m3u8"
            }
            thumbnailURL={
              "https://image.mux.com/SltXqCWwRqxPP6EH8Mi7XQWGs02xGlMKDbVWmcrUvbLI/thumbnail.jpg?time=61"
            }
            subtitles={[
              {
                kind: "subtitles",
                label: "English",
                src: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fcaptions%2FZG23-Zoysia-Tech-Video_en.vtt?alt=media`,
                srcLang: "en"
              },
              {
                kind: "subtitles",
                label: "French",
                src: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fcaptions%2FZG23-Zoysia-Tech-Video_fr.vtt?alt=media`,
                srcLang: "fr"
              }
            ]}
          />
        </div>
      </SectionWrapper>
      {userType === "internal" && (
        <KeyTakeaways takeaways={data?.keyTakeaways} />
      )}
      <NavFooter />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
  padding-bottom: 100px;
`;

const SectionWrapper = styled.div``;

const BlankDiv = styled.div`
  width: 100%;
  height: 600px;
`;

export default Footwear;
