import React, { useState, useRef } from "react";
import styled from "styled-components/macro";
import NavFooter from "components/shared/NavFooter";
import { saveAs } from "file-saver";
import Loading from "components/shared/Loading";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import useIntersection from "helpers/useIntersection";
import { useAuth } from "components/auth/useAuth";
import { useTranslation } from "react-i18next";

const MarketingMomentum = () => {
  const { i18n } = useTranslation();
  const { userType } = useAuth();
  const [isLoading, setIsLoading] = useState(false);

  const firstItemRef = useRef();
  const lastItemRef = useRef();
  const firstItemInViewport = useIntersection(firstItemRef, "0px", 0.4);
  const lastItemInViewport = useIntersection(lastItemRef, "0px", 0.4);

  const listRef = useRef();

  const saveFile = async (url, name) => {
    setIsLoading(true);
    const response = await fetch(url);
    const blob = await response.blob();
    saveAs(blob, name);
    setIsLoading(false);
  };

  const scrollUp = () => {
    listRef.current.scrollBy({ top: -300, behavior: "smooth" });
  };

  const scrollDown = () => {
    listRef.current.scrollBy({ top: 300, behavior: "smooth" });
  };

  return (
    <Wrapper
      backgroundURL={`https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Ftoolkit%2Ftoolkit_background.jpg?alt=media`}
    >
      <Loading isLoading={isLoading} />
      <Content>
        <Header>{i18n.language === "en" ? "TOOLKIT" : "Boîte à outils"}</Header>
        <ContentOverlayWrapper>
          <ContentOverlay>
            <ContentOverlayBackground />
            <ContentTitle>
              {i18n.language === "en" ? "DOWNLOADS" : "Téléchargements"}
            </ContentTitle>
            {userType === "internal" ? (
              <ListWrapper>
                <ShowMoreTop
                  onClick={scrollUp}
                  shouldHide={firstItemInViewport}
                >
                  <ArrowDropUpIcon sx={{ fontSize: "8vh" }} />
                </ShowMoreTop>
                <List ref={listRef}>
                  <ListItem
                    ref={firstItemRef}
                    onClick={() =>
                      saveFile(
                        `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Ftoolkit%2Fdownloads%2FSS23_Material_Manifesto_Final.pdf?alt=media`,
                        "FSS23_Material_Manifesto_Final.pdf"
                      )
                    }
                  >
                    <Icon>
                      <img src='images/pdfIcon.svg' alt='' />
                    </Icon>
                    SS23 Material Manifesto
                    <Download>
                      <img src='images/downloadIcon.svg' alt='' />
                    </Download>
                  </ListItem>
                  <ListItem
                    onClick={() =>
                      saveFile(
                        `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Ftoolkit%2Fdownloads%2FSS'23%20adidas%20Golf_Line%20list.xlsx?alt=media`,
                        "SS'23 adidas Golf_Line list.xlsx"
                      )
                    }
                  >
                    <Icon>
                      <img src='images/xlsxIcon.svg' alt='' />
                    </Icon>
                    SS23 adidas Golf Line List
                    <Download>
                      <img src='images/downloadIcon.svg' alt='' />
                    </Download>
                  </ListItem>
                  <ListItem
                    onClick={() =>
                      saveFile(
                        `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Ftoolkit%2Fdownloads%2FInline%20UPCs.xlsx?alt=media`,
                        "Inline UPCs.xlsx"
                      )
                    }
                  >
                    <Icon>
                      <img src='images/xlsxIcon.svg' alt='' />
                    </Icon>
                    Inline UPCs
                    <Download>
                      <img src='images/downloadIcon.svg' alt='' />
                    </Download>
                  </ListItem>
                  <ListItem
                    onClick={() =>
                      saveFile(
                        `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Ftoolkit%2Fdownloads%2FInline%20Webcopy.xlsx?alt=media`,
                        "Inline Webcopy.xlsx"
                      )
                    }
                  >
                    <Icon>
                      <img src='images/xlsxIcon.svg' alt='' />
                    </Icon>
                    Inline Webcopy
                    <Download>
                      <img src='images/downloadIcon.svg' alt='' />
                    </Download>
                  </ListItem>
                  <ListItem
                    onClick={() =>
                      saveFile(
                        `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Ftoolkit%2Fdownloads%2FSS23-Sustainability.pptx?alt=media`,
                        "SS23-Sustainability.pptx"
                      )
                    }
                  >
                    <Icon>
                      <img src='images/pptxIcon.svg' alt='' />
                    </Icon>
                    SS23 - Sustainability
                    <Download>
                      <img src='images/downloadIcon.svg' alt='' />
                    </Download>
                  </ListItem>
                  <ListItem
                    onClick={() =>
                      saveFile(
                        `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Ftoolkit%2Fdownloads%2F2023_LOGO_Update.pptx?alt=media`,
                        "2023 LOGO Update.pptx"
                      )
                    }
                  >
                    <Icon>
                      <img src='images/pptxIcon.svg' alt='' />
                    </Icon>
                    SS23 - Logo Update
                    <Download>
                      <img src='images/downloadIcon.svg' alt='' />
                    </Download>
                  </ListItem>
                  <ListItem
                    ref={lastItemRef}
                    onClick={() =>
                      saveFile(
                        `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Ftoolkit%2Fdownloads%2FSS23%20adidas%20Golf%20-%20Supply%20Chain%20Script.pptx?alt=media`,
                        "SS23 adidas Golf - Supply Chain Script.pptx"
                      )
                    }
                  >
                    <Icon>
                      <img src='images/pptxIcon.svg' alt='' />
                    </Icon>
                    SS23 adidas Golf - Supply Chain Script
                    <Download>
                      <img src='images/downloadIcon.svg' alt='' />
                    </Download>
                  </ListItem>
                  <ListItem
                    ref={lastItemRef}
                    onClick={() =>
                      saveFile(
                        `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Ftoolkit%2Fdownloads%2FLightstrike%20vs.%20Lightstrike%20Pro.pptx?alt=media`,
                        "Lightstrike vs. Lightstrike Pro.pptx"
                      )
                    }
                  >
                    <Icon>
                      <img src='images/pptxIcon.svg' alt='' />
                    </Icon>
                    Lightstrike vs. Lightstrike Pro
                    <Download>
                      <img src='images/downloadIcon.svg' alt='' />
                    </Download>
                  </ListItem>
                  <ListItem
                    onClick={() =>
                      saveFile(
                        `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Ftoolkit%2Fdownloads%2FSS23%20Footwear%20Tech%20Guide_CDN.pdf?alt=media`,
                        "SS23 Footwear Tech Guide_CDN.pdf"
                      )
                    }
                  >
                    <Icon>
                      <img src='images/pdfIcon.svg' alt='' />
                    </Icon>
                    SS23 Footwear Tech Guide
                    <Download>
                      <img src='images/downloadIcon.svg' alt='' />
                    </Download>
                  </ListItem>
                  <ListItem
                    onClick={() =>
                      saveFile(
                        `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Ftoolkit%2Fdownloads%2FSS23%20adi%20Golf-PGA%20SCRIPTING%20(COMMERCIAL).pdf?alt=media`,
                        "SS23 adi Golf-PGA SCRIPTING (COMMERCIAL).pdf"
                      )
                    }
                  >
                    <Icon>
                      <img src='images/pdfIcon.svg' alt='' />
                    </Icon>
                    SS23 adidas Golf - PGA Scripting (Commercial)
                    <Download>
                      <img src='images/downloadIcon.svg' alt='' />
                    </Download>
                  </ListItem>
                  <ListItem
                    onClick={() =>
                      saveFile(
                        `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Ftoolkit%2Fdownloads%2FSS23%20adidas%20Golf-%20%20Accessories_Accessoires%20Fit%20Guide.pptx?alt=media`,
                        "SS23 adidas Golf-  Accessories_Accessoires Fit Guide.pptx"
                      )
                    }
                  >
                    <Icon>
                      <img src='images/pptxIcon.svg' alt='' />
                    </Icon>
                    SS23 adidas Golf - Accessories/Accessoires Fit Guide
                    <Download>
                      <img src='images/downloadIcon.svg' alt='' />
                    </Download>
                  </ListItem>
                  <ListItem
                    onClick={() =>
                      saveFile(
                        `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Ftoolkit%2Fdownloads%2FSS23%20adidas%20Golf%20-%20Juniors%20colour%20groups_groupes%20couleurs.pptx?alt=media`,
                        "SS23 adidas Golf - Juniors colour groups_groupes couleurs.pptx"
                      )
                    }
                  >
                    <Icon>
                      <img src='images/pptxIcon.svg' alt='' />
                    </Icon>
                    SS23 adidas Golf - Juniors colour groups/groupes couleurs
                    <Download>
                      <img src='images/downloadIcon.svg' alt='' />
                    </Download>
                  </ListItem>
                  <ListItem
                    onClick={() =>
                      saveFile(
                        `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Ftoolkit%2Fdownloads%2FSS23%20adidas%20Golf%20-%20Mens%20colour%20groups_groupes%20couleurs.pptx?alt=media`,
                        "SS23 adidas Golf - Mens colour groups_groupes couleurs.pptx"
                      )
                    }
                  >
                    <Icon>
                      <img src='images/pptxIcon.svg' alt='' />
                    </Icon>
                    SS23 adidas Golf - Mens/Hommes colour groups/groupes
                    couleurs
                    <Download>
                      <img src='images/downloadIcon.svg' alt='' />
                    </Download>
                  </ListItem>
                  <ListItem
                    onClick={() =>
                      saveFile(
                        `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Ftoolkit%2Fdownloads%2FSS23%20adidas%20Golf%20-%20Mens_Hommes%20Performance_Authentic.pptx?alt=media`,
                        "SS23 adidas Golf - Mens_Hommes Performance_Authentic.pptx"
                      )
                    }
                  >
                    <Icon>
                      <img src='images/pptxIcon.svg' alt='' />
                    </Icon>
                    SS23 adidas Golf - Mens/Hommes Performance Authentic
                    <Download>
                      <img src='images/downloadIcon.svg' alt='' />
                    </Download>
                  </ListItem>
                  <ListItem
                    onClick={() =>
                      saveFile(
                        `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Ftoolkit%2Fdownloads%2FSS23%20adidas%20Golf%20-%20Mens_Hommes%20Performance_Versatile.pptx?alt=media`,
                        "SS23 adidas Golf - Mens_Hommes Performance_Versatile.pptx"
                      )
                    }
                  >
                    <Icon>
                      <img src='images/pptxIcon.svg' alt='' />
                    </Icon>
                    SS23 adidas Golf - Mens/Hommes Performance Versatile
                    <Download>
                      <img src='images/downloadIcon.svg' alt='' />
                    </Download>
                  </ListItem>
                  <ListItem
                    onClick={() =>
                      saveFile(
                        `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Ftoolkit%2Fdownloads%2FSS23%20adidas%20Golf%20-%20Mens_Hommes%20Prints.pptx?alt=media`,
                        "SS23 adidas Golf - Mens_Hommes Prints.pptx"
                      )
                    }
                  >
                    <Icon>
                      <img src='images/pptxIcon.svg' alt='' />
                    </Icon>
                    SS23 adidas Golf - Mens/Hommes Prints
                    <Download>
                      <img src='images/downloadIcon.svg' alt='' />
                    </Download>
                  </ListItem>
                  <ListItem
                    onClick={() =>
                      saveFile(
                        `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Ftoolkit%2Fdownloads%2FSS23%20adidas%20Golf%20-%20Womens%20colour%20groups_groupes%20couleurs.pptx?alt=media`,
                        "SS23 adidas Golf - Womens colour groups_groupes couleurs.pptx"
                      )
                    }
                  >
                    <Icon>
                      <img src='images/pptxIcon.svg' alt='' />
                    </Icon>
                    SS23 adidas Golf - Womens/Femmes colour groups / groupes
                    couleurs
                    <Download>
                      <img src='images/downloadIcon.svg' alt='' />
                    </Download>
                  </ListItem>
                  <ListItem
                    onClick={() =>
                      saveFile(
                        `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Ftoolkit%2Fdownloads%2FSS23%20adidas%20Golf%20-%20Womens_Femmes%20Fit%20Images.pptx?alt=media`,
                        "SS23 adidas Golf - Womens_Femmes Fit Images.pptx"
                      )
                    }
                  >
                    <Icon>
                      <img src='images/pptxIcon.svg' alt='' />
                    </Icon>
                    SS23 adidas Golf - Womens/Femmes Fit Images
                    <Download>
                      <img src='images/downloadIcon.svg' alt='' />
                    </Download>
                  </ListItem>
                  <ListItem
                    onClick={() =>
                      saveFile(
                        `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Ftoolkit%2Fdownloads%2FSS23%20adidas%20Golf%20-%20Womens_Femmes%20Prints.pptx?alt=media`,
                        "SS23 adidas Golf - Womens_Femmes Prints.pptx"
                      )
                    }
                  >
                    <Icon>
                      <img src='images/pptxIcon.svg' alt='' />
                    </Icon>
                    SS23 adidas Golf - Womens/Femmes Prints
                    <Download>
                      <img src='images/downloadIcon.svg' alt='' />
                    </Download>
                  </ListItem>
                  <ListItem
                    onClick={() =>
                      saveFile(
                        `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Ftoolkit%2Fdownloads%2FSS23%20adidas%20Golf%20-%20colour%20groups_groupes%20couleurs.pptx?alt=media`,
                        "SS23 adidas Golf - colour groups_groupes couleurs.pptx"
                      )
                    }
                  >
                    <Icon>
                      <img src='images/pptxIcon.svg' alt='' />
                    </Icon>
                    SS23 adidas Golf - Colour Groups / Groupes Couleurs
                    <Download>
                      <img src='images/downloadIcon.svg' alt='' />
                    </Download>
                  </ListItem>
                  <ListItem
                    ref={lastItemRef}
                    onClick={() =>
                      saveFile(
                        `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Ftoolkit%2Fdownloads%2FPlay%20Green.pptx?alt=media`,
                        "Play Green.pptx"
                      )
                    }
                  >
                    <Icon>
                      <img src='images/pptxIcon.svg' alt='' />
                    </Icon>
                    Play Green
                    <Download>
                      <img src='images/downloadIcon.svg' alt='' />
                    </Download>
                  </ListItem>
                </List>
                <ShowMoreBottom
                  onClick={scrollDown}
                  shouldHide={lastItemInViewport}
                >
                  <ArrowDropDownIcon sx={{ fontSize: "8vh" }} />
                </ShowMoreBottom>
              </ListWrapper>
            ) : (
              <ListWrapper>
                <ShowMoreTop
                  onClick={scrollUp}
                  shouldHide={firstItemInViewport}
                >
                  <ArrowDropUpIcon sx={{ fontSize: "8vh" }} />
                </ShowMoreTop>
                <List ref={listRef}>
                  <ListItem
                    ref={firstItemRef}
                    onClick={() =>
                      saveFile(
                        `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Ftoolkit%2Fdownloads%2FSS'23%20adidas%20Golf_Line%20list.xlsx?alt=media`,
                        "SS'23 adidas Golf_Line list.xlsx"
                      )
                    }
                  >
                    <Icon>
                      <img src='images/xlsxIcon.svg' alt='' />
                    </Icon>
                    SS23 adidas Golf Line List
                    <Download>
                      <img src='images/downloadIcon.svg' alt='' />
                    </Download>
                  </ListItem>
                  <ListItem
                    onClick={() =>
                      saveFile(
                        `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Ftoolkit%2Fdownloads%2FInline%20UPCs.xlsx?alt=media`,
                        "Inline UPCs.xlsx"
                      )
                    }
                  >
                    <Icon>
                      <img src='images/xlsxIcon.svg' alt='' />
                    </Icon>
                    Inline UPCs
                    <Download>
                      <img src='images/downloadIcon.svg' alt='' />
                    </Download>
                  </ListItem>
                  <ListItem
                    onClick={() =>
                      saveFile(
                        `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Ftoolkit%2Fdownloads%2FSS23%20Footwear%20Tech%20Guide_CDN.pdf?alt=media`,
                        "SS23 Footwear Tech Guide_CDN.pdf"
                      )
                    }
                  >
                    <Icon>
                      <img src='images/pdfIcon.svg' alt='' />
                    </Icon>
                    SS23 Footwear Tech Guide
                    <Download>
                      <img src='images/downloadIcon.svg' alt='' />
                    </Download>
                  </ListItem>
                  <ListItem
                    onClick={() =>
                      saveFile(
                        `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Ftoolkit%2Fdownloads%2FSS23%20adi%20Golf-PGA%20SCRIPTING%20(COMMERCIAL).pdf?alt=media`,
                        "SS23 adi Golf-PGA SCRIPTING (COMMERCIAL).pdf"
                      )
                    }
                  >
                    <Icon>
                      <img src='images/pdfIcon.svg' alt='' />
                    </Icon>
                    SS23 adidas Golf - PGA Scripting (Commercial)
                    <Download>
                      <img src='images/downloadIcon.svg' alt='' />
                    </Download>
                  </ListItem>
                  <ListItem
                    onClick={() =>
                      saveFile(
                        `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Ftoolkit%2Fdownloads%2FSS23%20adidas%20Golf-%20%20Accessories_Accessoires%20Fit%20Guide.pptx?alt=media`,
                        "SS23 adidas Golf-  Accessories_Accessoires Fit Guide.pptx"
                      )
                    }
                  >
                    <Icon>
                      <img src='images/pptxIcon.svg' alt='' />
                    </Icon>
                    SS23 adidas Golf - Accessories/Accessoires Fit Guide
                    <Download>
                      <img src='images/downloadIcon.svg' alt='' />
                    </Download>
                  </ListItem>
                  <ListItem
                    onClick={() =>
                      saveFile(
                        `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Ftoolkit%2Fdownloads%2FSS23%20adidas%20Golf%20-%20Mens_Hommes%20Prints.pptx?alt=media`,
                        "SS23 adidas Golf - Mens_Hommes Prints.pptx"
                      )
                    }
                  >
                    <Icon>
                      <img src='images/pptxIcon.svg' alt='' />
                    </Icon>
                    SS23 adidas Golf - Mens/Hommes Prints
                    <Download>
                      <img src='images/downloadIcon.svg' alt='' />
                    </Download>
                  </ListItem>
                  <ListItem
                    onClick={() =>
                      saveFile(
                        `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Ftoolkit%2Fdownloads%2FSS23%20adidas%20Golf%20-%20Womens_Femmes%20Fit%20Images.pptx?alt=media`,
                        "SS23 adidas Golf - Womens_Femmes Fit Images.pptx"
                      )
                    }
                  >
                    <Icon>
                      <img src='images/pptxIcon.svg' alt='' />
                    </Icon>
                    SS23 adidas Golf - Womens/Femmes Fit Images
                    <Download>
                      <img src='images/downloadIcon.svg' alt='' />
                    </Download>
                  </ListItem>
                  <ListItem
                    onClick={() =>
                      saveFile(
                        `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Ftoolkit%2Fdownloads%2FSS23%20adidas%20Golf%20-%20Womens_Femmes%20Prints.pptx?alt=media`,
                        "SS23 adidas Golf - Womens_Femmes Prints.pptx"
                      )
                    }
                  >
                    <Icon>
                      <img src='images/pptxIcon.svg' alt='' />
                    </Icon>
                    SS23 adidas Golf - Womens/Femmes Prints
                    <Download>
                      <img src='images/downloadIcon.svg' alt='' />
                    </Download>
                  </ListItem>
                  <ListItem
                    onClick={() =>
                      saveFile(
                        `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Ftoolkit%2Fdownloads%2FSS23%20adidas%20Golf%20-%20colour%20groups_groupes%20couleurs.pptx?alt=media`,
                        "SS23 adidas Golf - colour groups_groupes couleurs.pptx"
                      )
                    }
                  >
                    <Icon>
                      <img src='images/pptxIcon.svg' alt='' />
                    </Icon>
                    SS23 adidas Golf - Colour Groups / Groupes Couleurs
                    <Download>
                      <img src='images/downloadIcon.svg' alt='' />
                    </Download>
                  </ListItem>
                  <ListItem
                    ref={lastItemRef}
                    onClick={() =>
                      saveFile(
                        `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Ftoolkit%2Fdownloads%2FPlay%20Green.pptx?alt=media`,
                        "Play Green.pptx"
                      )
                    }
                  >
                    <Icon>
                      <img src='images/pptxIcon.svg' alt='' />
                    </Icon>
                    Play Green
                    <Download>
                      <img src='images/downloadIcon.svg' alt='' />
                    </Download>
                  </ListItem>
                  {/* <ListItem ref={firstItemRef} /> */}
                </List>
                {/* <ListItem ref={lastItemRef} /> */}
              </ListWrapper>
            )}
          </ContentOverlay>
        </ContentOverlayWrapper>
      </Content>
      <NavFooter />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  height: 100vh;
  font-size: 5vw;
  overflow: hidden;
  padding-bottom: 100px;
  background-image: url(${({ backgroundURL }) => backgroundURL});
  background-position: center center;
  background-size: cover;
`;

const Content = styled.div`
  width: 100%;
  height: 100%;
  overflow: scroll;
  display: grid;
  grid-template-rows: min-content 1fr;
`;

const Header = styled.div`
  display: flex;
  justify-content: center;
  color: white;
  padding-bottom: 20px;
  filter: drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4));
  text-transform: uppercase;
`;

const ContentOverlayWrapper = styled.div`
  height: 100%;
  padding-bottom: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`;

const ContentOverlay = styled.div`
  height: 100%;
  position: relative;
  display: grid;
  grid-template-rows: min-content 1fr;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  color: black;
  font-size: 3vw;
  padding: 1% 5% 1% 5%;
`;

const ContentOverlayBackground = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  /* width: 100%;
  height: 100%; */
  background-color: rgb(150 150 150 /0.7);
  border-radius: 20px;
  /* filter: blur(5px); */
`;

const ContentTitle = styled.div`
  filter: drop-shadow(3px 2px 2px rgb(0 0 0 / 0.4));
  z-index: 1;
  text-align: center;
  text-transform: uppercase;
`;

const ListWrapper = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  /* padding: 20px 0; */
`;

const List = styled.ul`
  height: 100%;
  list-style-type: none;
  padding: unset;
  margin: unset;
  overflow: scroll;
`;

const Icon = styled.div`
  display: flex;
  align-items: center;
  padding: 0 20px 0 0;
  /* max-height: 2.5vw; */
  img {
    height: 2.5vw;
  }
`;

const Download = styled.div`
  flex-grow: 2;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 20px;
  opacity: 0;
  img {
    height: 2vw;
  }
  transition: all 1s;
`;

const ListItem = styled.li`
  border-bottom: 1px solid white;
  display: flex;
  align-items: center;
  padding: 20px 0;
  font-size: 1.5vw;
  font-family: "adineuePRORegularWeb";
  filter: drop-shadow(3px 2px 2px rgb(0 0 0 / 0.4));
  cursor: pointer;

  :hover {
    color: white;

    ${Download} {
      opacity: 1;
    }
  }
  transition: all 0.5s;
`;

const ShowMoreTop = styled.div`
  /* position: absolute;
  top: 0;
  left: 0;
  right: 0; */
  display: flex;
  justify-content: center;
  margin-bottom: -20px;
  color: white;
  /* background-color: black; */
  /* z-index: 5; */
  filter: drop-shadow(3px 2px 2px rgb(0 0 0 / 0.4));
  cursor: pointer;
  opacity: ${({ shouldHide }) => (shouldHide ? 0.0 : 1.0)};
  transition: all 0.5s;

  :hover {
    color: black;
    transition: all 0.5s;
  }
`;

const ShowMoreBottom = styled.div`
  /* position: absolute;
  bottom: 0;
  left: 0;
  right: 0; */
  display: flex;
  justify-content: center;
  margin-top: -20px;
  color: white;
  /* z-index: 5; */
  filter: drop-shadow(3px 2px 2px rgb(0 0 0 / 0.4));
  cursor: pointer;
  opacity: ${({ shouldHide }) => (shouldHide ? 0.0 : 1.0)};
  transition: all 0.5s;

  :hover {
    color: black;
    transition: all 0.5s;
  }
`;

export default MarketingMomentum;
