import React, { useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";
import { Ambilight } from "helpers/ambilight/ambilight.ts";
import ReactPlayer from "react-player/file";

const AmbilightVideo = ({
  className,
  videoSrc,
  thumbnail,
  subtitles = [],
  showLighting = true
}) => {
  const { i18n } = useTranslation();

  const videoWrapperRef = useRef();
  const videoRef = useRef();

  const playerIsReady = () => {
    setupSubtitles();
    setUpAmbilight();
  };

  const setupSubtitles = () => {
    if (i18n.language === "fr-CA") {
      const video = videoRef.current.player.player.player;
      let frenchTrackIndex = 0;
      [...Array(video.textTracks.length)].forEach((item, idx) => {
        if (video.textTracks[idx].language === "fr") {
          frenchTrackIndex = idx;
        }
      });
      video.textTracks[frenchTrackIndex].mode = "showing";
    }
  };

  const setUpAmbilight = () => {
    if (showLighting) {
      const video = videoRef.current;
      const videoWrapper = videoWrapperRef.current;
      const ambi = new Ambilight(video.player.player.player, videoWrapper);
      ambi.setType("4Sides");
    }
  };

  return (
    <VideoWrapper ref={videoWrapperRef}>
      <ReactPlayer
        ref={videoRef}
        width='100%'
        height='100%'
        controls
        onReady={playerIsReady}
        playing
        light={thumbnail}
        url={videoSrc}
        config={{
          file: {
            forceHLS: true,
            attributes: { crossOrigin: "anonymous" },
            tracks: subtitles
          }
        }}
      />
    </VideoWrapper>
  );
};

const VideoWrapper = styled.div`
  max-width: 100%;
  max-height: 100%;
  aspect-ratio: 16 / 9;
`;

export default AmbilightVideo;
