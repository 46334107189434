import { createGlobalStyle } from "styled-components";
import adineuePROCondLight from "./adineuePROCondLight.woff2";
import adineuePROCondRegular from "./adineuePROCondRegular.woff2";
import adineuePROCondBold from "./adineuePROCondBold.woff2";
import adineuePROCondBlack from "./adineuePROCondBlack.woff2";
import adineuePRORegularWeb from "./adineuePRORegularWeb.woff2";
import adineuePROLightWeb from "./adineuePROLightWeb.woff2";

const FontStyles = createGlobalStyle`

@font-face {
  font-family: 'adiNeue PRO Condensed Light';
  src: url(${adineuePROCondLight}) format('woff2');
  font-display: block; /* Fix flickering */
}

@font-face {
  font-family: 'adiNeue PRO Condensed Regular';
  src: url(${adineuePROCondRegular}) format('woff2');
  font-display: block; /* Fix flickering */
}

@font-face {
  font-family: 'adiNeue PRO Condensed Bold';
  src: url(${adineuePROCondBold}) format('woff2');
  font-display: block; /* Fix flickering */
}

@font-face {
  font-family: "adiNeue PRO Condensed Black";
  src: url(${adineuePROCondBlack}) format('woff2');
  font-display: block; /* Fix flickering */
}

@font-face {
  font-family: "adineuePRORegularWeb";
  src: url(${adineuePRORegularWeb}) format('woff2');
  font-display: block; /* Fix flickering */
}

@font-face {
  font-family: "adineuePROLightWeb";
  src: url(${adineuePROLightWeb}) format('woff2');
  font-display: block; /* Fix flickering */
}

* {
  font-family: "adiNeue PRO Condensed Bold", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}

`;

export default FontStyles;
