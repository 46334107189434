import React, { createContext, useContext, useState } from "react";

import useSessionStorage from "helpers/useSessionStorage";

const AuthContext = createContext();

const AuthContextProvider = ({ children }) => {
  const [userType, setUserType] = useSessionStorage("userType", null);

  const login = async (pw) => {
    if (pw === process.env.REACT_APP_PUB_PW) {
      setUserType("public");
      return true;
    } else if (pw === process.env.REACT_APP_INT_PW) {
      setUserType("internal");
      return true;
    } else {
      return false;
    }
  };

  return (
    <AuthContext.Provider
      value={{
        userType,
        login
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
export const useAuth = () => useContext(AuthContext);
