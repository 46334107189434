const pageData = {
  seasonal_priorities: {
    pageTitle: "Seasonal Priorities",
    pageTitle_fr: "LES PRIORITÉS SAISONNIÈRES",
    headerBackgroundImage: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fseasonal_priorities%2Fpage_assets%2Fpage_header_background.jpg?alt=media`,
    headerOverlayImage: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fseasonal_priorities%2Fpage_assets%2Fpage_header_overlay.png?alt=media`
  },
  mens_sp: {
    pageTitle: "Men's Sport Performance",
    pageTitle_fr: "PERFORMANCE SPORTIVE HOMMES",
    headerBackgroundImage: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fmens_sp%2Fpage_assets%2Fheader_background.jpg?alt=media`,
    headerOverlayImage: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fmens_sp%2Fpage_assets%2Fheader_secondary.png?alt=media`,
    colorStories: [
      {
        name: "Lucid Fuchsia",
        name_fr: "Fuchsia Lucide",
        delivery: "1/1",
        images: [
          `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fmens_sp%2Fcolor_stories%2FLucid_Fuchia.jpg?alt=media`,
          `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fmens_sp%2Fcolor_stories%2FLucid_Fuchia_2.jpg?alt=media`,
          `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fmens_sp%2Fcolor_stories%2FLucid_Fuchia_3.jpg?alt=media`,
          `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fmens_sp%2Fcolor_stories%2FLucid_Fuchia_4.jpg?alt=media`,
          `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fmens_sp%2Fcolor_stories%2FLucid_Fuchia_5.jpg?alt=media`
        ],
        colors: [
          {
            name: "LUCID FUCHSIA",
            name_fr: "Fuchsia Lucide",
            color: [170, 57, 125, 255],
            type: "primary"
          },
          {
            name: "BLACK",
            name_fr: "NOIR",
            color: [0, 0, 0, 255],
            type: "primary"
          },
          {
            name: "WHITE",
            name_fr: "BLANC",
            color: [255, 255, 255, 255],
            type: "primary"
          },
          {
            name: "LUCID BLUE",
            name_fr: "BLEU LUCIDE",
            color: [52, 45, 124, 255],
            type: "secondary"
          },
          {
            name: "COLLEGIATE NAVY",
            name_fr: "Marine Collégial",
            color: [49, 50, 64, 255],
            type: "secondary"
          }
        ]
      },
      {
        name: "Blue Fusion",
        name_fr: "Bleu Fusion",
        delivery: "1/1",
        images: [
          `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fmens_sp%2Fcolor_stories%2FBlue_Fusion_1.jpg?alt=media`,
          `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fmens_sp%2Fcolor_stories%2FBlue_Fusion_2.jpg?alt=media`,
          `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fmens_sp%2Fcolor_stories%2FBlue_Fusion_3.jpg?alt=media`,
          `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fmens_sp%2Fcolor_stories%2FBlue_Fusion_4.jpg?alt=media`
        ],
        colors: [
          {
            name: "BLUE FUSION",
            name_fr: "Bleu Fusion",
            color: [116, 128, 188, 255],
            type: "primary"
          },
          {
            name: "COLLEGIATE NAVY",
            name_fr: "Marine Collégial",
            color: [49, 50, 64, 255],
            type: "primary"
          },
          {
            name: "WHITE",
            name_fr: "BLANC",
            color: [255, 255, 255, 255],
            type: "primary"
          },
          {
            name: "OLIVE STRATA",
            name_fr: "Strate Olive",
            color: [102, 95, 76, 255],
            type: "secondary"
          },
          {
            name: "BLUE DAWN",
            name_fr: "Aube Bleu",
            color: [179, 187, 219, 255],
            type: "secondary"
          }
        ]
      },
      {
        name: "Coral Fusion",
        name_fr: "Corail Fusion",
        delivery: "4/1",
        images: [
          `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fmens_sp%2Fcolor_stories%2FCoral_Fusion_1.jpg?alt=media`,
          `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fmens_sp%2Fcolor_stories%2FCoral_Fusion_2.jpg?alt=media`,
          `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fmens_sp%2Fcolor_stories%2FCoral_Fusion_3.jpg?alt=media`,
          `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fmens_sp%2Fcolor_stories%2FCoral_Fusion_4.jpg?alt=media`,
          `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fmens_sp%2Fcolor_stories%2FCoral_Fusion_5.jpg?alt=media`
        ],
        colors: [
          {
            name: "CORAL FUSION",
            name_fr: "Corail Fusion",
            color: [233, 122, 110, 255],
            type: "primary"
          },
          {
            name: "COLLEGIATE NAVY",
            name_fr: "marine collégial",
            color: [49, 50, 64, 255],
            type: "primary"
          },
          {
            name: "WHITE",
            name_fr: "Blanc",
            color: [255, 255, 255, 255],
            type: "primary"
          },
          {
            name: "OLIVE STRATA",
            name_fr: "Strate olive",
            color: [102, 95, 76, 255],
            type: "secondary"
          },
          {
            name: "BLACK",
            name_fr: "Noir",
            color: [0, 0, 0, 255],
            type: "secondary"
          },
          {
            name: "BLUE FUSION",
            name_fr: "Bleu Fusion",
            color: [116, 128, 188, 255],
            type: "secondary"
          },
          {
            name: "BLUE DAWN",
            name_fr: "Aube Bleu",
            color: [179, 187, 219, 255],
            type: "secondary"
          }
        ]
      },
      {
        name: "Pink Strata",
        name_fr: "Strada Rose",
        delivery: "5/1",
        images: [
          `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fmens_sp%2Fcolor_stories%2FPink_Strada.jpg?alt=media`,
          `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fmens_sp%2Fcolor_stories%2FPink_Strada_2.jpg?alt=media`,
          `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fmens_sp%2Fcolor_stories%2FPink_Strada_3.jpg?alt=media`,
          `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fmens_sp%2Fcolor_stories%2FPink_Strada_4.jpg?alt=media`,
          `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fmens_sp%2Fcolor_stories%2FPink_Strada_5.jpg?alt=media`,
          `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fmens_sp%2Fcolor_stories%2FPink_Strada_6.jpg?alt=media`
        ],
        colors: [
          {
            name: "PINK STRATA",
            name_fr: "Strada Rose",
            color: [181, 127, 140, 255],
            type: "primary"
          },
          {
            name: "SILVER PEBBLE",
            name_fr: "GALET ARGENTÉ",
            color: [134, 130, 119, 255],
            type: "primary"
          },
          {
            name: "OLIVE STRATA",
            name_fr: "Strate olive",
            color: [102, 95, 76, 255],
            type: "primary"
          },
          {
            name: "CLEAR BROWN",
            name_fr: "MARRON CLAIR",
            color: [177, 168, 159, 255],
            type: "secondary"
          },
          {
            name: "COLLEGIATE NAVY",
            name_fr: "marine collégial",
            color: [49, 50, 64, 255],
            type: "secondary"
          },
          {
            name: "BLUE FUSION",
            name_fr: "Bleu fusion",
            color: [116, 128, 188, 255],
            type: "secondary"
          },
          {
            name: "BLUE DAWN",
            name_fr: "aube bleu",
            color: [179, 187, 219, 255],
            type: "secondary"
          }
        ]
      }
    ],
    mustHaves: {
      leftImage: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fmens_sp%2Fpage_assets%2FMust_Haves_Left.jpg?alt=media`,
      rightImage: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fmens_sp%2Fpage_assets%2FMust_Haves_Right.jpg?alt=media`,
      title: "MUST HAVES",
      title_fr: "LES INCONTOURNABLES",
      sections: [
        {
          title: "Polos",
          title_fr: "Polos",
          imageURL: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fmens_sp%2Fpage_assets%2FPolos_Header.jpg?alt=media`,
          products: [
            {
              name: "GO-TO STRIPE POLO SHIRT",
              name_fr: "GO-TO STRIPE POLO SHIRT",
              articleCode: "TM1030S23",
              imageCount: 10,
              IdPrice: "TM1030S23 - $90",
              sizes: "S - 2XL",
              sizes_fr: "P - 2TG",
              description:
                "Stay comfortable and focused as you play in this men's adidas golf polo shirt. The fabric has a rich cotton feel with built-in stretch for full mobility on the course. AEROREADY manages moisture to keep you dry. Made with a series of recycled materials, and at least 70% recycled content, this product represents just one of our solutions to help end plastic waste.",
              description_fr:
                "Restez à l'aise et concentré lorsque vous jouez dans ce polo de golf adidas pour homme. Le tissu a un toucher riche en coton avec une extensibilité intégré pour une mobilité totale sur le parcours. AEROREADY gère l'humidité pour vous garder au sec. Fabriqué à partir d'une série de matériaux recyclés d'au moins 70%, ce produit ne représente qu'une de nos solutions pour aider à mettre fin aux déchets plastiques.",
              keyPoints: [
                "Regular fit",
                "Two-button polo collar with wide-spread collar",
                "74% recycled polyester, 19% cotton, 7% elastane single jersey",
                "Rich cotton feel with stretch",
                "Moisture-absorbing AEROREADY",
                "Split hem on sides"
              ],
              keyPoints_fr: [
                "Coupe régulière",
                "Polo à col large avec 2 boutons",
                "Jersey simple, 74 % polyester recyclé, 19 % coton, 7 % élasthanne",
                "Toucher coton riche et extensible",
                "Absorbeur d'humidité AEROREADY",
                "Fente sur les côtés"
              ]
            },
            {
              name: "KEY PRINT POLO",
              name_fr: "KEY PRINT POLO",
              articleCode: "TM1020S23",
              imageCount: 9,
              IdPrice: "TM1020S23 - $85",
              sizes: "S - 2XL",
              sizes_fr: "P - 2TG",
              description:
                "Liven up your course look in this men's adidas golf polo shirt. The breathable four-way stretch fabric ensures unrestricted movement and all-day comfort. The allover print keeps your look on point. Made with a series of recycled materials, and at least 70% recycled content, this product represents just one of our solutions to help end plastic waste.",
              description_fr:
                "Rehaussez votre look sur le parcours avec ce polo de golf adidas pour homme. Le tissu respirant extensible dans les quatre directions assure un mouvement sans restriction et un confort tout au long de la journée. L'imprimé vous permet d'être au ''top''. Fabriqué à partir d'une série de matériaux recyclés d'au moins 70%, ce produit ne représente qu'une de nos solutions pour aider à mettre fin aux déchets plastiques.",
              keyPoints: [
                "Regular fit",
                "Three-button polo collar",
                "92% recycled polyester, 8% elastane mesh",
                "Breathable, durable, moisture-wicking fabric",
                "Four-way stretch",
                "Allover print"
              ],
              keyPoints_fr: [
                "Coupe régulière",
                "Col polo à trois boutons",
                "92% polyester recyclé, 8% mèche élasthanne",
                "Tissu respirant, durable et évacuant l'humidité",
                "Extensible dans les quatre directions",
                "Imprimé"
              ]
            },
            {
              name: "ULTIMATE365 ALLOVER PRINT POLO SHIRT",
              name_fr: "ULTIMATE365 ALLOVER PRINT POLO SHIRT",
              articleCode: "TM1016S23",
              imageCount: 9,
              IdPrice: "TM1016S23 - $85",
              sizes: "S - 3XL",
              sizes_fr: "P - 3TG",
              description:
                "Elevate your style in this men's adidas golf polo shirt. It's made of breathable, stretchy fabric for all-day comfort. The allover print plays well on and off the course. This garment is made with a yarn which contains 50% Parley Ocean Plastic — reimagined plastic waste, intercepted on remote islands, beaches, coastal communities and shorelines, preventing it from polluting our ocean. This garment contains at least 70% recycled content in total.",
              description_fr:
                "Rehaussez votre style avec ce polo de golf adidas pour homme. Il est fabriqué dans un tissu respirant et extensible pour un confort tout au long de la journée. L'imprimé est parfait sur et à l'extérieur du parcours. Ce vêtement est fabriqué avec un fil qui contient 50 % de plastique Parley Ocean Plastic - des déchets plastiques interceptés sur des îles éloignées, des plages, des communautés côtières et des rivages, les empêchant de polluer nos océans. Ce vêtement contient au moins 70 % de contenu recyclé au total.",
              keyPoints: [
                "Regular fit",
                "Three-button polo collar",
                "88% recycled polyester, 12% elastane single jersey",
                "Breathable, durable, moisture-wicking fabric",
                "Allover print",
                "Yarn contains 50% Parley Ocean Plastic",
                "This garment contains a minimum of 40% recycled material in total"
              ],
              keyPoints_fr: [
                "Coupe régulière",
                "Col polo à trois boutons",
                "Jersey simple, 88 % polyester recyclé, 12 % élasthanne",
                "Tissu respirant, durable et évacuant l'humidité",
                "Imprimé ",
                "Le fil contient 50 % de plastique Parley Ocean",
                "Ce vêtement contient un minimum de 40 % de matériaux recyclés."
              ]
            },
            {
              name: "VERSATILE TEXTURED POLO",
              name_fr: "VERSATILE TEXTURED POLO",
              articleCode: "TM1024S23",
              imageCount: 6,
              IdPrice: "TM1024S23 - $75",
              sizes: "S - 2XL",
              sizes_fr: "P - 2TG",
              description:
                "This men's adidas golf polo shirt is designed for year-round play. It's made of soft, stretchy piqué fabric for comfort and mobility on the course. The textured look keeps your style fresh. Made with a series of recycled materials, and at least 70% recycled content, this product represents just one of our solutions to help end plastic waste.",
              description_fr:
                "Ce polo de golf adidas pour homme est conçu pour être porté toute l'année. Il est fabriqué dans un tissu piqué doux et extensible pour plus de confort et de mobilité sur le parcours. Son aspect texturé vous permet de garder un style à jour. Fabriqué à partir d'une série de matériaux recyclés d'au moins 70%, ce produit ne représente qu'une de nos solutions pour aider à mettre fin aux déchets plastiques.",
              keyPoints: [
                "Regular fit",
                "Three-button polo collar",
                "89% recycled nylon, 11% elastane piqué",
                "Breathable, durable, moisture-wicking fabric"
              ],
              keyPoints_fr: [
                "Coupe régulière",
                "Col polo à trois boutons",
                "Piqué 89% nylon recyclé, 11% élasthanne",
                "Tissu respirant, durable, évacuant l'humidité"
              ]
            },
            {
              name: "SPORT TEXTURED POLO",
              name_fr: "SPORT TEXTURED POLO",
              articleCode: "TM1027S23",
              imageCount: 11,
              IdPrice: "TM1027S23 - $75",
              sizes: "S - 3XL",
              sizes_fr: "P - 3TG",
              description:
                "A clean course look never goes out of style. This men's adidas golf polo shirt features an allover textured design with subtle style. The breathable, moisture-wicking fabric keeps you comfortable from first tee to final putt. Made with 100% recycled materials, this product represents just one of our solutions to help end plastic waste.",
              description_fr:
                "Un look épuré sur le parcours qui ne se démode jamais. Ce polo de golf adidas pour homme présente un motif texturé en toute subtilité. Le tissu respirant et évacuant l'humidité vous permet de rester à l'aise du premier départ jusqu'au dernier coup roulé. Fabriqué à partir de matériaux 100 % recyclés, ce produit n'est qu'une de nos solutions pour aider à mettre fin aux déchets plastiques.",
              keyPoints: [
                "Regular fit",
                "Three-button polo collar",
                "100% recycled polyester raschel",
                "Breathable, durable, moisture-wicking fabric"
              ],
              keyPoints_fr: [
                "Coupe régulière",
                "Col polo à trois boutons",
                "100% polyester recyclé raschel",
                "Tissu respirant, durable et évacuant l'humidité"
              ]
            }
          ]
        },
        {
          title: "Layering",
          title_fr: "Superposition",
          imageURL: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fmens_sp%2Fpage_assets%2FLayering_Header.jpg?alt=media`,
          products: [
            {
              name: "RIPSTOP HOODIE",
              name_fr: "RIPSTOP HOODIE",
              articleCode: "TM4507S23CF",
              imageCount: 7,
              IdPrice: "TM4507S23CF - $120",
              sizes: "S - 2XL",
              sizes_fr: "P - 2TG",
              description:
                "Whether you're working the driving range or playing 18 holes, this men's adidas golf hoodie provides a comfy layer during cool weather. A drawcord on the elastic hem lets you cinch up for a snug fit. Front pockets hold extra tees and markers for easy access. The textured look gives it plenty of style.",
              description_fr:
                "Que vous travailliez sur le champ de pratique ou que vous jouiez 18 trous, ce chandail à capuchon de golf adidas pour homme est une couche confortable pour un temps plus frais. Un cordon sur l'ourlet élastique vous permet de le resserrer pour un ajustement parfait. Les poches avant peuvent accueillir ''tees'' et marqueurs supplémentaires pour un accès facile. L'aspect texturé lui donne beaucoup de style.",
              keyPoints: [
                "Regular fit",
                "Hidden quarter zip with hood",
                "96% nylon, 4% elastane ripstop",
                "Soft, cotton-feel, textured fabric great for layering",
                "Front pockets",
                "Elastic sleeve hems",
                "Elastic hem with drawcord",
                "Quick-dry"
              ],
              keyPoints_fr: [
                "Coupe régulière",
                "Fermoir 1/4 caché par le capuchon",
                "96% nylon, 4% élasthanne ripstop",
                "Tissu doux, texturé, au toucher coton, idéal pour les superpositions.",
                "Poches au devant",
                "Ourlets de manches élastiques",
                "Ourlet élastique avec cordon de serrage aux hanches",
                "Séchage rapide"
              ]
            },
            {
              name: "AUTHENTIC 1/4 ZIP LAYER",
              name_fr: "AUTHENTIC 1/4 ZIP LAYER",
              articleCode: "TM4500S23CF",
              imageCount: 5,
              IdPrice: "TM4500S23CF - $110",
              sizes: "P - 3TG",
              description:
                "Be prepared for cool weather in this men's adidas golf sweatshirt. The soft fabric has built-in stretch for full mobility. Banded sleeves and hem seal out the chill. The half-zip design makes it easy to pull off when the temperature rises. Made with a series of recycled materials, and at least 70% recycled content, this product represents just one of our solutions to help end plastic waste.",
              description_fr:
                "Soyez prêt pour le temps frais avec ce chandail de golf adidas pour homme. Le tissu doux est extensible pour une mobilité complète. Les manches et l'ourlet à bandes protègent du froid. Le design demi-fermoir permet de l'enlever facilement lorsque la température monte. Fabriqué à partir d'une série de matériaux recyclés d'au moins 70%, ce produit ne représente qu'une de nos solutions pour aider à mettre fin aux déchets plastiques.",
              keyPoints: [
                "Regular fit",
                "Half zip with mock neck",
                "71% recycled polyester, 24% viscose, 5% elastane interlock",
                "Soft, rich fabric with stretch",
                "Banded sleeves and hem"
              ],
              keyPoints_fr: [
                "Coupe régulière",
                "1/2 zip avec col montant",
                "71% polyester recyclé, 24% viscose, 5% élasthanne interlock",
                "Tissu doux, riche et extensible"
              ]
            },
            {
              name: "MIRCODOT 1/4 ZIP LAYER",
              name_fr: "MIRCODOT 1/4 ZIP LAYER",
              articleCode: "TM4506S23",
              imageCount: 5,
              IdPrice: "TM4506S23 - $100",
              sizes: "S - 2XL",
              sizes_fr: "P - 2TG",
              description:
                "Layer up during cool weather in this men's adidas golf sweatshirt. It's made of soft fabric with built-in stretch for unrestricted movement on the course. The textured look gives it extra style. Made with a series of recycled materials, and at least 70% recycled content, this product represents just one of our solutions to help end plastic waste.",
              description_fr:
                "Habillez-vous par temps frais avec ce chandail de golf adidas pour homme. Il est fabriqué dans un tissu doux et extensible pour vous permettre de bouger librement sur le parcours. L'aspect texturé lui donne encore plus de style. Fabriqué à partir d'une série de matériaux recyclés d'au moins 70%, ce produit ne représente qu'une de nos solutions pour aider à mettre fin aux déchets plastiques.",
              keyPoints: [
                "Regular fit",
                "Quarter zip with low stand-up collar",
                "91% recycled polyester, 9% elastane doubleknit",
                "Soft, cotton-feel, textured fabric great for layering",
                "Front pockets",
                "Elastic bindings",
                "Quick-dry"
              ],
              keyPoints_fr: [
                "Coupe régulière",
                "1/4 zip avec col montant ",
                "Tricot double 91 % polyester recyclé, 9 % élasthanne",
                "Tissu doux, texturé, au toucher coton, idéal pour les superpositions.",
                "Poches avant",
                "Attaches élastiques",
                "Séchage rapide"
              ]
            }
          ]
        },
        {
          title: "Bottoms",
          title_fr: "Bases",
          imageURL: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fmens_sp%2Fpage_assets%2FBottoms_Header.jpg?alt=media`,
          products: [
            {
              name: "GO-TO 5 POCKET PANT",
              name_fr: "GO-TO 5 POCKET PANT",
              articleCode: "TM6505S23",
              imageCount: 11,
              IdPrice: "TM6505S23 - $110",
              sizes: "30 - 42",
              sizes_fr: "30 - 42",
              description:
                "Elevate your style on the course in these men's adidas golf pants. The breathable stretch fabric ensures a full range of motion as you crouch and swing. Tapered legs add a sleek finishing touch. A minimum of 70% of this product is a blend of recycled and renewable materials.",
              description_fr:
                "Améliorez votre style sur le parcours avec ce pantalon de golf adidas pour homme. Le tissu extensible respirant assure une liberté de mouvements complète lorsque vous vous accroupissez ou faites des élans.  La coupe ajustée apporte une touche élégante. Au moins 70 % de ce produit est un mélange de matériaux recyclés et renouvelables.",
              keyPoints: [
                "Regular fit",
                "Belt loops on waist",
                "56% cotton, 38% lyocell, 6% elastane twill",
                "Breathable stretch fabric",
                "Classic five-pocket construction",
                "Deeper front pockets",
                "Tapered legs"
              ],
              keyPoints_fr: [
                "Coupe régulière",
                "Passant de ceinture à la taille",
                "Twill, 56% coton, 38% lyocell, 6% élasthanne",
                "Tissu extensible et respirant",
                "Construction classique à cinq poches",
                "Poches avant plus profondes",
                "Jambes ajustées"
              ]
            },
            {
              name: "RIPSTOP JOGGER",
              name_fr: "RIPSTOP JOGGER",
              articleCode: "TM6502S23CF",
              imageCount: 4,
              IdPrice: "TM6502S23CF - $100",
              sizes: "S - 2XL",
              sizes_fr: "P - 2TG",
              description:
                "Stay cool and stylish in these men's adidas golf jogger pants. The lightweight fabric keeps you feeling fresh all day long. Elastic hems with inner bungees let you adjust the fit and ensure easy on and off over your shoes.",
              description_fr:
                "Restez au frais et élégant dans ce pantalon ''jogger'' de golf adidas pour homme. Le tissu léger vous permet de vous sentir au frais toute la journée. Les ourlets élastiques avec cordons intérieurs vous permettent d'ajuster la coupe et de l'enfiler et l'enlever facilement par-dessus vos chaussures.",
              keyPoints: [
                "Regular fit",
                "Elastic waist with drawcord",
                "96% nylon, 4% elastane ripstop",
                "Lightweight fabric",
                "Front and back pockets",
                "Elastic hems with inner bungee drawcord"
              ],
              keyPoints_fr: [
                "Coupe régulière",
                "Taille élastique avec cordon de serrage",
                "96% nylon, 4% élasthanne ripstop",
                "Tissu léger",
                "Poches avant et arrière",
                "Ourlets élastiques avec cordon de serrage élastique intérieur"
              ]
            },
            {
              name: "GO-TO SHORT",
              name_fr: "GO-TO SHORT",
              articleCode: "TM6503S23",
              imageCount: 8,
              IdPrice: "TM6503S23 - $95",
              sizes: "30 - 42",
              sizes_fr: "30 - 42",
              description:
                "Swing, bend and flex in comfort. These men's adidas golf shorts are made of stretch fabric with elastic at the back waist for full mobility. A minimum of 70% of this product is a blend of recycled and renewable materials.",
              description_fr:
                "Élan, flexion et souplesse en tout confort. Ce short de golf adidas pour homme est fabriqué en tissu extensible avec un élastique à la taille arrière pour une mobilité complète. Au moins 70 % de ce produit est un mélange de matériaux recyclés et renouvelables.",
              keyPoints: [
                "Regular fit",
                "Belt loops on waist",
                "56% cotton, 38% lyocell, 6% elastane twill",
                "Stretch fabric with a cool, dry feel",
                "Front and back pockets",
                "Elastic back waist",
                '9" inseam'
              ],
              keyPoints_fr: [
                "Coupe régulière",
                "Passant de ceinture à la taille",
                "Twill 56% coton, 38% lyocell, 6% élasthanne",
                "Tissu extensible avec une sensation de fraîcheur",
                "Poches avant et arrière",
                "Taille élastique à l'arrière",
                'Entrejambe de 9"'
              ]
            },
            {
              name: "ULTIMATE365 PRINT SHORT",
              name_fr: "ULTIMATE365 PRINT SHORT",
              articleCode: "TM6500S23",
              imageCount: 6,
              IdPrice: "TM6500S23 - $95",
              sizes: "30 - 42",
              sizes_fr: "30 - 42",
              description:
                "These men's adidas golf shorts are built for year-round comfort. The stretchy fabric ensures full mobility, and gripper tape keeps your shirt tucked in for undistracted play. The allover print livens up your look on and off the course. Made with a series of recycled materials, and at least 70% recycled content, this product represents just one of our solutions to help end plastic waste.",
              description_fr:
                "Ce short de golf adidas pour homme est conçu pour être porté toute au long de l'année. Le tissu extensible assure une mobilité complète et la bande auto-adhérante permet de maintenir votre polo à l'intérieur pour un jeu sans distraction. L'imprimé égaie votre look sur et à l'extérieur du parcours. Fabriqué à partir d'une série de matériaux recyclés d'au moins 70%, ce produit ne représente qu'une de nos solutions pour aider à mettre fin aux déchets plastiques.",
              keyPoints: [
                "Regular fit",
                "Belt loops on stretch waist",
                "88% recycled polyester, 12% elastane plain weave",
                "Moisture-wicking stretch fabric",
                "Front and back pockets",
                "adidas-branded silicon gripper tape keep shirts tucked in",
                "Moisture-wicking fabric",
                "Allover print",
                '9" inseam'
              ],
              keyPoints_fr: [
                "Coupe régulière",
                "Passant de ceinture à la taille",
                "Tissu uni 88% polyester recyclé, 12% élasthanne",
                "Tissu extensible évacuant l'humidité",
                "Poches avant et arrière",
                "Bande de serrage en silicone de marque adidas pour maintenir les chemises à l'intérieur",
                "Tissu à évacuation d'humidité",
                "Imprimé ",
                'Entrejambe de 9"'
              ]
            },
            {
              name: "ULTIMATE365 8.5 IN SOLID SHORT",
              name_fr: "ULTIMATE365 8.5 IN SOLID SHORT",
              articleCode: "TM6512S23",
              imageCount: 11,
              IdPrice: "TM6512S23 - $90",
              sizes: "30 - 42",
              sizes_fr: "30 - 42",
              description:
                "Flex and swing freely in these men's adidas golf shorts. Four-way stretch fabric gives you a full range of motion, and gripper tape keeps your shirt tucked in. A durable water-repellent finish sheds light showers and dew. Made with a series of recycled materials, and at least 70% recycled content, this product represents just one of our solutions to help end plastic waste.",
              description_fr:
                "Élancez-vous librement dans ce short de golf adidas pour homme. Le tissu extensible dans les quatre directions vous offre une gamme complète de mouvements et la bande antidérapante permet de maintenir votre polo à l'intérieur. Une finition déperlante durable protège des pluies légères et de la rosée. Fabriqué à partir d'une série de matériaux recyclés d'au moins 70%, ce produit ne représente qu'une de nos solutions pour aider à mettre fin aux déchets plastiques.",
              keyPoints: [
                "Regular fit",
                "Belt loops on stretch waist",
                "88% recycled polyester, 12% elastane plain weave",
                "Thin, lightweight fabric with four-way stretch",
                "Front and back pockets",
                "adidas-branded silicone gripper tape keep shirts tucked in",
                "Durable water-repellent (DWR) coating",
                "Moisture-wicking"
              ],
              keyPoints_fr: [
                "Coupe régulière",
                "Passant de ceinture à la taille",
                "Tissu uni 88 % polyester recyclé, 12 % élasthanne",
                "Tissu fin et léger, extensible dans quatre directions",
                "Poches avant et arrière",
                "Bande en silicone adidas pour garder le polo à l'intérieur.",
                "Finition déperlante durable (DWR)",
                "Evacuation de l'humidité"
              ]
            },
            {
              name: "MUST-HAVE CARRYOVER PANTS",
              name_fr: "MUST-HAVE CARRYOVER PANTS",
              articleCode: "Carry_Over_Pant",
              imageCount: 7,
              groupedItemDetails: [
                {
                  text: "ULTIMATE365 REGULAR FIT PANT - TM6471S22 - $100",
                  text_fr: "ULTIMATE365 REGULAR FIT PANT - TM6471S22 - $100"
                },
                {
                  text: "ULTIMATE365 TAPERED FIT PANT - TM6472S22 - $100",
                  text_fr: "ULTIMATE365 TAPERED FIT PANT - TM6472S22 - $100"
                }
              ]
            }
          ]
        }
      ]
    },
    keyTakeaways: [
      {
        title: "ULTIMATE365 TOUR",
        title_fr: "ULTIMATE365 TOUR",
        imageURL: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fmens_sp%2Fpage_assets%2FkeyTakeaway_Ultimate365_Tour.jpg?alt=media`,
        keyPoints: [
          {
            bullet:
              "WEARABLE, PREMIUM PRODUCT THAT HAS BEEN INSPIRED BY AND FOR THE ATHLETE",
            bullet_fr:
              "WEARABLE, PREMIUM PRODUCT THAT HAS BEEN INSPIRED BY AND FOR THE ATHLETE"
          },
          {
            bullet:
              "GAINING MOMENTUM IN THE PRIMEKNIT TECHNOLOGY AND CAPITALIZING ON THE SUCCESS BY ADDING MORE SILHOUETTES",
            bullet_fr:
              "GAINING MOMENTUM IN THE PRIMEKNIT TECHNOLOGY AND CAPITALIZING ON THE SUCCESS BY ADDING MORE SILHOUETTES"
          },
          {
            bullet: "HERO APPAREL STORY FOR THE SEASON",
            bullet_fr: "HERO APPAREL STORY FOR THE SEASON"
          }
        ]
      },
      {
        title: "POLOS",
        title_fr: "POLOS",
        imageURL: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fmens_sp%2Fpage_assets%2FkeyTakeaway_Polos.jpg?alt=media`,
        keyPoints: [
          {
            bullet: "CONTINUED FOCUS ON VERSATILITY + FUNCTIONALITY",
            bullet_fr: "CONTINUED FOCUS ON VERSATILITY + FUNCTIONALITY"
          },
          {
            bullet:
              "PRINTS & TEXTURES CONTINUE TO TREND, THE SEASONAL OFFERING HAS A BALANCE OF BOTH",
            bullet_fr:
              "PRINTS & TEXTURES CONTINUE TO TREND, THE SEASONAL OFFERING HAS A BALANCE OF BOTH"
          }
        ]
      },
      {
        title: "BOTTOMS",
        title_fr: "BOTTOMS",
        imageURL: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fmens_sp%2Fpage_assets%2FkeyTakeaway_Bottoms.jpg?alt=media`,
        keyPoints: [
          {
            bullet: "CONTINUED MOMENTUM IN CORE BOTTOMS PROGRAM",
            bullet_fr: "CONTINUED MOMENTUM IN CORE BOTTOMS PROGRAM",
            subBullets: [
              { bullet: "ULTIMATE365 PANT​", bullet_fr: "ULTIMATE365 PANT​" },
              {
                bullet: "ULTIMATE365 SHORT IN BOTH INSEAM LENGTHS",
                bullet_fr: "ULTIMATE365 SHORT IN BOTH INSEAM LENGTHS"
              }
            ]
          },
          {
            bullet:
              "LAUNCH OF THE NEW 5-POCKET PANT WITH UPDATED MATERIAL THAT SPEAKS TO ON-OFF-COURSE VERSATILITY",
            bullet_fr:
              "LAUNCH OF THE NEW 5-POCKET PANT WITH UPDATED MATERIAL THAT SPEAKS TO ON-OFF-COURSE VERSATILITY"
          }
        ]
      }
    ]
  },
  womens_app: {
    pageTitle: "Women's Apparel",
    pageTitle_fr: "VÊTEMENTS POUR FEMMES",
    headerBackgroundImage: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fwomens_app%2Fpage_assets%2Fheader-background.jpg?alt=media`,
    headerOverlayImage: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fwomens_app%2Fpage_assets%2Fheader-secondary.png?alt=media`,
    colorStories: [
      {
        name: "Lucid Fuchsia",
        name_fr: "Fuchsia lucide",
        delivery: "1/1",
        images: [
          `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fwomens_app%2Fcolor_stories%2FLucid_Fuchsia_1.jpg?alt=media`,
          `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fwomens_app%2Fcolor_stories%2FLucid_Fuchsia_2.jpg?alt=media`,
          `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fwomens_app%2Fcolor_stories%2FLucid_Fuchsia_3.jpg?alt=media`,
          `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fwomens_app%2Fcolor_stories%2FLucid_Fuchsia_4.jpg?alt=media`,
          `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fwomens_app%2Fcolor_stories%2FLucid_Fuchsia_5.jpg?alt=media`
        ],
        colors: [
          {
            name: "LUCID FUCHSIA",
            name_fr: "Fuchsia lucide",
            color: [170, 57, 125, 255],
            type: "primary"
          },
          {
            name: "BLACK",
            name_fr: "Noir",
            color: [0, 0, 0, 255],
            type: "primary"
          },
          {
            name: "WHITE",
            name_fr: "Blanc",
            color: [255, 255, 255, 255],
            type: "primary"
          },
          {
            name: "LUCID BLUE",
            name_fr: "Bleu lucide",
            color: [52, 45, 124, 255],
            type: "secondary"
          },
          {
            name: "COLLEGIATE NAVY",
            name_fr: "marine collégial",
            color: [49, 50, 64, 255],
            type: "secondary"
          }
        ]
      },
      {
        name: "Blue Fusion",
        name_fr: "Fusion bleue",
        delivery: "1/1",
        images: [
          `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fwomens_app%2Fcolor_stories%2FBlue_Fusion_1.jpg?alt=media`,
          `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fwomens_app%2Fcolor_stories%2FBlue_Fusion_2.jpg?alt=media`,
          `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fwomens_app%2Fcolor_stories%2FBlue_Fusion_3.jpg?alt=media`,
          `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fwomens_app%2Fcolor_stories%2FBlue_Fusion_4.jpg?alt=media`
        ],
        colors: [
          {
            name: "BLUE FUSION",
            name_fr: "Fusion bleue",
            color: [116, 128, 188, 255],
            type: "primary"
          },
          {
            name: "COLLEGIATE NAVY",
            name_fr: "marine collégial",
            color: [49, 50, 64, 255],
            type: "primary"
          },
          {
            name: "WHITE",
            name_fr: "Blanc",
            color: [255, 255, 255, 255],
            type: "primary"
          },
          {
            name: "WONDER TAUPE",
            name_fr: "wonder taupe",
            color: [181, 166, 158, 255],
            type: "secondary"
          },
          {
            name: "BLUE DAWN",
            name_fr: "aube bleue",
            color: [179, 187, 219, 255],
            type: "secondary"
          }
        ]
      },
      {
        name: "Coral Fusion",
        name_fr: "fusion corail",
        delivery: "4/1",
        images: [
          `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fwomens_app%2Fcolor_stories%2FCoral_Fusion_1.jpg?alt=media`,
          `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fwomens_app%2Fcolor_stories%2FCoral_Fusion_2.jpg?alt=media`,
          `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fwomens_app%2Fcolor_stories%2FCoral_Fusion_3.jpg?alt=media`,
          `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fwomens_app%2Fcolor_stories%2FCoral_Fusion_4.jpg?alt=media`
        ],
        colors: [
          {
            name: "CORAL FUSION",
            name_fr: "fusion corail",
            color: [233, 122, 110, 255],
            type: "primary"
          },
          {
            name: "COLLEGIATE NAVY",
            name_fr: "marine collégial",
            color: [49, 50, 64, 255],
            type: "primary"
          },
          {
            name: "WHITE",
            name_fr: "Blanc",
            color: [255, 255, 255, 255],
            type: "primary"
          },
          {
            name: "BLUE DAWN",
            name_fr: "aube bleue",
            color: [179, 187, 219, 255],
            type: "secondary"
          },
          {
            name: "BLUE FUSION",
            name_fr: "Fusion bleue",
            color: [116, 128, 188, 255],
            type: "secondary"
          },
          {
            name: "COLLEGIATE NAVY",
            name_fr: "marine collégial",
            color: [49, 50, 64, 255],
            type: "secondary"
          },
          {
            name: "WONDER TAUPE",
            name_fr: "wonder taupe",
            color: [181, 166, 158, 255],
            type: "secondary"
          }
        ]
      },
      {
        name: "Pink Strata",
        name_fr: "Rose strada",
        delivery: "5/1",
        images: [
          `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fwomens_app%2Fcolor_stories%2FPink_Strata.jpg?alt=media`,
          `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fwomens_app%2Fcolor_stories%2FPink_Strata_2.jpg?alt=media`,
          `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fwomens_app%2Fcolor_stories%2FPink_Strata_3.jpg?alt=media`,
          `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fwomens_app%2Fcolor_stories%2FPink_Strata_4.jpg?alt=media`,
          `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fwomens_app%2Fcolor_stories%2FPink_Strata_5.jpg?alt=media`
        ],
        colors: [
          {
            name: "PINK STRATA",
            name_fr: "Rose strada",
            color: [181, 127, 140, 255],
            type: "primary"
          },
          {
            name: "SILVER PEBBLE",
            name_fr: "galet argenté",
            color: [134, 130, 119, 255],
            type: "primary"
          },
          {
            name: "OLIVE STRATA",
            name_fr: "Olive strada",
            color: [102, 95, 76, 255],
            type: "primary"
          },
          {
            name: "BLUE FUSION",
            name_fr: "Fusion bleue",
            color: [116, 128, 188, 255],
            type: "secondary"
          },
          {
            name: "COLLEGIATE NAVY",
            name_fr: "marine collégial",
            color: [49, 50, 64, 255],
            type: "secondary"
          },
          {
            name: "WONDER TAUPE",
            name_fr: "wonder taupe",
            color: [181, 166, 158, 255],
            type: "secondary"
          }
        ]
      }
    ],
    mustHaves: {
      leftImage: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fwomens_app%2Fpage_assets%2FMust_Haves_Header_Left.jpg?alt=media`,
      rightImage: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fwomens_app%2Fpage_assets%2FMust_Haves_Header_Right.jpg?alt=media`,
      title: "MUST HAVES",
      title_fr: "LES INCONTOURNABLES",
      sections: [
        {
          title: "Polos",
          title_fr: "Polos",
          imageURL: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fwomens_app%2Fpage_assets%2FPolos_Header.jpg?alt=media`,
          products: [
            {
              name: "UTIMATE365 PRINTED LONG SLEEVE POLO",
              name_fr: "UTIMATE365 PRINTED LONG SLEEVE POLO",
              articleCode: "TW3506S23",
              imageCount: 5,
              IdPrice: "TW3506S23 - $85",
              sizes: "XS - 2XL",
              sizes_fr: "TP - 2TG",
              description:
                "This adidas women's golf polo shirt is made of soft, moisture-wicking fabric for comfort on and off the course. A curved hem and mock neck add extra coverage. Four-way stretch fabric ensures full range of motion. Made with a series of recycled materials, and at least 70% recycled content, this product represents just one of our solutions to help end plastic waste.",
              description_fr:
                "Ce polo de golf adidas pour femme est fabriqué dans un tissu doux qui évacue l'humidité pour un confort optimal sur et à l'extérieur du parcours. Un ourlet et un col montant ajoutent une couverture supplémentaire. Le tissu extensible dans les quatre directions assure une amplitude de mouvement complète. Fabriqué à partir d'une série de matériaux recyclés d'au moins 70%, ce produit ne représente qu'une de nos solutions pour aider à mettre fin aux déchets plastiques.",
              keyPoints: [
                "Regular fit",
                "Quarter-zip with mock neck",
                "88% recycled polyester, 12% elastane single jersey",
                "Four-way stretch fabric",
                "Slightly curved front and back hem",
                "Moisture-wicking",
                "Year-round comfort"
              ],
              keyPoints_fr: [
                "Coupe régulière",
                "1/4 zip ",
                "Jersey simple 88% polyester recyclé, 12% élasthanne",
                "Tissu extensible dans les quatre directions",
                "Ourlet avant et arrière légèrement courbé",
                "Évacuation de l'humidité",
                "Confort tout au long de l'année"
              ]
            },
            {
              name: "GO-TO SLEEVELESS POLO",
              name_fr: "GO-TO SLEEVELESS POLO",
              articleCode: "TW3608S23",
              imageCount: 6,
              IdPrice: "TW3608S23 - $75",
              sizes: "XS - XL",
              sizes_fr: "TP - TG",
              description:
                "Stay cool when the day gets hot. This women's adidas golf polo combines casual sleeveless style with cutting edge performance fabric to keep you cool and comfortable as the course heats up. The cotton-blend brrr° knit has a super-soft feel and plenty of stretch plus a triple chill effect thanks to cooling minerals, moisture-wicking fibres and a quick-drying feel. It's cut with a slightly longer back hem to keep it tucked in through your final putt. A minimum of 70% of this product is a blend of recycled and renewable materials.",
              description_fr:
                "Restez au frais lors des journées plus chaudes. Ce polo de golf adidas sans manche pour femme associe un style décontracté et un tissu performant pour vous garder au frais et à l'aise lorsque la température se réchauche.  Le tricot brrr° en coton mélangé est très doux au toucher et très extensible. Il offre un triple effet de fraîcheur grâce à des minéraux rafraîchissants, des fibres qui évacuent l'humidité et un séchage rapide. Il est coupé avec un ourlet arrière légèrement plus long pour qu'il reste bien en place tout au long de la partie.  Un minimum de 70% de ce produit est un mélange de matériaux recyclés et renouvelables.",
              keyPoints: [
                "Regular fit",
                "One-button ribbed polo collar with contrast tipping",
                "64% cotton, 36% recycled polyester doubleknit",
                "Rich cotton feel with stretch",
                "brrr° Triple Chill Effect fabric",
                "Scoop hem",
                "Side vents",
                "Slightly longer back hem"
              ],
              keyPoints_fr: [
                "Coupe régulière",
                "Col polo côtelé avec un bouton et bordures contrastantes.",
                "Double tricot 64 % coton, 36 % polyester recyclé",
                "Sensation de coton extensible",
                "Tissu brrr° Triple Chill Effect",
                "Ourlet évasé",
                "Fentes latérales",
                "Ourlet arrière légèrement plus long"
              ]
            },
            {
              name: "JACQUARD POLO SHIRT",
              name_fr: "JACQUARD POLO SHIRT",
              articleCode: "TW3602S23",
              imageCount: 8,
              IdPrice: "TW3602S23 - $75",
              sizes: "XS - XL",
              sizes_fr: "TP - TG",
              description:
                "From long drives to short putts, keep the focus on your game in this women's adidas golf polo shirt. The curved hem and low collar give it modern course style. Made with 100% recycled materials, this product represents just one of our solutions to help end plastic waste.",
              description_fr:
                "Des longs coups de départ aux petits coups roulés, restez concentré sur votre jeu dans ce polo de golf adidas pour femme. L'ourlet courbé et son col lui confèrent un style moderne. Fabriqué à partir de matériaux 100 % recyclés, ce produit n'est qu'une de nos solutions pour mettre fin aux déchets plastiques.",
              keyPoints: [
                "Regular fit",
                "Three-button low collar",
                "100% recycled polyester",
                "Slightly curved front and back hem"
              ],
              keyPoints_fr: [
                "Coupe régulière",
                "Col bas à trois boutons",
                "100% polyester recyclé",
                "Ourlet avant et arrière légèrement courbé"
              ]
            },
            {
              name: "ULTIMATE365 PRINTED SLEEVELESS GOLF POLO",
              name_fr: "ULTIMATE365 PRINTED SLEEVELESS GOLF POLO",
              articleCode: "TW3501S23",
              imageCount: 8,
              IdPrice: "TW3501S23 - $70",
              sizes: "XS - 2XL",
              sizes_fr: "TP - TG",
              description:
                "This women's adidas golf polo shirt is made of single-jersey fabric for year-round comfort on the course. Four-way stretch ensures a full range of motion. The feminine silhouette gives it on- and off-course style. Made with a series of recycled materials, and at least 70% recycled content, this product represents just one of our solutions to help end plastic waste.",
              description_fr:
                "Ce polo de golf adidas pour femme est concu de jersey simple pour un confort tout au long de l'année sur le parcours. L'extensibilité quadri-directionnelle assure une liberté de mouvement complète. La silhouette féminine lui confère du style autant sur qu'à l'extérieur du parcours. Fabriqué à partir d'une série de matériaux recyclés d'au moins 70%, ce produit ne représente qu'une de nos solutions pour aider à mettre fin aux déchets plastiques.",
              keyPoints: [
                "Regular fit",
                "Four-button polo collar",
                "88% recycled polyester, 12% elastane single jersey",
                "Four-way stretch fabric",
                "Scoop hem",
                "Moisture-wicking"
              ],
              keyPoints_fr: [
                "Coupe régulière",
                "Col polo à quatre boutons",
                "Jersey simple 88 % polyester recyclé, 12 % élasthanne",
                "Tissu extensible dans les quatre directions",
                "Ourlet évasé",
                "Évacuation de l'humidité"
              ]
            },
            {
              name: "OTTOMAN SLEEVELESS POLO",
              name_fr: "OTTOMAN SLEEVELESS POLO",
              articleCode: "TW3508S23",
              imageCount: 8,
              IdPrice: "TW3508S23 - $70",
              sizes: "XS - XL",
              sizes_fr: "TP - TG",
              description:
                "This women's adidas golf polo shirt gives you sporty style for the course. It's made with four-way stretch for full mobility. The half zip with a low collar gives it a modern look. Made with a series of recycled materials, and at least 70% recycled content, this product represents just one of our solutions to help end plastic waste.",
              description_fr:
                "Ce polo de golf adidas pour femme vous donne un style sportif sur le parcours. Il est conçu en tissu extensible quadri-directionnel pour une mobilité complète. Le 1/2 zip avec un col bas lui confère un look moderne. Fabriqué à partir d'une série de matériaux recyclés d'au moins 70%, ce produit ne représente qu'une de nos solutions pour aider à mettre fin aux déchets plastiques.",
              keyPoints: [
                "Regular fit",
                "Half zip with low collar",
                "93% recycled polyester, 7% elastane single jersey",
                "Four-way stretch fabric",
                "Scoop hem",
                "Allover stripes"
              ],
              keyPoints_fr: [
                "Coupe régulière",
                "1/2 zip avec col bas",
                "Single jersey 93% polyester recyclé, 7% élasthanne",
                "Tissu extensible dans les quatre direction",
                "Ourlet arrondi",
                "Rayures texturées"
              ]
            }
          ]
        },
        {
          title: "Layering",
          title_fr: "Superposition",
          imageURL: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fwomens_app%2Fpage_assets%2FLayering_Header.jpg?alt=media`,
          products: [
            {
              name: "1/4 ZIP KNIT LAYER",
              name_fr: "1/4 ZIP KNIT LAYER",
              articleCode: "TW4620S23",
              imageCount: 8,
              IdPrice: "TW4620S23 - $85",
              sizes: "XS - 2XL",
              sizes_fr: "TP - 2TG",
              description:
                "This women's adidas golf pullover is made for cool-morning rounds and late afternoons at the range. The stand-up collar adds extra coverage, and built-in stretch ensures a full range of motion. Made with a series of recycled materials, and at least 70% recycled content, this product represents just one of our solutions to help end plastic waste.",
              description_fr:
                "Ce chandail de golf adidas pour femme est conçu pour les températures plus fraîches. Le col montant offre une couverture supplémentaire et le tissu extensible intégré assure une liberté de mouvements totale. Fabriqué à partir d'une série de matériaux recyclés d'au moins 70%, ce produit ne représente qu'une de nos solutions pour aider à mettre fin aux déchets plastiques.",
              keyPoints: [
                "Regular fit",
                "Quarter-zip with stand-up collar",
                "91% recycled polyester, 9% elastane doubleknit",
                "Curved hem"
              ],
              keyPoints_fr: [
                "Coupe régulière",
                "1/4 zip avec col montant",
                "Tricot double 91 % polyester recyclé, 9 % élasthanne",
                "Ourlet cintré"
              ]
            },
            {
              name: "PERFORMANCE HOODIE",
              name_fr: "PERFORMANCE HOODIE",
              articleCode: "TW4625S23",
              imageCount: 9,
              IdPrice: "TW4625S23 - $80",
              sizes: "S - 2XL",
              sizes_fr: "TP - 2TG",
              description:
                "This women's adidas golf hoodie is a perfect layer for cool-weather rounds. The brushed fabric has a super-soft feel, and built-in stretch ensures a full range of motion. A curved hem finishes the look. Made with a series of recycled materials, and at least 70% recycled content, this product represents just one of our solutions to help end plastic waste.",
              description_fr:
                "Ce chandail à capuchon adidas pour femme est parfait pour les parties de golf par temps frais. Le tissu brossé est très doux au toucher et le tissu extensible intégré garantit une liberté de mouvement totale. Un ourlet incurvé termine le look. Fabriqué à partir d'une série de matériaux recyclés d'au moins 70%, ce produit ne représente qu'une de nos solutions pour aider à mettre fin aux déchets plastiques.",
              keyPoints: [
                "Regular fit",
                "Hooded",
                "82% recycled polyester, 18% elastane single jersey",
                "Brushed super-soft material",
                "Curved hem"
              ],
              keyPoints_fr: [
                "Coupe régulière",
                "Capuchon",
                "Jersey simple 82 % polyester recyclé, 18 % élasthanne",
                "Matière super douce brossée",
                "Ourlet cintré"
              ]
            },
            {
              name: "TEXTURED FULL-ZIP JACKET",
              name_fr: "TEXTURED FULL-ZIP JACKET",
              articleCode: "TW4600S22",
              imageCount: 10,
              IdPrice: "TW4600S22 - $75",
              sizes: "XS - 2XL",
              sizes_fr: "TP - 2TG",
              description:
                "Bring some style to your cool-weather round. This women's adidas golf jacket is made from a blend of materials that ensures comfortable warmth and an iconic look. This garment is made with high-performance recycled materials and contains a minimum of 60% recycled content.",
              description_fr:
                "Apportez du style à vos parties par temps frais. Cette veste de golf adidas pour femme est fabriquée à partir d'un mélange de matériaux qui garantit chaleur, confort et un look emblématique. Ce vêtement est fabriqué avec des matériaux recyclés haute performance et contient un minimum de 60 % de contenu recyclé.",
              keyPoints: [
                "Regular fit",
                "Full zip with high stand-up collar",
                "Main: 100% recycled polyester doubleknit",
                "Front body: 96% recycled polyester, 4% elastane jacquard",
                "Front pockets",
                "Cuffed sleeves",
                "Banded hem"
              ],
              keyPoints_fr: [
                "Coupe régulière",
                "Fermeture éclair complète avec col montant",
                "Partie principale : 100 % polyester recyclé en double tricot",
                "Corps avant : jacquard 96 % polyester recyclé, 4 % élasthanne",
                "Poches sur le devant",
                "Manches à revers",
                "Bordure d'ourlet"
              ]
            }
          ]
        },
        {
          title: "Bottoms",
          title_fr: "Bases",
          imageURL: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fwomens_app%2Fpage_assets%2FBottoms_Header.jpg?alt=media`,
          products: [
            {
              name: "SLEEVELESS ROMPER",
              name_fr: "SLEEVELESS ROMPER",
              articleCode: "TW6849S23",
              imageCount: 6,
              IdPrice: "TW6849S23 - $110",
              sizes: "XS - XL",
              sizes_fr: "TP - TG",
              description:
                "Bring sporty style to your game in this women's adidas golf romper. The soft, stretchy construction is built to keep you comfortable on the course. A drawcord-adjustable waist provides a modern look. Our cotton products support more sustainable cotton farming.",
              description_fr:
                "Apportez un style sportif à votre jeu dans cette grenouillère de golf adidas pour femme. Sa construction simple et extensible est conçue pour vous permettre d'être à l'aise sur le parcours. La taille ajustable par cordon de serrage offre un look très moderne. ",
              keyPoints: [
                "Regular fit",
                "Six-button front with polo collar",
                "90% cotton, 10% elastane single jersey",
                "Soft, stretchy fabric",
                "Two front pockets and one back pocket",
                "Drawcord-adjustable waist",
                '4" inseam'
              ],
              keyPoints_fr: [
                "Coupe régulière",
                "Devant à six boutons avec col polo",
                "Single jersey 90% coton, 10% élasthanne",
                "Tissu doux et extensible",
                "Deux poches avant et une poche arrière",
                "Taille ajustable par cordon de serrage",
                'Entrejambe de 4"'
              ]
            },
            {
              name: "PRINTED SKORT",
              name_fr: "PRINTED SKORT",
              articleCode: "TW6847S23",
              imageCount: 9,
              IdPrice: "TW6847S23 - $90",
              sizes: "XS - 2XL",
              sizes_fr: "TP - 2TG",
              description:
                "Tee off in sporty style in this women's adidas golf skort. It's made of soft, stretchy fabric for extra comfort. Attached undershorts let you swing and bend with confidence. Made with a series of recycled materials, and at least 70% recycled content, this product represents just one of our solutions to help end plastic waste.",
              description_fr:
                "Prenez le départ dans un style sportif avec cette jupe-short de golf adidas pour femme. Elle est fabriquée dans un tissu doux et extensible pour plus de confort. Le sous-short vous permet de vous élancer et de vous pencher en toute confiance. Fabriqué à partir d'une série de matériaux recyclés d'au moins 70%, ce produit ne représente qu'une de nos solutions pour aider à mettre fin aux déchets plastiques.",
              keyPoints: [
                'Regular fit with 16" length',
                "88% recycled polyester, 12% elastane single jersey",
                "Soft, stretchy fabric",
                "Front and back pockets",
                "Attached undershorts with right side pocket",
                "Allover graphic"
              ],
              keyPoints_fr: [
                'Coupe régulière avec une longueur de 16"',
                "Jersey simple 88% polyester recyclé, 12% élasthanne",
                "Tissu doux et extensible",
                "Poches avant et arrière",
                "Sous-short attaché avec poche latérale droite",
                "Imprimé"
              ]
            },
            {
              name: "SEERSUCKER SKORT",
              name_fr: "SEERSUCKER SKORT",
              articleCode: "TW6850S23",
              imageCount: 6,
              IdPrice: "TW6850S23 - $85",
              sizes: "XS - XL",
              sizes_fr: "TP - TG",
              description:
                "This adidas golf skort is designed for comfort on the course. It's made of soft, stretchy fabric for mobility, with a wide waistband that gives a supportive feel. Attached undershorts let you swing and bend with confidence. Made with a series of recycled materials, and at least 70% recycled content, this product represents just one of our solutions to help end plastic waste.",
              description_fr:
                "Ce short de golf adidas est conçu pour être confortable sur le parcours. Fabriqué dans un tissu doux et extensible pour la mobilité avec une large ceinture qui offre une sensation de soutien. Le sous-short attaché vous permet de vous élancer et de vous pencher en toute confiance. Fabriqué à partir d'une série de matériaux recyclés d'au moins 70%, ce produit ne représente qu'une de nos solutions pour aider à mettre fin aux déchets plastiques.",
              keyPoints: [
                'Regular fit with 16" length',
                "Wide elastic waist with drawcord",
                "90% recycled polyester, 10% elastane seersucker",
                "Soft, stretchy fabric",
                "Front and back pockets",
                "Attached undershorts with right side pocket"
              ],
              keyPoints_fr: [
                'Coupe régulière avec une longueur de 16"',
                "Large taille élastique avec cordon de serrage",
                "90% polyester recyclé, 10% élasthanne seersucker",
                "Tissu doux et extensible",
                "Poches avant et arrière",
                "Sous-short attaché avec poche latérale droite"
              ]
            },
            {
              name: "PINTUCK PANT",
              name_fr: "PINTUCK PANT",
              articleCode: "TW6868S23CF",
              imageCount: 5,
              IdPrice: "TW6868S23CF - $110",
              sizes: "XS - XL",
              sizes_fr: "TP - TG",
              description:
                "A fresh take on performance wear for the golfer focused on playing her best. These women's adidas golf pants feature an extra-soft feel and full stretch for unrestricted mobility.",
              description_fr:
                "Une nouvelle approche des vêtements performants pour la golfeuse qui veut donner le meilleur d'elle-même. Ce pantalon de golf adidas pour femme est extra-doux au toucher et entièrement extensible pour une mobilité sans restriction.",
              keyPoints: [
                "90% recycled polyester, 10% elastane",
                "Open front pockets",
                "Two back pockets"
              ],
              keyPoints_fr: [
                "90% polyester recyclé, 10% élasthanne",
                "Poches ouvertes sur le devant",
                "Deux poches arrière"
              ]
            }
          ]
        }
      ]
    },
    keyTakeaways: [
      {
        title: "ULTIMATE365 TOUR",
        title_fr: "ULTIMATE365 TOUR",
        imageURL: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fwomens_app%2Fpage_assets%2FStatement.jpg?alt=media`,
        keyPoints: [
          {
            bullet:
              "WEARABLE, PREMIUM PRODUCT THAT HAVE BEEN INSPIRED BY AND FOR THE ATHLETE",
            bullet_fr:
              "WEARABLE, PREMIUM PRODUCT THAT HAVE BEEN INSPIRED BY AND FOR THE ATHLETE"
          },
          {
            bullet: "HERO APPAREL STORY FOR THE SEASON",
            bullet_fr: "HERO APPAREL STORY FOR THE SEASON"
          }
        ]
      },
      {
        title: "VERSATILITY",
        title_fr: "VERSATILITY",
        imageURL: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fwomens_app%2Fpage_assets%2FVersatility.jpg?alt=media`,
        keyPoints: [
          {
            bullet:
              "REFRESHED GO-TO STYLES WITH SOFT FABRICATIONS AND OFF-COURSE APPEAL",
            bullet_fr:
              "REFRESHED GO-TO STYLES WITH SOFT FABRICATIONS AND OFF-COURSE APPEAL"
          },
          {
            bullet:
              "VARIETY OF LAYERING SILHOUETTES TO MATCH HER PERSONAL STYLE",
            bullet_fr:
              "VARIETY OF LAYERING SILHOUETTES TO MATCH HER PERSONAL STYLE"
          },
          {
            bullet:
              "EVOLUTION IN DRESS CATEGORGY WITH NEW PROGRESSIVE GOLF SILHOUETTES IN ROMPER, SLEEVELESS MOCK AND GO-TO DRESS",
            bullet_fr:
              "EVOLUTION IN DRESS CATEGORGY WITH NEW PROGRESSIVE GOLF SILHOUETTES IN ROMPER, SLEEVELESS MOCK AND GO-TO DRESS"
          }
        ]
      },
      {
        title: "BOTTOMS",
        title_fr: "BOTTOMS",
        imageURL: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fwomens_app%2Fpage_assets%2FBottoms.jpg?alt=media`,
        keyPoints: [
          {
            bullet:
              "INTRODUCTION OF NEW MATERIALS EXECUTIONS, NOVELTY AND SILHOUETTES TO EXPAND HER CLOSET",
            bullet_fr:
              "INTRODUCTION OF NEW MATERIALS EXECUTIONS, NOVELTY AND SILHOUETTES TO EXPAND HER CLOSET"
          },
          {
            bullet:
              "CONTINUED MOMENTUM IN CORE BOTTOMS PROGRAM WITH ULTIMATE365 SKORT, FRILL SKORT AND PIINTUCK PULL-ON PANT",
            bullet_fr:
              "CONTINUED MOMENTUM IN CORE BOTTOMS PROGRAM WITH ULTIMATE365 SKORT, FRILL SKORT AND PIINTUCK PULL-ON PANT"
          }
        ]
      }
    ]
  },
  juniors_app: {
    pageTitle: "Junior Apparel",
    pageTitle_fr: "VÊTEMENTS JUNIORS",
    headerBackgroundImage: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fjuniors_app%2Fpage_assets%2Fyouth-header-background.jpg?alt=media`,
    headerOverlayImage: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fjuniors_app%2Fpage_assets%2Fyouth-header-overlay.png?alt=media`,
    colorStories: [
      {
        name: "Girls",
        name_fr: "filles",
        delivery: "",
        images: [
          `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fjuniors_app%2Fcolor_stories%2FGirls.jpg?alt=media`,
          `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fjuniors_app%2Fcolor_stories%2FGirls_2.jpg?alt=media`
        ],
        colors: []
      },
      {
        name: "Boys",
        name_fr: "garçons",
        delivery: "",
        images: [
          `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fjuniors_app%2Fcolor_stories%2FBoys.jpg?alt=media`,
          `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fjuniors_app%2Fcolor_stories%2FBoys_2.jpg?alt=media`
        ],
        colors: []
      }
    ],
    mustHaves: {
      leftImage: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fjuniors_app%2Fpage_assets%2FMust%20Haves%20Header%20Left.jpg?alt=media`,
      rightImage: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fjuniors_app%2Fpage_assets%2FMust%20Haves%20Header%20Right.jpg?alt=media`,
      title: "MUST HAVES",
      title_fr: "LES INCONTOURNABLES",
      sections: [
        {
          title: "Unisex",
          title_fr: "unisexe",
          imageURL: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fjuniors_app%2Fpage_assets%2FUnisex_Section_Header.jpg?alt=media`,
          products: [
            {
              name: "UNISEX HOODIE",
              name_fr: "UNISEX HOODIE",
              articleCode: "TB1595S23",
              imageCount: 9,
              IdPrice: "TB1595S23 - $70",
              sizes: "S - XL",
              sizes_fr: "TP - TG",
              description:
                "This juniors' adidas golf hoodie keeps you warm during cool-weather rounds. The cotton-polyester French terry feels soft and cozy, and banded sleeves and hem ensure a stay-put feel. The quarter-zip design makes it easy to pull off when the temperature rises. A minimum of 70% of this product is a blend of recycled and renewable materials.",
              description_fr:
                "Ce chandail de golf à capuchon adidas junior garde au chaud lors des parties par temps frais. ''French terry'' en coton-polyester est doux et confortable.  Les manches et l'ourlet à bandes vous permettent de rester en place. Le 1/4 zip permet de l'enlever facilement. Un minimum de 70% de ce produit est un mélange de matériaux recyclés et renouvelables.",
              keyPoints: [
                "Regular fit",
                "Quarter zip with hood",
                "70% cotton, 30% recycled polyester",
                "French terry",
                "Soft feel",
                "Front pockets",
                "Banded sleeves and hem",
                "Colorblocked"
              ],
              keyPoints_fr: [
                "Coupe régulière",
                "1/4 zip avec capuchon",
                "70% coton, 30% polyester recyclé",
                '"French terry"',
                "Toucher doux",
                "Poches avant",
                "Manches et ourlet en bande",
                "Bloc de couleurs"
              ]
            }
          ]
        },
        {
          title: "Girls",
          title_fr: "filles",
          imageURL: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fjuniors_app%2Fpage_assets%2FGirls_Section_Header.jpg?alt=media`,
          products: [
            {
              name: "GIRLS HEAT.READY SLEEVELESS PRINTED POLO SHIRT",
              name_fr: "GIRLS HEAT.READY SLEEVELESS PRINTED POLO SHIRT",
              articleCode: "TG1581S23",
              imageCount: 6,
              IdPrice: "TG1581S23 - $50",
              sizes: "S - XL",
              sizes_fr: "TP - TG",
              description:
                "Stay cool. Stay focused. This junior girls' adidas golf polo shirt is made with HEAT.RDY to keep you comfortable on the hottest days. The doubleknit fabric has a soft feel. A lively graphic on the front contrasts with the solid-colored back for a modern look. Made with 100% recycled materials, this product represents just one of our solutions to help end plastic waste.",
              description_fr:
                "Restez au frais, restez concentrée. Ce polo de golf adidas junior pour filles est fabriqué avec HEAT.RDY pour être confortable les jours plus chauds. Le tissu à double tricotage est doux au toucher. Un imprimé vivant sur le devant contraste avec le dos de couleur unie pour un ''look'' moderne. Fabriqué avec des matériaux 100% recyclés, ce produit n'est qu'une de nos solutions pour aider à mettre fin aux déchets plastiques.",
              keyPoints: [
                "Regular fit",
                "V-neck with rounded collar",
                "100% recycled polyester doubleknit",
                "Soft feel",
                "HEAT.RDY",
                "Curved hem",
                "Graphic front body and solid-colored back body",
                "Breathable, moisture-wicking materials"
              ],
              keyPoints_fr: [
                "Coupe régulière",
                "Encolure en V avec col arrondi",
                "Tricot double 100% polyester recyclé",
                "Toucher doux",
                "HEAT.RDY",
                "Ourlet cintré",
                "Devant imprimé et dos de couleur unie",
                "Matériaux respirants et évacuant l'humidité"
              ]
            },
            {
              name: "GOLF ROMPER",
              name_fr: "GOLF ROMPER",
              articleCode: "TG6346S23",
              imageCount: 5,
              IdPrice: "TG6346S23 - $65",
              sizes: "S - XL",
              sizes_fr: "TP - TG",
              description:
                "Simple, sporty style for the course. This junior girls' adidas golf romper is made of doubleweave fabric for all-day comfort. It has a half-zip design with a low, stand-up collar to keep your look on point. Pockets hold extra tees and markers. Made with 100% recycled materials, this product represents just one of our solutions to help end plastic waste.",
              description_fr:
                "Un style simple et sportif pour le parcours. Cette grenouillère de golf adidas pour filles junior est fabriquée en tissu double tissage pour un confort tout au long de la journée. Elle est munie d'une fermeture éclair et d'un col montant pour un ''look'' impeccable. Les poches permettent de ranger les tees et les marqueurs supplémentaires. Fabriqué à partir de matériaux 100 % recyclés, ce produit n'est qu'une de nos solutions pour mettre fin aux déchets plastiques.",
              keyPoints: [
                "Regular fit",
                "Half zip with low stand-up collar",
                "100% recycled polyester doubleweave",
                "Two front pockets",
                "One back pocket"
              ],
              keyPoints_fr: [
                "Coupe régulière",
                "1/2 zip ",
                "Double tissage 100% polyester recyclé",
                "Deux poches avant",
                "Une poche arrière"
              ]
            },
            {
              name: "RUFFLE SKORT",
              name_fr: "RUFFLE SKORT",
              articleCode: "TG6313S20",
              imageCount: 7,
              IdPrice: "TG6313S20 - $55",
              sizes: "S - XL",
              sizes_fr: "TP - TG",
              description:
                "Confident style for the course. From tee off to the clubhouse, the juniors' adidas Ruffled Skort supports your game with compression-fit tights and a soft, stretchy feel that gives you freedom to move. Walk, bend and play through in comfort on warm summer days.",
              description_fr:
                "Un style sûr pour le parcours. Du départ au ''clubhouse'', la jupe-short à volants adidas junior a un toucher doux et extensible qui vous permet de bouger librement. Marchez et jouez en tout confort pendant les chaudes journées.",
              keyPoints: [
                "Regular fit",
                "Stretch waist",
                "88% recycled polyester, 12% elastane jersey",
                "Soft, stretchy fabric",
                "Golf skort",
                "Front pockets",
                "Tiered ruffles on back and mesh trim material",
                "Inner short tights",
                "UV 50+ factor",
                '11" inseam'
              ],
              keyPoints_fr: [
                "Coupe régulière",
                "Taille extensible",
                "88% polyester recyclé, 12% élasthanne",
                "Tissu doux et extensible",
                "Jupe de golf",
                "Poches sur le devant",
                "Volants étagés à l'arrière et tissu de garniture en filet",
                "Collants courts intérieurs",
                "Facteur UV 50",
                'Entrejambe de 11"'
              ]
            }
          ]
        },
        {
          title: "Boys",
          title_fr: "garçons",
          imageURL: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fjuniors_app%2Fpage_assets%2FBoys_Section_Header.jpg?alt=media`,
          products: [
            {
              name: "KEY PRINT POLO",
              name_fr: "KEY PRINT POLO",
              articleCode: "TB1590S23",
              imageCount: 5,
              IdPrice: "TB1590S23 - $55",
              sizes: "S - XL",
              sizes_fr: "TP - TG",
              description:
                "Liven up your game in this junior boys' adidas golf polo shirt. The lightweight, quick-drying fabric is made with four-way stretch for unrestricted movement. The allover print keeps your look fresh. Made with a series of recycled materials, and at least 70% recycled content, this product represents just one of our solutions to help end plastic waste.",
              description_fr:
                "Mettez de la vie dans votre jeu avec ce polo de golf adidas junior pour garçons. Le tissu léger et à séchage rapide est extensible dans les quatre sens pour un mouvement sans restriction. Fabriqué à partir d'une série de matériaux recyclés d'au moins 70%, ce produit ne représente qu'une de nos solutions pour aider à mettre fin aux déchets plastiques.",
              keyPoints: [
                "Regular fit",
                "Three-button polo collar",
                "92% recycled polyester, 8% elastane mesh",
                "Soft, four-way stretch fabric",
                "Durable and quick-drying"
              ],
              keyPoints_fr: [
                "Coupe régulière",
                "Col polo à trois boutons",
                "92 % polyester recyclé, 8 % élasthanne",
                "Tissu doux et extensible dans les quatre sens",
                "Durable et à séchage rapide"
              ]
            },
            {
              name: "PULL ON PANT",
              name_fr: "PULL ON PANT",
              articleCode: "TB6326S23",
              imageCount: 6,
              IdPrice: "TB6326S23 - $65",
              sizes: "S - XL",
              sizes_fr: "TP - TG",
              description:
                "Play through with a laid-back look in these juniors' adidas golf pants. The jogger style is made of soft, durable fabric for all-day comfort and repeated wear. A drawcord at the waist lets you adjust the fit, and the back patch pocket holds your card. A minimum of 70% of this product is a blend of recycled and renewable materials.",
              description_fr:
                "Jouez avec un look décontracté dans ce pantalon de golf adidas junior. Le style ''jogger'' est fait de tissu doux et durable pour un confort tout au long de la journée. Un cordon de serrage à la taille vous permet d'ajuster la coupe, et la poche arrière plaquée peut accueillir votre carte de pointage. Au moins 70 % de ce produit est un mélange de matériaux recyclés et renouvelables.",
              keyPoints: [
                "Regular fit",
                "Drawcord waist with belt loops",
                "100% recycled polyester dobby",
                "Front pockets",
                "One back patch pocket",
                "Elastic cuffs"
              ],
              keyPoints_fr: [
                "Coupe régulière",
                "Cordon de serrage à la taille avec passant de ceinture",
                "100% polyester recyclé",
                "Poches avant",
                "Une poche arrière plaquée",
                "Poignets élastiques"
              ]
            },
            {
              name: "ULTIMATE365 ADJUSTABLE GOLF SHORTS",
              name_fr: "ULTIMATE365 ADJUSTABLE GOLF SHORTS",
              articleCode: "TB6315S22",
              imageCount: 5,
              IdPrice: "TB6315S22 - $60",
              sizes: "S - XL",
              sizes_fr: "TP - TG",
              description:
                "Every day is a good day for golf, and these juniors' adidas shorts are made for the course. A water-repellent coating keeps you dry on dewy fairways. The drawcord waist lets you adjust the fit. Made with 100% recycled materials, this product represents just one of our solutions to help end plastic waste.",
              description_fr:
                "Chaque jour est un bon jour pour le golf, et ce short adidas pour junior est fait pour le parcours. Un revêtement déperlant vous garde au sec sur les allées humides. Le cordon de serrage à la taille vous permet d'ajuster la coupe. Fabriqué à partir de matériaux 100% recyclés, ce produit n'est qu'une de nos solutions pour aider à mettre fin aux déchets plastiques.",
              keyPoints: [
                "Regular fit",
                "Drawcord waist with belt loops",
                "100% recycled polyester twill with durable water-repellent (DWR) coating",
                "Front pockets and one back pocket",
                "Elastic waistband on back",
                "Flat-front construction",
                "UV 50+ factor",
                '8.5" inseam'
              ],
              keyPoints_fr: [
                "Coupe régulière",
                "Cordon de serrage à la taille avec passant de ceinture",
                "100 % polyester recyclé avec enduit déperlant durable (DWR)",
                "Poches avant et une poche arrière",
                "Taille élastique à l'arrière",
                "Taille sans pli à l'avant ",
                "Facteur UV 50",
                'Entrejambe de 8,5"'
              ]
            }
          ]
        }
      ]
    },
    keyTakeaways: [
      {
        title: "POLOS",
        title_fr: "POLOS",
        imageURL: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fjuniors_app%2Fpage_assets%2Fkey_takeaways_polos.jpg?alt=media`,
        keyPoints: [
          {
            bullet:
              "CONTINUE SUCCESS WITH TECHNOLOGY FOUND IN THE MEN'S AND WOMEN'S APPAREL LINE",
            bullet_fr:
              "CONTINUE SUCCESS WITH TECHNOLOGY FOUND IN THE MEN'S AND WOMEN'S APPAREL LINE"
          },
          {
            bullet:
              "HEAT.RDY FABRICATION AS WELL AS SPORTY SILHOUETTES WITH ON- AND OFF-COURSE APPEAL",
            bullet_fr:
              "HEAT.RDY FABRICATION AS WELL AS SPORTY SILHOUETTES WITH ON- AND OFF-COURSE APPEAL"
          }
        ]
      },
      {
        title: "UNISEX STYLES",
        title_fr: "UNISEX STYLES",
        imageURL: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fjuniors_app%2Fpage_assets%2Fkey_takeaways_unisex_styles.jpg?alt=media`,
        keyPoints: [
          {
            bullet:
              "INTRODUCTION OF NEW LIFESTYLE-INSPIRED SILHOUETTES WITH THE INTRODUCTION OF A NEW YOUTH HOODIE AND T-SHIRT",
            bullet_fr:
              "INTRODUCTION OF NEW LIFESTYLE-INSPIRED SILHOUETTES WITH THE INTRODUCTION OF A NEW YOUTH HOODIE AND T-SHIRT"
          }
        ]
      },
      {
        title: "BOTTOMS",
        title_fr: "BOTTOMS",
        imageURL: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fjuniors_app%2Fpage_assets%2Fkey_takeaways_bottoms.jpg?alt=media`,
        keyPoints: [
          {
            bullet: "VARIETY OF BOTTOMS SILHOUETTES TO MATCH HIS AND HER STYLE",
            bullet_fr:
              "VARIETY OF BOTTOMS SILHOUETTES TO MATCH HIS AND HER STYLE"
          },
          {
            bullet:
              "ADJUSTABLE WAISTBANDS TO ACCOMMODATE THE GROWING YOUNG ATHLETE",
            bullet_fr:
              "ADJUSTABLE WAISTBANDS TO ACCOMMODATE THE GROWING YOUNG ATHLETE"
          }
        ]
      }
    ]
  },
  footwear: {
    pageTitle: "Footwear",
    pageTitle_fr: "Chaussures",
    headerBackgroundImage: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Ffootwear%2Fpage_assets%2Fpage_header_background.jpg?alt=media`,
    headerOverlayImage: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Ffootwear%2Fpage_assets%2Fpage_header_overlay.png?alt=media`,
    keyTakeaways: [
      {
        title: "ZG23",
        title_fr: "ZG23",
        imageURL: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Ffootwear%2Fpage_assets%2FZG23_key_takeaways.jpg?alt=media`,
        keyPoints: [
          {
            bullet:
              "WILL BE OUR HERO FOOTWEAR STORY FOR THE SEASON ALL ABOUT LIGHTWEIGHT WITH ZERO COMPROMISE WITH UPDATED MIDSOLE AND OUTSOLE TO IMPROVE COMFORT, STABILITY AND TRACTION",
            bullet_fr:
              "WILL BE OUR HERO FOOTWEAR STORY FOR THE SEASON ALL ABOUT LIGHTWEIGHT WITH ZERO COMPROMISE WITH UPDATED MIDSOLE AND OUTSOLE TO IMPROVE COMFORT, STABILITY AND TRACTION"
          }
        ]
      },
      {
        title: "VERSATILITY",
        title_fr: "VERSATILITY",
        imageURL: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Ffootwear%2Fpage_assets%2Fversatility_key_takeaways.jpg?alt=media`,
        keyPoints: [
          {
            bullet:
              "PRODUCT THAT PERFORMS ON THE COURSE BUT HAS OFF-COURSE WEARABILITY",
            bullet_fr:
              "PRODUCT THAT PERFORMS ON THE COURSE BUT HAS OFF-COURSE WEARABILITY"
          },
          {
            bullet:
              "MODELS THAT NOT ONLY ATTRACT THE CORE CONSUMER BUT ALSO THE NEW-AGE GOLFER WITH SUCH MODELS AS RETROCROSS, SG2 SL AND GO-TO",
            bullet_fr:
              "MODELS THAT NOT ONLY ATTRACT THE CORE CONSUMER BUT ALSO THE NEW-AGE GOLFER WITH SUCH MODELS AS RETROCROSS, SG2 SL AND GO-TO"
          }
        ]
      },
      {
        title: "ZOYSIA",
        title_fr: "ZOYSIA",
        imageURL: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Ffootwear%2Fpage_assets%2Fzoysia_key_takeaways.jpg?alt=media`,
        keyPoints: [
          {
            bullet:
              "CREATION CENTERED AROUND THE FEMALE ATHLETES NEEDS AND WANTS SO SHE CAN FEEL CONFIDENT IN ANY SITUATION",
            bullet_fr:
              "CREATION CENTERED AROUND THE FEMALE ATHLETES NEEDS AND WANTS SO SHE CAN FEEL CONFIDENT IN ANY SITUATION"
          },
          {
            bullet:
              "WOMENS EXCLUSIVE MODELS THAT SPEAK TO PERFORMANCE AND VERSATILITY",
            bullet_fr:
              "WOMENS EXCLUSIVE MODELS THAT SPEAK TO PERFORMANCE AND VERSATILITY"
          },
          {
            bullet: "BUILT FOR HER",
            bullet_fr: "BUILT FOR HER"
          }
        ]
      }
    ]
  },
  accessories: {
    pageTitle: "Accessories",
    pageTitle_fr: "Accessoires",
    headerBackgroundImage: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Faccessories%2Fpage_assets%2Fpage_header_background.jpg?alt=media`,
    headerOverlayImage: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Faccessories%2Fpage_assets%2Fpage_header_overlay.png?alt=media`,
    mustHaves: {
      leftImage: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Faccessories%2Fpage_assets%2FMust_Haves_Header_Left.jpg?alt=media`,
      rightImage: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Faccessories%2Fpage_assets%2FMust_Haves_Header_Right.jpg?alt=media`,
      title: "MUST HAVES",
      title_fr: "LES INCONTOURNABLES",
      sections: [
        {
          title: "Men's",
          title_fr: "Hommes",
          imageURL: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Faccessories%2Fpage_assets%2Fmens_section_header.jpg?alt=media`,
          products: [
            {
              name: "HYDROPHOBIC TOUR HAT",
              name_fr: "HYDROPHOBIC TOUR HAT",
              articleCode: "TXM4042S23",
              imageCount: 9,
              IdPrice: "TXM4042S23 - $50",
              sizes: "OSFM",
              sizes_fr: "OSFM",
              description:
                "Ready to hit the links but the forecast calls for rain? Stay dry with this men's adidas golf hat. Water-repellent fabric sheds light showers. A snapback closure lets you adjust for the perfect fit. Made with a series of recycled materials, and at least 60% recycled content, this product represents just one of our solutions to help end plastic waste.",
              description_fr:
                "Vous êtes prêt à aller sur le terrain mais la météo annonce de la pluie? Restez au sec avec cette casquette de golf adidas pour homme. Le tissu déperlant protège contre les averses légères. Une fermeture à boutons-pression permet un ajustement parfait. Fabriqué à partir d'une série de matériaux recyclés d'au moins 60%, ce produit ne représente qu'une de nos solutions pour aider à mettre fin aux déchets plastiques.",
              keyPoints: [
                "One size fits most",
                "87% recycled polyester, 13% elastane dobby",
                "Waterproof",
                "Pre-curved brim",
                "Adjustable snapback closure",
                "Six-panel construction",
                "Vented sides and back for breathability",
                "Hidden stash pocket under crown"
              ],
              keyPoints_fr: [
                "Taille unique",
                "87 % de polyester recyclé, 13 % d'élasthanne ",
                "Imperméable",
                "Bord pré-courbé",
                "Fermeture réglable par bouton-pression",
                "Construction à six panneaux",
                "Côtés et arrière ventilés pour une meilleure respirabilité",
                "Poche de rangement cachée "
              ]
            },
            {
              name: "CORDUROY LEATHER 5 PANEL HAT",
              name_fr: "CORDUROY LEATHER 5 PANEL HAT",
              articleCode: "TXM4052S23",
              imageCount: 5,
              IdPrice: "TXM4052S23 - $45",
              sizes: "OSFM",
              sizes_fr: "OSFM",
              description:
                "This men's adidas golf hat is made of corduroy for a retro look. The five-panel construction gives it a low-profile fit. Leather-rope details add the finishing touch. Made in part with recycled content generated from production waste, e.g. cutting scraps and post-consumer household waste, to avoid the larger environmental impact of producing virgin content. Contains a minimum of 25% recycled content.",
              description_fr:
                "Cette casquette de golf adidas pour homme est fait de velours côtelé pour un ''look'' rétro. Les détails en cuir apportent la touche finale. Fabriquée en partie avec un contenu recyclé provenant de déchets de production (déchets de coupe et/ou ménagers post-consommation) afin d'éviter l'impact environnemental de la production de contenu vierge. Contient un minimum de 25% de contenu recyclé.",
              keyPoints: [
                "One size fits most",
                "Main: 100% cotton corduroy",
                "Lining: 100% recycled polyester",
                "Pre-curved brim",
                "Adjustable back strap with clip",
                "Five-panel construction",
                "3D embroidered adidas wordmark"
              ],
              keyPoints_fr: [
                "Taille unique",
                "Partie principale : 100% coton velours côtelé",
                "Doublure : 100 % polyester recyclé",
                "Bord pré-courbé",
                "Sangle arrière réglable avec clip",
                "Construction à cinq panneaux",
                "Logo adidas brodé en 3D"
              ]
            },
            {
              name: "GOLF LO PRO TRUCKER ",
              name_fr: "GOLF LO PRO TRUCKER ",
              articleCode: "TXM4054S23",
              imageCount: 6,
              IdPrice: "TXM4054S23 - $35",
              sizes: "OSFM",
              sizes_fr: "OSFM",
              description:
                "Stay cool and comfortable on the course with this men's adidas golf hat. The trucker style offers ventilation through its mesh panels. An adjustable snapback closure lets you find the perfect fit. Made with a series of recycled materials, and at least 60% recycled content, this product represents just one of our solutions to help end plastic waste.",
              description_fr:
                "Restez au frais et à l'aise sur le parcours avec cette casquette de golf adidas pour homme. Le style trucker offre une ventilation grâce à ses panneaux en mèche. Une fermeture réglable à boutons-pression permet un ajustement parfait. Fabriqué à partir d'une série de matériaux recyclés d'au moins 60%, ce produit ne représente qu'une de nos solutions pour aider à mettre fin aux déchets plastiques.",
              keyPoints: [
                "One size fits most",
                "86% recycled polyester, 14% elastane plain weave",
                "Embroidered patch logo on front",
                "Pre-curved brim",
                "Adjustable snapback closure",
                "Mesh sides and back",
                "Six-panel construction"
              ],
              keyPoints_fr: [
                "Taille unique",
                "86 % polyester recyclé, 14 % élasthanne",
                "Logo brodé sur le devant",
                "Bord pré-courbé",
                "Fermeture réglable par boutons-pression",
                "Côtés et arrière en mèche",
                "Construction à six panneaux"
              ]
            },
            {
              name: "HEATHERED STRETCH REVERSIBLE BELT",
              name_fr: "HEATHERED STRETCH REVERSIBLE BELT",
              articleCode: "TXM2075S23",
              imageCount: 3,
              IdPrice: "TXM2075S23 - $50",
              sizes: "OSFM",
              sizes_fr: "OSFM",
              description:
                "Change up your look with this men's adidas reversible golf belt. The stretchy build ensures a just-right fit, and the removable buckle lets you cut the belt to the perfect size. Made with a series of recycled materials, and at least 60% recycled content, this product represents just one of our solutions to help end plastic waste.",
              description_fr:
                "Changez votre ''look'' avec cette ceinture de golf réversible adidas pour homme. Sa structure extensible garantit un ajustement parfait, et la boucle amovible vous permet de couper la ceinture à la taille idéale. Fabriqué à partir d'une série de matériaux recyclés d'au moins 60%, ce produit ne représente qu'une de nos solutions pour aider à mettre fin aux déchets plastiques.",
              keyPoints: [
                'Dimensions: 1.5" x 48"',
                "60% recycled polyester, 40% rubber jacquard",
                "Adjustable clamp closure",
                "Reversible: heathered fabric on one side, herringbone on the other",
                "Cut-to-size"
              ],
              keyPoints_fr: [
                'Dimensions : 1.5" x 48"',
                "60% polyester recyclé, 40% caoutchouc jacquard",
                "Fermeture à pince réglable",
                "Réversible : tissu chiné d'un côté, chevron de l'autre",
                "Découpé sur mesure"
              ]
            }
          ]
        },
        {
          title: "Women's",
          title_fr: "Femmes",
          imageURL: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Faccessories%2Fpage_assets%2Fwomens_section_header.jpg?alt=media`,
          products: [
            {
              name: "WOMENS TOUR BADGE HAT",
              name_fr: "WOMENS TOUR BADGE HAT",
              articleCode: "TXW1270S23",
              imageCount: 7,
              IdPrice: "TXW1270S23 - $30",
              sizes: "OSFW",
              sizes_fr: "OSFW",
              description:
                "Shade your eyes on the course in this women's adidas golf hat. It has a moisture-wicking sweatband to keep you dry. The hook-and-loop back closure lets you adjust for the perfect fit. Made with a series of recycled materials, and at least 60% recycled content, this product represents just one of our solutions to help end plastic waste.",
              description_fr:
                "Protéger vos yeux sur le parcours avec cette casquette de golf adidas pour femme. Elle est dotée d'une bande de transpiration qui évacue l'humidité pour vous garder au sec. La fermeture arrière vous permet un ajustement parfait. Fabriqué à partir d'une série de matériaux recyclés d'au moins 60%, ce produit ne représente qu'une de nos solutions pour aider à mettre fin aux déchets plastiques.",
              keyPoints: [
                "One size fits most",
                "100% recycled polyester ripstop",
                "Pre-curved brim",
                "Hook-and-loop back closure",
                "Six-panel construction",
                "Moisture-wicking sweatband"
              ],
              keyPoints_fr: [
                "Taille unique",
                "100% polyester recyclé ripstop",
                "Bord pré-courbé",
                "Fermeture arrière à crochets et boucles",
                "Construction à six panneaux",
                "Bandeau de transpiration évacuant l'humidité"
              ]
            },
            {
              name: "WOMEN'S CRISSCROSS GOLF HAT",
              name_fr: "WOMEN'S CRISSCROSS GOLF HAT",
              articleCode: "TXW1231S22",
              imageCount: 11,
              IdPrice: "TXW1231S22 - $35",
              sizes: "OSFW",
              sizes_fr: "OSFW",
              description:
                "Step to the tee box in style with this women's golf hat. It features a crisscross elastic back for sliding your ponytail through. A silicone adidas Badge of Sport rides front and center. By buying cotton products from us, you're supporting more sustainable cotton farming.",
              description_fr:
                "Rendez-vous sur le tertre de départ avec style grâce à cette casquette de golf pour femme. Arrière élastique entrecroisé dans lequel vous pouvez faire glisser votre queue de cheval. L'insigne adidas en silicone est placé devant et au centre. ",
              keyPoints: [
                "One size fits women",
                "100% cotton twill",
                "Pre-curved brim",
                "Adjustable back strap",
                "Six-panel construction",
                "Better Cotton Initiative"
              ],
              keyPoints_fr: [
                "Taille unique pour les femmes",
                "100% coton croisé",
                "Bord pré-courbé",
                "Sangle arrière réglable",
                "Construction à six panneaux"
              ]
            }
          ]
        },
        {
          title: "Junior",
          title_fr: "Junior",
          imageURL: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Faccessories%2Fpage_assets%2Fyouth_section_header.jpg?alt=media`,
          products: [
            {
              name: "YOUTH NOVELTY PAR THREE HAT",
              name_fr: "YOUTH NOVELTY PAR THREE HAT",
              articleCode: "TXB1050S23",
              imageCount: 4,
              IdPrice: "TXB1050S23 - $30",
              sizes: "OSFY",
              sizes_fr: "OSFY",
              description:
                "This junior boys' adidas golf cap is made of cotton twill fabric for lightweight breathability on the course. The five-panel design has a shallow crown and a pre-curved brim. The adjustable snapback closure offers a custom fit. A minimum of 50% of this product is a blend of recycled and renewable materials.",
              description_fr:
                "Cette casquette de golf adidas junior pour garçons est fabriquée en coton croisé pour une respirabilité le parcours. La conception à cinq panneaux a une couronne peu profonde et un bord pré-courbé. La fermeture réglable à boutons-pression permet un ajustement parfait. Au moins 50 % de ce produit est un mélange de matériaux recyclés et renouvelables.",
              keyPoints: [
                "One size fits youth",
                "100% cotton twill",
                "Pre-curved brim",
                "Adjustable snapback closure",
                "Five-panel construction with shallow crown"
              ],
              keyPoints_fr: [
                "Taille unique pour les jeunes",
                "100% coton croisé",
                "Bord pré-courbé",
                "Fermeture réglable par bouton-pression",
                "Construction à cinq panneaux"
              ]
            }
          ]
        },
        {
          title: "Bags",
          title_fr: "Sacs",
          imageURL: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Faccessories%2Fpage_assets%2Fbags_section_header.jpg?alt=media`,
          products: [
            {
              name: "HYBRID BACKPACK",
              name_fr: "HYBRID BACKPACK",
              articleCode: "Hybrid_Backpack",
              imageCount: 6,
              IdPrice: "$150",
              sizes: "",
              sizes_fr: "",
              description:
                "Keep your essentials organized when heading to a golf outing near or far. This adidas hybrid backpack has plenty of pockets for stashing large and small items. A Sherpa-lined laptop sleeve on the side provides quick access. Side handles let you carry it like a duffel, and a Trolley strap secures it to a carry-on for easy transport. Made in part with recycled content generated from  production waste, e.g. cutting scraps, and post-consumer household waste to avoid the larger environmental impact of producing virgin content. ",
              description_fr:
                "Gardez vos essentiels organisés lorsque vous vous rendez à une sortie de golf proche ou lointaine. Ce sac à dos hybride adidas dispose de nombreuses poches pour ranger petits et grands objets. Une pochette latérale doublée en Sherpa pour ordinateur portable permet un accès rapide. Les poignées latérales vous permettent de le porter comme un sac de voyage, et une sangle le fixe à un bagage à main pour un transport facile. Fabriqué en partie avec un contenu recyclé provenant de déchets de production (déchets de coupe et/ou ménagers post-consommation) afin d'éviter l'impact environnemental de la production de contenu vierge.  ",
              keyPoints: [
                "100% recycled polyester plain weave",
                "Adjustable, contoured shoulder straps",
                "Two front zip pockets, two side zip pockets, water bottle pocket and deeper slip-in front pocket",
                "Easy-access",
                "Sherpa-lined external laptop sleeve plus inner laptop sleeve",
                "Inner mesh zip pocket and organizer",
                "Trolley strap slips onto a carry-on",
                "Top and side carry handles"
              ],
              keyPoints_fr: [
                "Tissu uni 100% polyester recyclé",
                "Bretelles réglables et profilées",
                "Deux poches frontales zippées, deux poches latérales zippées, une poche pour bouteille d'eau et une poche frontale plus profonde à insérer",
                "Poche extérieure doublée Sherpa pour ordinateur portable et poche intérieure pour ordinateur portable",
                "Poche zippée intérieure en filet et organisateur",
                "La sangle de se glisse sur un bagage à main",
                "Poignées supérieure et latérales"
              ]
            },
            {
              name: "HYBRID DUFFLE",
              name_fr: "HYBRID DUFFLE",
              articleCode: "Hybrid_Duffle",
              imageCount: 5,
              IdPrice: "$110",
              sizes: "",
              sizes_fr: "",
              description:
                "Transport your essentials to and from the course with ease. This adidas golf duffel bag features a roomy main compartment with a separate end pocket. Carry it with the dual handles or the adjustable shoulder straps. Made in part with recycled content generated from production waste, e.g. cutting scraps, and post-consumer household waste to avoid the larger environmental impact of producing virgin content. Contains a minimum of 25% recycled content.",
              description_fr:
                "Transportez vos essentiels sur le parcours en toute simplicité. Ce sac adidas golf est doté d'un compartiment principal spacieux et d'une poche latérale séparée. Portez-le avec les deux poignées ou les bretelles réglables. Fabriqué en partie avec un contenu recyclé provenant de déchets de production (déchets de coupe et/ou ménagers post-consommation) afin d'éviter l'impact environnemental de la production de contenu vierge. Contient un minimum de 25% de contenu recyclé.",
              keyPoints: [
                'Dimensions: 26.75" x 15.75" x 11.75"',
                "100% recycled polyester plain weave",
                "Vented shoe pocket with exterior zipper",
                "Zip main compartment with mesh end pocket",
                "Zip mesh pocket on inner lid",
                "Contoured, adjustable and removable shoulder straps",
                "Dual carry handles"
              ],
              keyPoints_fr: [
                'Dimensions : 26,75" x 15,75" x 11,75"',
                "100% polyester recyclé",
                "Poche à chaussures ventilée avec fermeture éclair extérieure",
                "Compartiment principal zippé avec poche en filet",
                "Poche en filet zippée sur le couvercle intérieur",
                "Bretelles profilées, réglables et amovibles",
                "Double poignée de transport"
              ]
            },
            {
              name: "SHOE BAG",
              name_fr: "SHOE BAG",
              articleCode: "Shoe_Bag",
              imageCount: 3,
              IdPrice: "$45",
              sizes: "",
              sizes_fr: "",
              description:
                "This adidas golf shoe bag makes it easy to carry your cleats to and from the course. A front zip pocket keeps small items secure. Grab it by the top handle as you head out the door. Made in part with recycled content generated from production waste, e.g. cutting scraps, and post-consumer household waste to avoid the larger environmental impact of producing virgin content. Contains a minimum of 25% recycled content.",
              description_fr:
                "Ce sac à chaussures de golf adidas permet de transporter facilement vos chaussures à crampons vers et depuis le parcours. Une poche frontale zippée permet de protéger les petits objets. Saisissez-le par la poignée supérieure lorsque vous vous dirigez vers la sortie.Fabriqué en partie avec un contenu recyclé provenant de déchets de production (déchets de coupe et/ou ménagers post-consommation) afin d'éviter l'impact environnemental de la production de contenu vierge. Contient un minimum de 25% de contenu recyclé.",
              keyPoints: [
                'Dimensions: 9/25" x 5" x 14"',
                "100% recycled polyester plain weave",
                "Front zip pocket",
                "Easy-grab carry handle on top"
              ],
              keyPoints_fr: [
                'Dimensions : 9/25" x 5" x 14"',
                "Tissu uni 100% polyester recyclé",
                "Poche frontale zippée",
                "Poignée de transport facile à saisir sur le dessus"
              ]
            },
            {
              name: "COOLER BAG",
              name_fr: "COOLER BAG",
              articleCode: "Cooler_Bag",
              imageCount: 2,
              IdPrice: "$50",
              sizes: "",
              sizes_fr: "",
              description:
                "Keep your drinks chilled during warm weather in this adidas golf cooler. The top-load design has a zip closure to ensure easy access to your beverages. An adjustable shoulder strap makes carrying it a breeze. Made in part with recycled content generated from production waste, e.g. cutting scraps, and post-consumer household waste to avoid the larger environmental impact of producing virgin content. Contains a minimum of 25% recycled content.",
              description_fr:
                "Gardez vos boissons au frais par temps chaud dans cette glacière. La conception à chargement par le haut est dotée d'une fermeture à glissière pour garantir un accès facile à vos boissons. Une bandoulière réglable permet de la transporter facilement. Fabriqué en partie avec un contenu recyclé provenant de déchets de production (déchets de coupe et/ou ménagers post-consommation) afin d'éviter l'impact environnemental de la production de contenu vierge. Contient un minimum de 25% de contenu recyclé.",
              keyPoints: [
                'Dimensions: 11.5" x 7.5" x 8"',
                "100% recycled polyester plain weave",
                "Top loader with zip closure",
                "Adjustable shoulder strap",
                "Cooling lining material"
              ],
              keyPoints_fr: [
                'Dimensions : 11,5" x 7,5" x 8"',
                "100% polyester recyclé à armure toile",
                "Chargeur supérieur avec fermeture à glissière",
                "Bandoulière réglable",
                "Matériau de doublure rafraîchissant"
              ]
            },
            {
              name: "VALUABLES POUCH",
              name_fr: "VALUABLES POUCH",
              articleCode: "Valuables_Pouch",
              imageCount: 2,
              IdPrice: "$38",
              sizes: "",
              sizes_fr: "",
              description:
                "Keep your valuables close at hand on the course in this adidas pouch. It features a carry handle, and the drawcord closure doubles as shoulder straps. Made in part with recycled content generated from production waste, e.g. cutting scraps, and post-consumer household waste to avoid the larger environmental impact of producing virgin content. Contains a minimum of 25% recycled content.",
              description_fr:
                "Gardez vos objets de valeur à portée de main sur le parcours dans cette pochette adidas. Elle est dotée d'une poignée de transport et le cordon de fermeture fait office de bretelles. Fabriqué en partie avec un contenu recyclé provenant de déchets de production (déchets de coupe et/ou ménagers post-consommation) afin d'éviter l'impact environnemental de la production de contenu vierge. Contient un minimum de 25% de contenu recyclé.",
              keyPoints: [
                'Dimensions: 8" x 11.5"',
                "100% recycled polyester plain weave",
                "Carry handle",
                "Drawcord closure can be used as shoulder straps",
                "Divider and zipper pocket"
              ],
              keyPoints_fr: [
                'Dimensions : 8" x 11.5"',
                "Tissu uni 100% polyester recyclé",
                "Poignée de transport",
                "Cordon de fermeture pouvant être utilisé comme bretelles",
                "Diviseur et poche à fermeture éclair"
              ]
            },
            {
              name: "PACKABLE BACKPACK",
              name_fr: "PACKABLE BACKPACK",
              articleCode: "Packable_Backpack",
              imageCount: 1,
              IdPrice: "$45",
              sizes: "",
              sizes_fr: "",
              description:
                "Bring this adidas golf backpack along when you travel. It holds all your course essentials in the main compartment, plus small items in the front zip pocket. It packs into a small pouch for easy transport. Made with a series of recycled materials, and at least 60% recycled content, this product represents just one of our solutions to help end plastic waste.",
              description_fr:
                "Emportez ce sac à dos de golf adidas lors de vos déplacements. Il peut contenir tous les éléments essentiels de votre parcours dans le compartiment principal, ainsi que de petits objets dans la poche frontale zippée. Il se range dans une petite pochette pour un transport facile. Fabriqué à partir d'une série de matériaux recyclés, et d'au moins 60 % de contenu recyclé, ce produit n'est qu'une de nos solutions pour aider à mettre fin aux déchets plastiques.",
              keyPoints: [
                'Dimensions: 19" x 11" x 8"',
                "Volume: 35 L 100% recycled polyester ripstop",
                "Zip closure",
                "Front zip pocket",
                "Hideaway adjustable shoulder strap",
                "Packable into small pouch",
                "Top carry handle"
              ],
              keyPoints_fr: [
                'Dimensions : 19" x 11" x 8"',
                "Volume : 35 L 100% polyester recyclé ripstop",
                "Fermeture à glissière",
                "Poche frontale zippée",
                "Bandoulière réglable ",
                "Peut être rangé dans une petite pochette",
                "Poignée de transport supérieure"
              ]
            }
          ]
        }
      ]
    },
    keyTakeaways: [
      {
        title: "HEADWEAR",
        title_fr: "HEADWEAR",
        imageURL: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Faccessories%2Fpage_assets%2FHeadwear_key_takeaways.jpg?alt=media`,
        keyPoints: [
          {
            bullet:
              "HEADWEAR WILL LEAD OUR ACCESSORIES CATEGORY WITH A FOCUS ON VERSATILE STYLES THAT PERFORM ON THE COURSE AND HAVE OFF-COURSE WEARABILITY FOR HIM AND HER",
            bullet_fr:
              "HEADWEAR WILL LEAD OUR ACCESSORIES CATEGORY WITH A FOCUS ON VERSATILE STYLES THAT PERFORM ON THE COURSE AND HAVE OFF-COURSE WEARABILITY FOR HIM AND HER"
          }
        ]
      },
      {
        title: "TRAVEL",
        title_fr: "VERSATILITY",
        imageURL: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Faccessories%2Fpage_assets%2FTravel_key_takeaways.jpg?alt=media`,
        keyPoints: [
          {
            bullet:
              "ALL NEW ELEVATED AND PREMIUM BAG OFFERING TO MEET ALL YOUR TRAVEL NEEDS, TO THE COURSE AND BEYOND",
            bullet_fr:
              "ALL NEW ELEVATED AND PREMIUM BAG OFFERING TO MEET ALL YOUR TRAVEL NEEDS, TO THE COURSE AND BEYOND"
          }
        ]
      }
    ]
  }
};

export default pageData;
