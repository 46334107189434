const sitePages = [
  {
    name: "Home",
    name_fr: "Accueil",
    link: "/home",
    access: "public"
  },
  {
    name: "Seasonal Priorities",
    name_fr: "LES PRIORITÉS SAISONNIÈRES",
    link: "/seasonal_priorities",
    access: "public"
  },
  {
    name: "Men's Sport Performance",
    name_fr: "PERFORMANCE SPORTIVE HOMMES",
    link: "/mens_sp",
    access: "public"
  },
  {
    name: "Women's Apparel",
    name_fr: "VÊTEMENTS POUR FEMMES",
    link: "/womens_app",
    access: "public"
  },
  {
    name: "Junior Apparel",
    name_fr: "VÊTEMENTS JUNIORS",
    link: "/junior_app",
    access: "public"
  },
  {
    name: "Footwear",
    name_fr: "Chaussures",
    link: "/footwear",
    access: "public"
  },
  {
    name: "Accessories",
    name_fr: "Accessoires",
    link: "/accessories",
    access: "public"
  },
  {
    name: "Toolkit",
    name_fr: "Boîte à outils",
    link: "/toolkit",
    access: "public"
  }
];

export default sitePages;
