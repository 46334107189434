import React, { useRef, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";

import { useDatabase } from "data/useDatabase";
import { useAuth } from "components/auth/useAuth";

import CategoryHeader from "components/shared/productComponents/CategoryHeader";
import BounceArrow from "components/shared/BounceArrow";
import NavFooter from "components/shared/NavFooter";
import ColourStories from "components/shared/productComponents/ColourStories";
import MustHaves from "components/shared/productComponents/MustHaves";
import SectionHeader from "components/shared/productComponents/SectionHeader";
import ProductCard from "components/shared/productComponents/ProductCard";
import KeyTakeaways from "components/shared/productComponents/KeyTakeaways";

const MensSportPerformance = () => {
  const { i18n } = useTranslation();
  const { userType } = useAuth();
  const { getPageData } = useDatabase();

  const [data, setData] = useState();

  useEffect(() => {
    getPageData("mens_sp").then((data) => setData(data));
  }, [getPageData]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const divRef = useRef();
  const scrollToBottom = () => {
    window.scrollTo({
      top: divRef.current.offsetTop,
      behavior: "smooth"
    });
  };

  return (
    <Wrapper>
      <CategoryHeader
        imageURL={data?.headerBackgroundImage}
        overlayImageURL={data?.headerOverlayImage}
        title={i18n.language === "en" ? data?.pageTitle : data?.pageTitle_fr}
      >
        <BounceArrow onClick={scrollToBottom} />
      </CategoryHeader>
      <div ref={divRef}>
        <ColourStories collections={data?.colorStories} />
      </div>
      <MustHaves
        leftImage={data?.mustHaves.leftImage}
        rightImage={data?.mustHaves.rightImage}
        title={
          i18n.language === "en"
            ? data?.mustHaves.title
            : data?.mustHaves.title_fr
        }
      />
      {data?.mustHaves.sections.map((section) => (
        <SectionWrapper key={section.title}>
          <SectionHeader
            title={i18n.language === "en" ? section.title : section.title_fr}
            imageURL={section.imageURL}
          />
          <div>
            {section.products.map((product, index) => (
              <ProductCard
                key={product.name}
                pageID='mens_sp'
                product={product}
                index={index}
              />
            ))}
          </div>
        </SectionWrapper>
      ))}
      {userType === "internal" && (
        <KeyTakeaways takeaways={data?.keyTakeaways} />
      )}
      <NavFooter />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
  padding-bottom: 100px;
`;

const SectionWrapper = styled.div``;

export default MensSportPerformance;
