import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";

import { useParallax } from "react-scroll-parallax";
import BounceArrow from "components/shared/BounceArrow";

const Header = ({ onScrollClick }) => {
  const [golferLoaded, setGolferLoaded] = useState(false);

  const { ref } = useParallax({ translateY: [-50, 50] });
  return (
    <Wrapper
      backgroundURL={`https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fhome%2Fpage_assets%2Fhome-header-background.jpg?alt=media`}
    >
      <GolferOverlay
        ref={ref}
        src={`https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fhome%2Fpage_assets%2Fhome_header_golfer_overlay.png?alt=media`}
        alt=''
        onLoad={() => setGolferLoaded(true)}
        isLoaded={golferLoaded}
      />
      <InputOverlay>
        <HeaderContent>
          <Logo>
            <svg
              version='1.1'
              id='Layer_1'
              xmlns='http://www.w3.org/2000/svg'
              x='0px'
              y='0px'
              viewBox='0 0 74.4 44'
              enableBackground='new 0 0 74.4 44'
            >
              <path
                fill='#FFFFFF'
                d='M0,39l2.9,5h19.6l-7.8-13.5L0,39z M33,44h15.5L31.9,15.5l-14.7,8.4L28.8,44H33z M54.7,44h19.7L48.9,0L34.2,8.5L54.7,44z'
              />
            </svg>
            <TextWrapper>
              <TextLeft>SS23</TextLeft>
              <TextRight>SHO</TextRight>
            </TextWrapper>
          </Logo>
        </HeaderContent>
      </InputOverlay>
      <BounceArrow onClick={onScrollClick} />
    </Wrapper>
  );
};

let vh = window.innerHeight * 0.01;
// Then we set the value in the --vh custom property to the root of the document
document.documentElement.style.setProperty("--vh", `${vh}px`);

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  //Heights here include fixes for mobile
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  /* transition: all 1.5s; */
  /* //End of Height fixes for mobile */
  background-image: url(${({ backgroundURL }) => backgroundURL});
  background-position: center center;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.75);
  clip-path: inset(0px 0px -50px 0px);
`;

const GolferOverlay = styled.img`
  position: absolute;
  bottom: -28vh;
  left: 10vw;
  /* width: 400px; */
  height: 95vh;
  z-index: 5;
  opacity: ${({ isLoaded }) => (isLoaded ? 1.0 : 0)};
  transition: opacity 1s 0.5s;

  @media screen and (max-width: 1100px) {
    /* max-width: 300px; */
  }

  @media screen and (max-width: 700px) {
    left: -15vw;
  }

  @media screen and (max-width: 500px) {
    top: 50vh;
    left: -10vw;
    /* width: 280px; */
  }
`;

const InputOverlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;

  @media screen and (max-width: 1000px) {
  }
`;

const HeaderContent = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 50px;

  @media screen and (max-width: 1200px) {
    padding: 10px;
  }
`;

const Logo = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  /* height: 350px; */
  /* text-align: center; */
  padding-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  svg {
    /* max-width: 300px; */
    height: 22vh;
    /* filter: drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4)); */
    opacity: 1;
  }

  @media screen and (max-width: 1200px) {
    left: unset;
    right: 10vw;
    svg {
      height: 18vh;
    }
  }
`;

const TextWrapper = styled.div`
  display: flex;
`;
const TextLeft = styled.div`
  padding-right: 1vw;
  font-family: "adiNeue PRO Condensed Black";
  font-size: 12vh;
  color: white;
  opacity: 1;
  /* text-shadow: 3px 5px 2px rgb(0 0 0 / 0.4); */
  /* mix-blend-mode: screen; */

  @media screen and (max-width: 1200px) {
    font-size: 10vh;
  }
`;
const TextRight = styled.div`
  padding-left: 1vw;
  font-family: "adiNeue PRO Condensed Black";
  font-size: 12vh;
  color: black;
  -webkit-text-stroke: 1px white;
  opacity: 1;
  /* text-shadow: 0px 0px 10px rgb(255 255 255 / 1); */
  /* mix-blend-mode: screen; */

  @media screen and (max-width: 1200px) {
    font-size: 10vh;
  }
`;

export default Header;
