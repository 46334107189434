import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";
import CustomImageGallery from "components/shared/imageGallery/CustomImageGallery";
import useIntersection from "helpers/useIntersection";
import { useSpring, animated } from "react-spring";
import AmbilightVideo from "components/shared/AmbilightVideo";

const ProductCard = ({
  pageID,
  productID,
  imageCount,
  videoURL,
  thumbnailURL,
  subtitles,
  index = 0
}) => {
  const { i18n } = useTranslation();
  const ref = useRef();
  const inViewport = useIntersection(ref, "0px", 0.1);

  const detailsAnimation = useSpring({
    from: { translateX: index % 2 === 0 ? "-50vw" : "50vw", opacity: 0 },
    to: { translateX: "0", opacity: 1.0 },
    pause: !inViewport,
    delay: 0
  });

  const imageGalleryAnimation = useSpring({
    from: { translateX: index % 2 === 0 ? "50vw" : "-50vw", opacity: 0 },
    to: { translateX: "0", opacity: 1.0 },
    pause: !inViewport,
    delay: 200
  });

  return (
    <Wrapper ref={ref}>
      <AnimatedVideo style={detailsAnimation} index={index}>
        <AmbilightVideo
          videoSrc={videoURL}
          thumbnail={thumbnailURL}
          subtitles={subtitles}
          showLighting={false}
        />
      </AnimatedVideo>
      <VideoNote>
        {i18n.language === "en"
          ? "*REFER TO TOOLKIT FOR CANADIAN COLOURWAYS AND PRICING"
          : "*SE RÉFÉRER À LA BOÎTE À OUTILS POUR LES COULEURS ET LES PRIX CANADIENS"}
      </VideoNote>
      <AnimatedImageGalleryWrapper style={imageGalleryAnimation} index={index}>
        <CustomImageGallery
          items={[...Array(imageCount)].map((item, index) => ({
            fullscreen: `https://firebasestorage.googleapis.com/v0/b/${
              process.env.REACT_APP_FIREBASE_STORAGE_ROOT
            }.appspot.com/o/sho%2F${pageID}%2Farticle_assets%2F${productID}%2F${productID}_${
              index + 1
            }_fullscreen.jpg?alt=media`,
            original: `https://firebasestorage.googleapis.com/v0/b/${
              process.env.REACT_APP_FIREBASE_STORAGE_ROOT
            }.appspot.com/o/sho%2F${pageID}%2Farticle_assets%2F${productID}%2F${productID}_${
              index + 1
            }_original.jpg?alt=media`,
            thumbnail: `https://firebasestorage.googleapis.com/v0/b/${
              process.env.REACT_APP_FIREBASE_STORAGE_ROOT
            }.appspot.com/o/sho%2F${pageID}%2Farticle_assets%2F${productID}%2F${productID}_${
              index + 1
            }_thumbnail.jpg?alt=media`
          }))}
        />
      </AnimatedImageGalleryWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  /* margin: 30px; */
  margin: 30px 10vw;
  padding: 50px 0 70px;
  display: flex;
  flex-direction: column;
  align-items: center;

  :not(:last-child) {
    border-bottom: 1px solid black;
  }

  @media screen and (max-width: 900px) {
    flex-direction: column;
    align-items: center;
    margin: 0px 15px;
  }
`;

const Video = styled.div`
  width: 85%;

  @media screen and (max-width: 900px) {
    width: 100%;
    order: 0;
  }
`;
const AnimatedVideo = animated(Video);

const VideoNote = styled.div`
  width: 85%;
  padding: 10px 20px 40px;
`;

const StyledVideoPlayer = styled.video`
  max-height: 100%;
  max-width: 100%;
  aspect-ratio: 16 / 9;
  background-color: black;
  z-index: 3;
  border: unset;
  /* margin-bottom: 15vh; */
`;

const ImageGalleryWrapper = styled.div`
  order: ${({ index }) => (index % 2 === 0 ? 1 : 0)};
  width: 85%;
  margin: 20px 30px;

  @media screen and (max-width: 900px) {
    width: 100%;
    margin: 20px 0;
    order: 1;
  }
`;
const AnimatedImageGalleryWrapper = animated(ImageGalleryWrapper);

export default ProductCard;
