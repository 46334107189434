import React, { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";
import useWindowSize from "helpers/useWindowSize";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useDatabase } from "data/useDatabase";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import ImageMask from "../ImageMask";

const ColourStories = ({ collections, showThumbnails = true }) => {
  const { i18n } = useTranslation();
  const [gradient, setGradient] = useState();
  const [selectedCollectionIndex, setSelectedCollectionIndex] = useState(0);

  const windowSize = useWindowSize();
  const carouselRef = useRef();

  useEffect(() => {
    if (collections == null) return;
    let backgroundGradient = "linear-gradient(to bottom, ";
    let parts = [];
    collections[selectedCollectionIndex].colors.forEach((color) => {
      parts.push(`rgb(${color.color.join(", ")})`);
    });
    backgroundGradient += parts.join(", ");
    backgroundGradient += ")";
    setGradient(backgroundGradient);
  }, [selectedCollectionIndex, collections]);

  const onClickItem = (index) => {
    if (
      index - selectedCollectionIndex === 1 ||
      (index === 0 && selectedCollectionIndex === collections.length - 1)
    ) {
      carouselRef.current.slickNext();
    } else if (
      selectedCollectionIndex - index === 1 ||
      (index === collections.length - 1 && selectedCollectionIndex === 0)
    ) {
      carouselRef.current.slickPrev();
    }
    setSelectedCollectionIndex(index);
  };

  const getCenterPadding = () => {
    if (windowSize?.width > 700) {
      if (windowSize?.width / windowSize?.height > 1.5) {
        return `${windowSize?.width * 0.15}px`;
      } else {
        return `${windowSize?.width * 0.1}px`;
      }
    } else {
      return `${windowSize?.width * 0.04}px`;
    }
  };

  return (
    <Wrapper
      background={
        collections?.[selectedCollectionIndex].colors.length > 0
          ? gradient
          : null
      }
    >
      {/* <ImageMask maskOpacity={0.6} /> */}
      <StyledSlider
        infinite
        centerMode
        centerPadding={getCenterPadding()}
        ref={carouselRef}
        afterChange={(index) => setSelectedCollectionIndex(index)}
      >
        {collections?.map((collection, index) => (
          <div key={collection}>
            <CollectionTitleWrapper>
              <CollectionTitle windowWidth={windowSize.width}>
                {i18n.language === "en" ? collection.name : collection.name_fr}
              </CollectionTitle>
              <CollectionDeliveryDate>
                {collection.delivery}
              </CollectionDeliveryDate>
            </CollectionTitleWrapper>
            <Slide onClick={() => onClickItem(index)}>
              <SlideInner>
                <SlideContent>
                  {collections[index].colors.length > 0 && (
                    <Colors hero={index === selectedCollectionIndex}>
                      {collections[index].colors.map((color) => (
                        <ColorBlock
                          key={color.name}
                          color={color.color}
                          type={color.type}
                        >
                          <ColorBlockText>
                            {i18n.language === "en"
                              ? color.name
                              : color.name_fr}
                          </ColorBlockText>
                        </ColorBlock>
                      ))}
                    </Colors>
                  )}
                  <GalleryWrapper>
                    <ImageGallery
                      showNav={false}
                      showFullscreenButton={false}
                      showPlayButton={false}
                      showBullets={false}
                      lazyLoad={true}
                      disableSwipe
                      disableThumbnailSwipe
                      showThumbnails={showThumbnails}
                      disableKeyDown
                      disableThumbnailScroll
                      stopPropagation
                      items={collection.images.map((imageURL, index) => ({
                        original: imageURL,
                        thumbnail: imageURL,
                        isPrimary: index === 0
                      }))}
                    />
                  </GalleryWrapper>
                </SlideContent>
              </SlideInner>
            </Slide>
          </div>
        ))}
      </StyledSlider>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  background: ${({ background }) => background || "black"};
  color: ${({ color }) => color};
  padding: 20px 0 50px 0;
  overflow: hidden;

  .slick-slider {
    overflow: hidden;
  }

  .slick-track {
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
  }
`;

const CollectionTitleWrapper = styled.div`
  padding 0 2.5%;
  display: flex;
  /* justify-content: space-between; */
  align-items: flex-end;
`;

const CollectionTitle = styled.div`
  padding: 10px 0;
  font-size: 6vw;
  color: white;
  text-shadow: 0px 0px 8px rgb(0 0 0 / 0.3);
  text-transform: uppercase;
`;

const CollectionDeliveryDate = styled.div`
  font-size: 3.5vw;
  line-height: 5vw;
  color: white;
  padding: 10px 3vw;
  text-shadow: 0px 0px 8px rgb(0 0 0 / 0.3);
  text-transform: uppercase;
`;

const StyledSlider = styled(Slider)`
  -webkit-overflow-scrolling: touch;
`;

const Slide = styled.div``;

const SlideInner = styled.div`
  /* margin: 10px; */
  display: flex;
  justify-content: center;

  @media screen and (max-width: 700px) {
    margin: unset;
  }
`;

const SlideContent = styled.div`
  position: relative;
  width: 95%;
  background: white;
  box-shadow: 0px 0px 10px rgb(0 0 0 / 0.3);
  cursor: pointer;
`;

const Colors = styled.div`
  width: 100%;
  height: 4vw;
  display: flex;
  border: 1px solid white;
  box-shadow: 0px 2px 10px rgb(0 0 0 / 0.3);
  margin-bottom: 12px;
  text-transform: uppercase;
  /* opacity: ${({ hero }) => (hero ? 1 : 0)}; */

  div:not(:last-child) {
    border-right: 1px solid white;
  }

  @media screen and (max-width: 700px) {
    height: 8vw;
  }
`;

const ColorBlock = styled.div`
  flex-grow: ${({ type }) => (type === "primary" ? 3 : 1)};
  background: ${({ color }) => `rgb(${color.join()})`};
`;

const ColorBlockText = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: white;
  font-size: 1.2vw;
  font-family: "adineuePRORegularWeb";
  text-shadow: 0px 0px 3px rgb(0 0 0 / 0.6);
  opacity: 0;
  transition: all 0.5s;

  @media screen and (max-width: 500px) {
    font-size: 2vw;
  }

  :hover {
    opacity: 1;
  }
`;

const GalleryWrapper = styled.div`
  && .image-gallery-slide-wrapper {
    pointer-events: none;
  }
  && .image-gallery-thumbnail {
    border: 2px solid transparent;
    border-radius: 5px;

    .image-gallery-thumbnail-inner {
      padding: 2px;
    }
  }
  && .image-gallery-thumbnail.active {
    border: 2px solid black;
  }
`;

export default ColourStories;
