import React from "react";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";

const LeftNav = ({ onClick, disabled, className }) => {
  return (
    <button
      type='button'
      className={`image-gallery-icon image-gallery-left-nav ${className}`}
      disabled={disabled}
      onClick={onClick}
      aria-label='Previous Slide'
    >
      <ArrowLeftIcon sx={{ fontSize: "50px" }} />
    </button>
  );
};

export default LeftNav;
