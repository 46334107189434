import React from "react";
import styled from "styled-components/macro";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import { useSpring, animated } from "react-spring";

const BounceArrow = ({ onClick, className }) => {
  const bounceArrow = useSpring({
    to: { opacity: 1, translateY: 0 },
    from: { opacity: 0, translateY: -70 },
    loop: true,
    config: { mass: 1, tension: 180, friction: 8 },
    delay: 1200,
    duration: 3000
  });

  return (
    <AnimatedDownArrowWrapper style={bounceArrow} className={className}>
      <div onClick={onClick}>
        <KeyboardArrowDownRoundedIcon sx={{ fontSize: "10vh" }} />
      </div>
    </AnimatedDownArrowWrapper>
  );
};

const DownArrowWrapper = styled.div`
  position: absolute;
  bottom: 0;
  color: white;
  display: flex;
  justify-content: center;
  cursor: pointer;
  svg {
    filter: drop-shadow(3px 2px 2px rgb(0 0 0 / 0.4));
  }
  z-index: 100;
`;

const AnimatedDownArrowWrapper = animated(DownArrowWrapper);

export default BounceArrow;
