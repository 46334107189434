import React, { useRef, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";
import NavFooter from "components/shared/NavFooter";
import { useDatabase } from "data/useDatabase";

import CategoryHeader from "components/shared/productComponents/CategoryHeader";
import BounceArrow from "components/shared/BounceArrow";

import ZG23 from "../productPages/footwear/ZG23";
import { useParallax } from "react-scroll-parallax";
import { ParallaxBanner } from "react-scroll-parallax";
import CustomImageGallery from "components/shared/imageGallery/CustomImageGallery";
import AmbilightVideo from "components/shared/AmbilightVideo";

const SeasonalPriorities = () => {
  const { i18n } = useTranslation();
  const { getPageData } = useDatabase();

  const [data, setData] = useState();

  const adiLogoParallax = useParallax({ scale: [0.1, 3] });
  const energyLogoParallax = useParallax({ scale: [0.1, 2] });

  useEffect(() => {
    getPageData("seasonal_priorities").then((data) => setData(data));
  }, [getPageData]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const divRef = useRef();
  const scrollToBottom = () => {
    window.scrollTo({
      top: divRef.current.offsetTop,
      behavior: "smooth"
    });
  };

  return (
    <Wrapper>
      <CategoryHeader
        imageURL={data?.headerBackgroundImage}
        overlayImageURL={data?.headerOverlayImage}
        title={i18n.language === "en" ? data?.pageTitle : data?.pageTitle_fr}
      >
        <BounceArrow onClick={scrollToBottom} />
      </CategoryHeader>
      <CategorySelectorWrapper ref={divRef}>
        <Category>
          <img
            src={`https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fseasonal_priorities%2Fpage_assets%2FZG23_background.jpg?alt=media`}
            alt=''
          />
          <CategorySelectorWrapperOverlay>
            <img
              src={`https://firebasestorage.googleapis.com/v0/b/${
                process.env.REACT_APP_FIREBASE_STORAGE_ROOT
              }.appspot.com/o/sho%2Ffootwear%2Farticle_assets%2Fzg23%2FZG_logo${
                i18n.language === "en" ? "" : "_fr"
              }.png?alt=media`}
              alt=''
            />
          </CategorySelectorWrapperOverlay>
        </Category>
        <Category>
          <img
            src={`https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fseasonal_priorities%2Fpage_assets%2Fultimate365_tour_background.jpg?alt=media`}
            alt=''
          />
          <CategorySelectorWrapperOverlay>
            <img
              src={`https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fseasonal_priorities%2Fpage_assets%2Fultimate365_tour_text.png?alt=media`}
              alt=''
            />
          </CategorySelectorWrapperOverlay>
        </Category>
        <Category>
          <img
            src={`https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fseasonal_priorities%2Fpage_assets%2Fenergy_background.jpg?alt=media`}
            alt=''
          />
          <CategorySelectorWrapperOverlay>
            {i18n.language === "en" ? "ENERGY" : "ENERGIE"}
          </CategorySelectorWrapperOverlay>
        </Category>
      </CategorySelectorWrapper>
      <ZG23 pageType='overview' />
      <Ult365Section>
        <Ult365SectionHeader
          backgroundURL={`https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fseasonal_priorities%2Fpage_assets%2Fultimate365_tour_background.jpg?alt=media`}
        >
          <Ult365LogoWrapper ref={adiLogoParallax.ref}>
            <Ult365HeaderLogo
              xmlns='http://www.w3.org/2000/svg'
              viewBox='0 0 746.3 468.3'
            >
              <path
                fill='#FFFFFF'
                d='M37.6 468.3h194.6l-87.1-149.2L0 402.4zM294.8 468.2l193.8.1-178.2-309.1-145.3 83.9zM551.9 468.1l194.4.1L475.8 0 330.4 84.2z'
              />
            </Ult365HeaderLogo>
          </Ult365LogoWrapper>
          <Ult365OverlayLogo>
            <img
              src={`https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fseasonal_priorities%2Fpage_assets%2Fultimate365_tour_text.png?alt=media`}
              alt=''
            />
          </Ult365OverlayLogo>
        </Ult365SectionHeader>
        <Ult365HeaderText>
          {i18n.language === "en"
            ? "CLAIM YOUR COMPETITIVE EDGE"
            : "REVENDIQUEZ VOTRE AVANTAGE CONCURRENTIEL"}
        </Ult365HeaderText>
        <Ult365Section1>
          <Ult365Section1Text
            backgroundURL={`https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fseasonal_priorities%2Fpage_assets%2FMensFabric-R2.jpg?alt=media`}
          >
            <p>
              {i18n.language === "en"
                ? "STEP INTO TOURNAMENT DAY FOCUSED, CONFIDENT AND READY TO PLAY AT YOUR PEAK."
                : "ENTREZ DANS LE TOURNOI EN ÉTANT CONCENTRÉ, CONFIANT ET PRÊT À JOUER À VOTRE MEILLEUR NIVEAU."}
            </p>
            <p>
              <span>ULTIMATE365 </span>
              <span>TOUR</span>{" "}
              {i18n.language === "en"
                ? "HAS THE INNOVATIVE MATERIALS AND REFINED STYLE TO UNLEASH YOUR BEST."
                : "PROPOSE DES MATÉRIAUX INNOVANTS ET UN STYLE RAFFINÉ POUR VOUS PERMETTRE DE DONNER LE MEILLEUR DE VOUS-MÊME."}
            </p>
          </Ult365Section1Text>
          <Ult365Section1Image
            backgroundURL={`https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fseasonal_priorities%2Fpage_assets%2F48_20220119_Adidas_Berger_03589-F.jpg?alt=media`}
          />
        </Ult365Section1>
        <Ult365Section2>
          <Ult365Section2VideoWrapper>
            <AmbilightVideo
              videoSrc='https://stream.mux.com/wcnG9fqUZ4ZLDzo009BWx01EF2mZEqC01PJdiGI7crdP3s.m3u8'
              thumbnail='https://image.mux.com/wcnG9fqUZ4ZLDzo009BWx01EF2mZEqC01PJdiGI7crdP3s/thumbnail.jpg?time=24'
              subtitles={[
                {
                  kind: "subtitles",
                  label: "English",
                  src: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fcaptions%2FSS23-Ultimate365_TOUR_en.vtt?alt=media`,
                  srcLang: "en"
                },
                {
                  kind: "subtitles",
                  label: "French",
                  src: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fcaptions%2FSS23-Ultimate365_TOUR_fr.vtt?alt=media`,
                  srcLang: "fr",
                  default: i18n.language === "fr-CA"
                }
              ]}
            />
          </Ult365Section2VideoWrapper>
        </Ult365Section2>
        <Delivery1Header>
          <ImageGalleryWrapper>
            <CustomImageGallery
              items={[
                {
                  fullscreen: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fseasonal_priorities%2Fdelivery1%2FgridImages%2FHero_fullscreen.jpg?alt=media`,
                  original: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fseasonal_priorities%2Fdelivery1%2FgridImages%2FHero_original.jpg?alt=media`,
                  thumbnail: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fseasonal_priorities%2Fdelivery1%2FgridImages%2FHero_thumbnail.jpg?alt=media`
                },
                {
                  fullscreen: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fseasonal_priorities%2Fdelivery1%2FgridImages%2F1_fullscreen.jpg?alt=media`,
                  original: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fseasonal_priorities%2Fdelivery1%2FgridImages%2F1_original.jpg?alt=media`,
                  thumbnail: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fseasonal_priorities%2Fdelivery1%2FgridImages%2F1_thumbnail.jpg?alt=media`
                },
                {
                  fullscreen: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fseasonal_priorities%2Fdelivery1%2FgridImages%2F2_fullscreen.jpg?alt=media`,
                  original: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fseasonal_priorities%2Fdelivery1%2FgridImages%2F2_original.jpg?alt=media`,
                  thumbnail: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fseasonal_priorities%2Fdelivery1%2FgridImages%2F2_thumbnail.jpg?alt=media`
                },
                {
                  fullscreen: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fseasonal_priorities%2Fdelivery1%2FgridImages%2F3_fullscreen.jpg?alt=media`,
                  original: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fseasonal_priorities%2Fdelivery1%2FgridImages%2F3_original.jpg?alt=media`,
                  thumbnail: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fseasonal_priorities%2Fdelivery1%2FgridImages%2F3_thumbnail.jpg?alt=media`
                },
                {
                  fullscreen: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fseasonal_priorities%2Fdelivery1%2FgridImages%2F4_fullscreen.jpg?alt=media`,
                  original: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fseasonal_priorities%2Fdelivery1%2FgridImages%2F4_original.jpg?alt=media`,
                  thumbnail: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fseasonal_priorities%2Fdelivery1%2FgridImages%2F4_thumbnail.jpg?alt=media`
                },
                {
                  fullscreen: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fseasonal_priorities%2Fdelivery1%2FgridImages%2F5_fullscreen.jpg?alt=media`,
                  original: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fseasonal_priorities%2Fdelivery1%2FgridImages%2F5_original.jpg?alt=media`,
                  thumbnail: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fseasonal_priorities%2Fdelivery1%2FgridImages%2F5_thumbnail.jpg?alt=media`
                },
                {
                  fullscreen: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fseasonal_priorities%2Fdelivery1%2FgridImages%2F6_fullscreen.jpg?alt=media`,
                  original: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fseasonal_priorities%2Fdelivery1%2FgridImages%2F6_original.jpg?alt=media`,
                  thumbnail: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fseasonal_priorities%2Fdelivery1%2FgridImages%2F6_thumbnail.jpg?alt=media`
                },
                {
                  fullscreen: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fseasonal_priorities%2Fdelivery1%2FgridImages%2F7_fullscreen.jpg?alt=media`,
                  original: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fseasonal_priorities%2Fdelivery1%2FgridImages%2F7_original.jpg?alt=media`,
                  thumbnail: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fseasonal_priorities%2Fdelivery1%2FgridImages%2F7_thumbnail.jpg?alt=media`
                },
                {
                  fullscreen: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fseasonal_priorities%2Fdelivery1%2FgridImages%2F8_fullscreen.jpg?alt=media`,
                  original: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fseasonal_priorities%2Fdelivery1%2FgridImages%2F8_original.jpg?alt=media`,
                  thumbnail: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fseasonal_priorities%2Fdelivery1%2FgridImages%2F8_thumbnail.jpg?alt=media`
                },
                {
                  fullscreen: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fseasonal_priorities%2Fdelivery1%2FgridImages%2F9_fullscreen.jpg?alt=media`,
                  original: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fseasonal_priorities%2Fdelivery1%2FgridImages%2F9_original.jpg?alt=media`,
                  thumbnail: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fseasonal_priorities%2Fdelivery1%2FgridImages%2F9_thumbnail.jpg?alt=media`
                },
                {
                  fullscreen: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fseasonal_priorities%2Fdelivery1%2FgridImages%2F10_fullscreen.jpg?alt=media`,
                  original: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fseasonal_priorities%2Fdelivery1%2FgridImages%2F10_original.jpg?alt=media`,
                  thumbnail: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fseasonal_priorities%2Fdelivery1%2FgridImages%2F10_thumbnail.jpg?alt=media`
                },
                {
                  fullscreen: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fseasonal_priorities%2Fdelivery1%2FgridImages%2F11_fullscreen.jpg?alt=media`,
                  original: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fseasonal_priorities%2Fdelivery1%2FgridImages%2F11_original.jpg?alt=media`,
                  thumbnail: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fseasonal_priorities%2Fdelivery1%2FgridImages%2F11_thumbnail.jpg?alt=media`
                },
                {
                  fullscreen: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fseasonal_priorities%2Fdelivery1%2FgridImages%2F12_fullscreen.jpg?alt=media`,
                  original: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fseasonal_priorities%2Fdelivery1%2FgridImages%2F12_original.jpg?alt=media`,
                  thumbnail: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fseasonal_priorities%2Fdelivery1%2FgridImages%2F12_thumbnail.jpg?alt=media`
                }
              ]}
            />
          </ImageGalleryWrapper>
          {/* <Delivery1HeaderTextWrapper>
            <p>DELIVERY 1</p>
            <p>1.1.23</p>
          </Delivery1HeaderTextWrapper> */}
        </Delivery1Header>
      </Ult365Section>
      <EnergySectionWrapper>
        <EnergySectionHeader>
          <EnergySectionHeaderTextWrapper>
            <EnergySectionHeaderText
              ref={energyLogoParallax.ref}
              backgroundURL={`https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fseasonal_priorities%2Fpage_assets%2Fenergy_background.jpg?alt=media`}
            >
              {i18n.language === "en" ? "ENERGY" : "ENERGIE"}
            </EnergySectionHeaderText>
          </EnergySectionHeaderTextWrapper>
        </EnergySectionHeader>
      </EnergySectionWrapper>
      <EnergyWomensSectionHeader
        backgroundURL={`https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fseasonal_priorities%2Fpage_assets%2Ffloaral_pattern.jpg?alt=media`}
      >
        <EnergyWomensSectionHeaderBottomOverlay />
        <EnergyWomensSectionHeaderText>
          {i18n.language === "en" ? "WOMENS" : "FEMMES"}
        </EnergyWomensSectionHeaderText>
      </EnergyWomensSectionHeader>
      <EnergyWomensSayings>
        <SayingWrapper>
          {i18n.language === "en"
            ? "SELF-EXPRESSION"
            : "EXPRESSION PERSONNELLE"}
        </SayingWrapper>
        <SayingWrapper>
          {i18n.language === "en" ? "BELONGING" : "APPARTENANCE"}
        </SayingWrapper>
        <SayingWrapper>
          {i18n.language === "en" ? "INDIVIDUALITY" : "INDIVIDUALITÉ"}
        </SayingWrapper>
        <SayingWrapper>
          {i18n.language === "en" ? "COMMUNITY" : "COMMUNAUTÉ"}
        </SayingWrapper>
      </EnergyWomensSayings>
      <EnergyWomenProductWrapper>
        <EnergyWomenImageGalleryWrapper>
          <CustomImageGallery
            items={[
              {
                fullscreen: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fseasonal_priorities%2Fenergy_womens%2F1_fullscreen.jpg?alt=media`,
                original: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fseasonal_priorities%2Fenergy_womens%2F1_original.jpg?alt=media`,
                thumbnail: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fseasonal_priorities%2Fenergy_womens%2F1_thumbnail.jpg?alt=media`
              },
              {
                fullscreen: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fseasonal_priorities%2Fenergy_womens%2F2_fullscreen.jpg?alt=media`,
                original: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fseasonal_priorities%2Fenergy_womens%2F2_original.jpg?alt=media`,
                thumbnail: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fseasonal_priorities%2Fenergy_womens%2F2_thumbnail.jpg?alt=media`
              },
              {
                fullscreen: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fseasonal_priorities%2Fenergy_womens%2F3_fullscreen.jpg?alt=media`,
                original: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fseasonal_priorities%2Fenergy_womens%2F3_original.jpg?alt=media`,
                thumbnail: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fseasonal_priorities%2Fenergy_womens%2F3_thumbnail.jpg?alt=media`
              },
              {
                fullscreen: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fseasonal_priorities%2Fenergy_womens%2F4_fullscreen.jpg?alt=media`,
                original: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fseasonal_priorities%2Fenergy_womens%2F4_original.jpg?alt=media`,
                thumbnail: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fseasonal_priorities%2Fenergy_womens%2F4_thumbnail.jpg?alt=media`
              },
              {
                fullscreen: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fseasonal_priorities%2Fenergy_womens%2F5_fullscreen.jpg?alt=media`,
                original: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fseasonal_priorities%2Fenergy_womens%2F5_original.jpg?alt=media`,
                thumbnail: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fseasonal_priorities%2Fenergy_womens%2F5_thumbnail.jpg?alt=media`
              },
              {
                fullscreen: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fseasonal_priorities%2Fenergy_womens%2F6_fullscreen.jpg?alt=media`,
                original: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fseasonal_priorities%2Fenergy_womens%2F6_original.jpg?alt=media`,
                thumbnail: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fseasonal_priorities%2Fenergy_womens%2F6_thumbnail.jpg?alt=media`
              },
              {
                fullscreen: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fseasonal_priorities%2Fenergy_womens%2F7_fullscreen.jpg?alt=media`,
                original: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fseasonal_priorities%2Fenergy_womens%2F7_original.jpg?alt=media`,
                thumbnail: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fseasonal_priorities%2Fenergy_womens%2F7_thumbnail.jpg?alt=media`
              },
              {
                fullscreen: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fseasonal_priorities%2Fenergy_womens%2F8_fullscreen.jpg?alt=media`,
                original: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fseasonal_priorities%2Fenergy_womens%2F8_original.jpg?alt=media`,
                thumbnail: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fseasonal_priorities%2Fenergy_womens%2F8_thumbnail.jpg?alt=media`
              },
              {
                fullscreen: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fseasonal_priorities%2Fenergy_womens%2F9_fullscreen.jpg?alt=media`,
                original: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fseasonal_priorities%2Fenergy_womens%2F9_original.jpg?alt=media`,
                thumbnail: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fseasonal_priorities%2Fenergy_womens%2F9_thumbnail.jpg?alt=media`
              },
              {
                fullscreen: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fseasonal_priorities%2Fenergy_womens%2F10_fullscreen.jpg?alt=media`,
                original: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fseasonal_priorities%2Fenergy_womens%2F10_original.jpg?alt=media`,
                thumbnail: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fseasonal_priorities%2Fenergy_womens%2F10_thumbnail.jpg?alt=media`
              },
              {
                fullscreen: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fseasonal_priorities%2Fenergy_womens%2F1_fullscreen.jpg?alt=media`,
                original: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fseasonal_priorities%2Fenergy_womens%2F11_original.jpg?alt=media`,
                thumbnail: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fseasonal_priorities%2Fenergy_womens%2F11_thumbnail.jpg?alt=media`
              }
            ]}
          />
        </EnergyWomenImageGalleryWrapper>
        <EnergyWomenProductTextWrapper>
          <p>
            {i18n.language === "en" ? "PAR: 4.1.23" : "INTRO: 1ER AVRIL 23"}
          </p>
          <p>
            {i18n.language === "en"
              ? "SCRIPTED @ CHEVRON"
              : "SCRIPTÉ @ CHEVRON"}
          </p>
          <p>
            {i18n.language === "en"
              ? "SCRIPTED @ MASTERS"
              : "SCRIPTÉ @ MASTERS"}
          </p>
          <p>
            {i18n.language === "en"
              ? "CELEBRATE AND CONNECT WOMEN IN GOLF USING A VIBRANT PRINT"
              : "CÉLÉBREZ ET CONNECTEZ LES FEMMES DANS LE MONDE DU GOLF EN UTILISANT UNE IMPRESSION VIBRANTE."}
          </p>
        </EnergyWomenProductTextWrapper>
      </EnergyWomenProductWrapper>
      <EnergyWomensLaydown>
        <img
          src={`https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fseasonal_priorities%2Fenergy_womens%2Flaydown.jpg?alt=media`}
          alt=''
        />
      </EnergyWomensLaydown>
      <GoToHeader>
        <GoToParallaxBanner
          layers={[
            {
              image: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fseasonal_priorities%2Fgo_to%2Fgo_to_header_background_2.png?alt=media`,
              speed: 3
            }
          ]}
        />
        <GoToHeaderText>GO-TO</GoToHeaderText>
      </GoToHeader>
      <GoToProductWrapper>
        <GoToImageGalleryWrapper>
          <CustomImageGallery
            items={[
              {
                fullscreen: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fseasonal_priorities%2Fgo_to%2FsliderImages%2F1_fullscreen.jpg?alt=media`,
                original: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fseasonal_priorities%2Fgo_to%2FsliderImages%2F1_original.jpg?alt=media`,
                thumbnail: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fseasonal_priorities%2Fgo_to%2FsliderImages%2F1_thumbnail.jpg?alt=media`
              },
              {
                fullscreen: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fseasonal_priorities%2Fgo_to%2FsliderImages%2F2_fullscreen.jpg?alt=media`,
                original: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fseasonal_priorities%2Fgo_to%2FsliderImages%2F2_original.jpg?alt=media`,
                thumbnail: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fseasonal_priorities%2Fgo_to%2FsliderImages%2F2_thumbnail.jpg?alt=media`
              },
              {
                fullscreen: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fseasonal_priorities%2Fgo_to%2FsliderImages%2F3_fullscreen.jpg?alt=media`,
                original: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fseasonal_priorities%2Fgo_to%2FsliderImages%2F3_original.jpg?alt=media`,
                thumbnail: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fseasonal_priorities%2Fgo_to%2FsliderImages%2F3_thumbnail.jpg?alt=media`
              },
              {
                fullscreen: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fseasonal_priorities%2Fgo_to%2FsliderImages%2F4_fullscreen.jpg?alt=media`,
                original: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fseasonal_priorities%2Fgo_to%2FsliderImages%2F4_original.jpg?alt=media`,
                thumbnail: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fseasonal_priorities%2Fgo_to%2FsliderImages%2F4_thumbnail.jpg?alt=media`
              },
              {
                fullscreen: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fseasonal_priorities%2Fgo_to%2FsliderImages%2F5_fullscreen.jpg?alt=media`,
                original: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fseasonal_priorities%2Fgo_to%2FsliderImages%2F5_original.jpg?alt=media`,
                thumbnail: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fseasonal_priorities%2Fgo_to%2FsliderImages%2F5_thumbnail.jpg?alt=media`
              },
              {
                fullscreen: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fseasonal_priorities%2Fgo_to%2FsliderImages%2F6_fullscreen.jpg?alt=media`,
                original: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fseasonal_priorities%2Fgo_to%2FsliderImages%2F6_original.jpg?alt=media`,
                thumbnail: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fseasonal_priorities%2Fgo_to%2FsliderImages%2F6_thumbnail.jpg?alt=media`
              },
              {
                fullscreen: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fseasonal_priorities%2Fgo_to%2FsliderImages%2F7_fullscreen.jpg?alt=media`,
                original: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fseasonal_priorities%2Fgo_to%2FsliderImages%2F7_original.jpg?alt=media`,
                thumbnail: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fseasonal_priorities%2Fgo_to%2FsliderImages%2F7_thumbnail.jpg?alt=media`
              },
              {
                fullscreen: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fseasonal_priorities%2Fgo_to%2FsliderImages%2F8_fullscreen.jpg?alt=media`,
                original: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fseasonal_priorities%2Fgo_to%2FsliderImages%2F8_original.jpg?alt=media`,
                thumbnail: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fseasonal_priorities%2Fgo_to%2FsliderImages%2F8_thumbnail.jpg?alt=media`
              },
              {
                fullscreen: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fseasonal_priorities%2Fgo_to%2FsliderImages%2F9_fullscreen.jpg?alt=media`,
                original: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fseasonal_priorities%2Fgo_to%2FsliderImages%2F9_original.jpg?alt=media`,
                thumbnail: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fseasonal_priorities%2Fgo_to%2FsliderImages%2F9_thumbnail.jpg?alt=media`
              },
              {
                fullscreen: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fseasonal_priorities%2Fgo_to%2FsliderImages%2F10_fullscreen.jpg?alt=media`,
                original: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fseasonal_priorities%2Fgo_to%2FsliderImages%2F10_original.jpg?alt=media`,
                thumbnail: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fseasonal_priorities%2Fgo_to%2FsliderImages%2F10_thumbnail.jpg?alt=media`
              },
              {
                fullscreen: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fseasonal_priorities%2Fgo_to%2FsliderImages%2F11_fullscreen.jpg?alt=media`,
                original: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fseasonal_priorities%2Fgo_to%2FsliderImages%2F11_original.jpg?alt=media`,
                thumbnail: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fseasonal_priorities%2Fgo_to%2FsliderImages%2F11_thumbnail.jpg?alt=media`
              },
              {
                fullscreen: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fseasonal_priorities%2Fgo_to%2FsliderImages%2F12_fullscreen.jpg?alt=media`,
                original: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fseasonal_priorities%2Fgo_to%2FsliderImages%2F12_original.jpg?alt=media`,
                thumbnail: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fseasonal_priorities%2Fgo_to%2FsliderImages%2F12_thumbnail.jpg?alt=media`
              },
              {
                fullscreen: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fseasonal_priorities%2Fgo_to%2FsliderImages%2F13_fullscreen.jpg?alt=media`,
                original: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fseasonal_priorities%2Fgo_to%2FsliderImages%2F13_original.jpg?alt=media`,
                thumbnail: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fseasonal_priorities%2Fgo_to%2FsliderImages%2F13_thumbnail.jpg?alt=media`
              },
              {
                fullscreen: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fseasonal_priorities%2Fgo_to%2FsliderImages%2F14_fullscreen.jpg?alt=media`,
                original: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fseasonal_priorities%2Fgo_to%2FsliderImages%2F14_original.jpg?alt=media`,
                thumbnail: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fseasonal_priorities%2Fgo_to%2FsliderImages%2F14_thumbnail.jpg?alt=media`
              },
              {
                fullscreen: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fseasonal_priorities%2Fgo_to%2FsliderImages%2F15_fullscreen.jpg?alt=media`,
                original: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fseasonal_priorities%2Fgo_to%2FsliderImages%2F15_original.jpg?alt=media`,
                thumbnail: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fseasonal_priorities%2Fgo_to%2FsliderImages%2F15_thumbnail.jpg?alt=media`
              },
              {
                fullscreen: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fseasonal_priorities%2Fgo_to%2FsliderImages%2F16_fullscreen.jpg?alt=media`,
                original: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fseasonal_priorities%2Fgo_to%2FsliderImages%2F16_original.jpg?alt=media`,
                thumbnail: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fseasonal_priorities%2Fgo_to%2FsliderImages%2F16_thumbnail.jpg?alt=media`
              },
              {
                fullscreen: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fseasonal_priorities%2Fgo_to%2FsliderImages%2F17_fullscreen.jpg?alt=media`,
                original: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fseasonal_priorities%2Fgo_to%2FsliderImages%2F17_original.jpg?alt=media`,
                thumbnail: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fseasonal_priorities%2Fgo_to%2FsliderImages%2F17_thumbnail.jpg?alt=media`
              },
              {
                fullscreen: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fseasonal_priorities%2Fgo_to%2FsliderImages%2F18_fullscreen.jpg?alt=media`,
                original: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fseasonal_priorities%2Fgo_to%2FsliderImages%2F18_original.jpg?alt=media`,
                thumbnail: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fseasonal_priorities%2Fgo_to%2FsliderImages%2F18_thumbnail.jpg?alt=media`
              },
              {
                fullscreen: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fseasonal_priorities%2Fgo_to%2FsliderImages%2F19_fullscreen.jpg?alt=media`,
                original: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fseasonal_priorities%2Fgo_to%2FsliderImages%2F19_original.jpg?alt=media`,
                thumbnail: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fseasonal_priorities%2Fgo_to%2FsliderImages%2F19_thumbnail.jpg?alt=media`
              }
            ]}
          />
        </GoToImageGalleryWrapper>
        <GoToProductTextWrapper>
          <p>{i18n.language === "en" ? "PAR: 5.1.23" : "INTRO: 1ER MAI 23"}</p>
          <p>{i18n.language === "en" ? "SCRIPTED @ PGA" : "SCRIPTÉ @ PGA"}</p>
          <p>
            {i18n.language === "en"
              ? "HEAD-TO-TOE STORY WITH EASY-TO-WEAR, MODERN STYLES THAT BRING A RELAXED FEEL FOR SOCIAL ROUNDS AND BEYOND"
              : "UNE HISTOIRE DE LA TÊTE AUX PIEDS AVEC DES STYLES MODERNES ET FACILES À PORTER, QUI APPORTENT UNE SENSATION DE DÉTENTE POUR LES SORTIES SOCIALES  ET ENCORE PLUS."}
          </p>
        </GoToProductTextWrapper>
        <GoToProductSectionBottomOverlay />
      </GoToProductWrapper>
      <GoToLaydown>
        <GoToLaydownInnerSection>
          <img
            src={`https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fseasonal_priorities%2Fgo_to%2Flaydown-mens.jpg?alt=media`}
            alt=''
          />
          <div>{i18n.language === "en" ? "MEN'S" : "HOMMES"}</div>
        </GoToLaydownInnerSection>
        <GoToLaydownInnerSection>
          <div>{i18n.language === "en" ? "WOMEN'S" : "FEMMES"}</div>
          <img
            src={`https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fseasonal_priorities%2Fgo_to%2Flaydown-womens.jpg?alt=media`}
            alt=''
          />
        </GoToLaydownInnerSection>
      </GoToLaydown>
      <NavFooter />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
  padding-bottom: 100px;
`;

const CategorySelectorWrapper = styled.div`
  display: flex;
`;

const Category = styled.div`
  position: relative;
  max-width: 33%;
  padding: 30px;
  filter: drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4));

  img {
    max-width: 100%;
  }
`;

const CategorySelectorWrapperOverlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 5vw;
  filter: drop-shadow(3px 5px 2px rgb(0 0 0 / 0.8));

  img {
    max-width: 80%;
    max-height: 80%;
  }
`;

const Ult365Section = styled.div`
  /* padding: 100px 0; */
  background-color: black;
`;

const Ult365SectionHeader = styled.div`
  position: relative;
  height: 55vh;
  display: flex;
  justify-content: center;
  background-color: black;
  overflow: hidden;
  background-image: url(${({ backgroundURL }) => backgroundURL});
  background-position: center center;
  background-size: cover;

  @media screen and (max-width: 1000px) {
    height: 25vh;
  }
`;

const Ult365LogoWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
`;

const Ult365HeaderLogo = styled.svg`
  max-height: 100%;
  opacity: 0.3;
`;

const Ult365OverlayLogo = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 5vw;

  img {
    max-width: 80%;
    filter: drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4));
    padding: 50px 0;
  }
`;

const Ult365HeaderText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  font-style: italic;
  font-size: 5vw;
  padding: 30px 0;
`;

const Ult365Section1 = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 0.5fr 0.5fr;
`;

const Ult365Section1Text = styled.div`
  width: 100%;
  height: 100%;
  background-image: url(${({ backgroundURL }) => backgroundURL});
  background-position: center center;
  background-size: cover;
  padding: 10% 20%;
  color: white;
  font-size: 3vw;
  text-align: center;
  text-shadow: 3px 5px 2px rgb(0 0 0 / 0.4);
  p {
    font-family: "adiNeue PRO Condensed Regular";

    span:nth-child(1) {
      font-family: "adiNeue PRO Condensed Bold";
    }

    span:nth-child(2) {
      font-family: "adiNeue PRO Condensed Light";
    }
  }
`;

const Ult365Section1Image = styled.div`
  width: 100%;
  height: 100%;
  background-image: url(${({ backgroundURL }) => backgroundURL});
  background-position: center center;
  background-size: cover;
`;

const Ult365Section2 = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 10% 0;
`;

const Ult365Section2VideoWrapper = styled.div`
  width: 65%;
  aspect-ratio: 16 / 9;
`;

const Delivery1Header = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 30px 80px;
  background-color: white;
`;

const ImageGalleryWrapper = styled.div`
  width: 65%;
  /* margin: 0 30px; */
`;

const Delivery1HeaderTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50%;
  background-color: white;

  p:nth-child(1) {
    font-family: "adiNeue PRO Condensed Bold";
    font-size: 5vw;
    margin: 0;
    letter-spacing: 10px;
  }

  p:nth-child(2) {
    font-family: "adiNeue PRO Condensed Regular";
    font-size: 3vw;
    margin: 0;
    letter-spacing: 10px;
  }
`;

const EnergySectionWrapper = styled.div`
  width: 100%;
`;

const EnergySectionHeader = styled.div`
  position: relative;
  background-color: black;
  height: 55vh;
`;

const EnergySectionHeaderTextWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  /* text-shadow: 3px 5px 2px rgb(0 0 0 / 0.4); */
`;

const EnergySectionHeaderText = styled.div`
  font-size: 15vw;
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  background-image: url(${({ backgroundURL }) => backgroundURL});
  background-position: center center;
  background-size: cover;
  filter: drop-shadow(0px 0px 20px rgb(255 255 255 / 1));
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: white;
`;

const EnergyWomensSectionHeader = styled.div`
  position: relative;
  background-image: url(${({ backgroundURL }) => backgroundURL});
  width: 100%;
  height: 35vh;
`;

const EnergyWomensSectionHeaderBottomOverlay = styled.div`
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  height: 200px;
  background: linear-gradient(transparent, white 90%);
`;

const EnergyWomensSectionHeaderText = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgb(242, 137, 128);
  color: #ababab;
  text-shadow: 3px 3px 2px rgb(0 0 0 / 0.4);
  font-size: 10vw;
  font-family: "adiNeue PRO Condensed Regular";
`;

const EnergyWomensSayings = styled.div`
  width: 100%;
  /* height: 100px; */
  padding: 30px;
  display: flex;
  justify-content: space-around;
`;

const SayingWrapper = styled.div`
  color: rgb(242, 137, 128);
  font-size: 3vw;
`;

const EnergyWomenProductWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 20px 30px 80px;
  background-color: white;
`;

const EnergyWomenImageGalleryWrapper = styled.div`
  width: 50%;
  /* margin: 0 30px; */
`;

const EnergyWomenProductTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50%;
  padding: 5%;
  background-color: white;
  color: rgb(242, 137, 128);
  text-align: center;

  p:nth-child(1) {
    font-family: "adiNeue PRO Condensed Bold";
    font-size: 3vw;
    margin: 0;
    letter-spacing: 10px;
    padding-bottom: 15px;
  }

  p {
    font-family: "adiNeue PRO Condensed Regular";
    font-size: 2vw;
    margin: 0;
    letter-spacing: 5px;
  }

  p:nth-child(4) {
    padding-top: 15px;
  }
`;

const EnergyWomensLaydown = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 0 0 50px;

  img {
    max-width: 80%;
  }
`;

const GoToHeader = styled.div`
  position: relative;
  padding: 0 5%;
  background-color: rgb(166, 160, 152);
  display: flex;
  justify-content: center;
`;

const GoToParallaxBanner = styled(ParallaxBanner)`
  height: 65vh;
  opacity: 0.7;
  @media screen and (max-width: 500px) {
    height: 35vh;
  }
`;

const GoToHeaderText = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgb(186, 93, 107);
  text-shadow: 3px 5px 2px rgb(0 0 0 / 0.4);
  font-size: 10vw;
  font-family: "adiNeue PRO Condensed Regular";
`;

const GoToProductWrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 20px 30px 200px;
  background-color: rgb(166, 160, 152);
`;

const GoToImageGalleryWrapper = styled.div`
  width: 50%;
  /* margin: 0 30px; */
`;

const GoToProductTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50%;
  padding: 10%;
  /* background-color: white; */
  color: rgb(186, 93, 107);
  text-align: center;

  p:nth-child(1) {
    font-family: "adiNeue PRO Condensed Bold";
    font-size: 3vw;
    margin: 0;
    letter-spacing: 10px;
    padding-bottom: 15px;
  }

  p {
    font-family: "adiNeue PRO Condensed Regular";
    font-size: 2vw;
    margin: 0;
    letter-spacing: 5px;
  }

  p:nth-child(3) {
    padding-top: 15px;
  }
`;

const GoToProductSectionBottomOverlay = styled.div`
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  height: 200px;
  background: linear-gradient(transparent, white 90%);
`;

const GoToLaydown = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 0 50px;
`;

const GoToLaydownInnerSection = styled.div`
  display: flex;

  img {
    max-width: 75%;
  }

  div {
    width: 25%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 5vw;
  }

  div:nth-child(2) {
    color: rgb(166, 160, 152);
  }
  div:nth-child(1) {
    color: rgb(186, 93, 107);
  }
`;

export default SeasonalPriorities;
