// import "./App.css";
import styled from "styled-components/macro";

import { ParallaxProvider } from "react-scroll-parallax";
import FontStyles from "fonts/fontStyles";

import AuthContextProvider from "components/auth/useAuth";
import DatabaseContextProvider from "data/useDatabase";

import { Routes, Route, useLocation } from "react-router-dom";
import { useTransition, animated } from "react-spring";

import ScrollToTop from "helpers/ScrollToTop";
import PublicRoute from "routes/PublicRoute";
import InternalRoute from "routes/InternalRoute";
import Layout from "components/shared/Layout";
import Login from "components/pages/login/Login";
import Home from "components/pages/home/Home";
import MensSportPerformance from "components/pages/productPages/mensSportPerformance/MensSportPerformance";
import WomensApparel from "components/pages/productPages/womensApparel/WomensApparel";
import YouthApparel from "components/pages/productPages/youthApparel/YouthApparel";
import Accessories from "components/pages/productPages/accessories/Accessories";
import Footwear from "components/pages/productPages/footwear/Footwear";
import SeasonalPriorities from "components/pages/seasonalPriorities/SeasonalPriorities";
import Toolkit from "components/pages/toolkit/Toolkit";

const App = () => {
  const location = useLocation();
  const transitions = useTransition(location, {
    keys: (location) => location.pathname,
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: { duration: 500 }
  });

  return (
    <AuthContextProvider>
      <DatabaseContextProvider>
        <ParallaxProvider>
          <FontStyles />
          <Wrapper>
            {transitions((styles, item) => (
              <AnimatedRouteWrapper style={styles}>
                {/* <ScrollToTop /> */}
                <Routes location={item}>
                  <Route>
                    <Route index element={<Login />} />
                  </Route>
                  <Route path='/' element={<Layout />}>
                    <Route
                      path='/home'
                      element={
                        <PublicRoute>
                          <Home />
                        </PublicRoute>
                      }
                    />
                    <Route
                      path='/seasonal_priorities'
                      element={
                        <PublicRoute>
                          <SeasonalPriorities />
                        </PublicRoute>
                      }
                    />
                    <Route
                      path='/mens_sp'
                      element={
                        <PublicRoute>
                          <MensSportPerformance />
                        </PublicRoute>
                      }
                    />
                    <Route
                      path='/womens_app'
                      element={
                        <PublicRoute>
                          <WomensApparel />
                        </PublicRoute>
                      }
                    />
                    <Route
                      path='/junior_app'
                      element={
                        <PublicRoute>
                          <YouthApparel />
                        </PublicRoute>
                      }
                    />
                    <Route
                      path='/footwear'
                      element={
                        <PublicRoute>
                          <Footwear />
                        </PublicRoute>
                      }
                    />
                    <Route
                      path='/accessories'
                      element={
                        <PublicRoute>
                          <Accessories />
                        </PublicRoute>
                      }
                    />
                    <Route
                      path='/toolkit'
                      element={
                        <PublicRoute>
                          <Toolkit />
                        </PublicRoute>
                      }
                    />
                  </Route>
                </Routes>
              </AnimatedRouteWrapper>
            ))}
          </Wrapper>
        </ParallaxProvider>
      </DatabaseContextProvider>
    </AuthContextProvider>
  );
};

const Wrapper = styled.div``;

const AnimatedRouteWrapper = styled(animated.div)`
  position: absolute;
  width: 100%;
  height: 100%;
`;

export default App;
