import React, { useRef } from "react";
import styled from "styled-components/macro";
import useIntersection from "helpers/useIntersection";
import { useSpring, animated, config } from "react-spring";
import { ParallaxBanner } from "react-scroll-parallax";

const MustHaves = ({ leftImage, rightImage, title }) => {
  const ref = useRef();

  const inViewport = useIntersection(ref, "0px");

  const leftImageAnimation = useSpring({
    from: { translateX: "-100vw" },
    to: { translateX: "0" },
    config: { mass: 1, tension: 180, friction: 20 },
    pause: !inViewport,
    delay: 200
  });

  const rightImageAnimation = useSpring({
    from: { translateX: "100vw" },
    to: { translateX: "0" },
    config: { mass: 1, tension: 180, friction: 20 },
    pause: !inViewport,
    delay: 500
  });

  return (
    <Wrapper ref={ref}>
      <PictureWrapper>
        <StyledParallaxBanner
          layers={[
            {
              image: leftImage,
              speed: -15
              // scale: [0.9, 1.2, 0.9, 1.2]
            }
          ]}
        />
      </PictureWrapper>
      {/* <AnimatedLeftImage style={leftImageAnimation}>
        <img src={leftImage} alt='' />
      </AnimatedLeftImage> */}
      <Center>{title}</Center>
      <PictureWrapper>
        <StyledParallaxBanner
          layers={[
            {
              image: rightImage,
              speed: -15
              // scale: [0.9, 1.2, 0.9, 1.2]
            }
          ]}
        />
      </PictureWrapper>
      {/* <AnimatedRightImage style={rightImageAnimation}>
        <img src={rightImage} alt='' />
      </AnimatedRightImage> */}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  display: flex;
`;

const PictureWrapper = styled.div`
  width: 25%;
`;

const StyledParallaxBanner = styled(ParallaxBanner)`
  height: 60vh;

  @media screen and (max-width: 500px) {
    height: 35vh;
  }
`;

const LeftImage = styled.div`
  max-width: 35%;
  max-height: 60vh;
  img {
    max-height: 100%;
    max-width: 100%;
    /* object-fit: aspect; */
  }
`;
const AnimatedLeftImage = animated(LeftImage);
const Center = styled.div`
  flex-grow: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 6vw;
  padding: 20px;
  color: black;
  background-color: white;
  text-shadow: 2px 2px 5px rgb(0 0 0 / 0.4);
  text-transform: uppercase;
`;
const RightImage = styled.div`
  max-width: 35%;
  max-height: 60vh;
  display: flex;
  justify-content: flex-end;
  img {
    max-height: 100%;
    max-width: 100%;
    object-fit: cover;
  }
`;
const AnimatedRightImage = animated(RightImage);

export default MustHaves;
