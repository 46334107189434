import { useState, useEffect } from "react";

const useIntersection = (element, rootMargin, threshold = 0.3) => {
  const [isVisible, setState] = useState(false);

  useEffect(() => {
    const currentElement = element.current;

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.intersectionRatio >= threshold) {
          setState(true);
        } else {
          setState(false);
        }
      },
      { rootMargin, threshold }
    );

    currentElement && observer.observe(currentElement);

    return () => observer.unobserve(currentElement);
  }, [element, rootMargin, threshold]);

  return isVisible;
};

export default useIntersection;
