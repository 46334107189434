import React from "react";
import { useAuth } from "components/auth/useAuth";
import { useLocation, Navigate } from "react-router-dom";

const PublicRoute = ({ children }) => {
  const { userType } = useAuth();
  const location = useLocation();
  return userType === "public" || userType === "internal" ? (
    children
  ) : (
    <Navigate to='/' state={{ from: location }} replace />
  );
};

export default PublicRoute;
