import React, { createContext, useCallback, useState, useEffect } from "react";
import { useContext } from "react";
import { useAuth } from "components/auth/useAuth";

import sitePages from "./sitePages";
import pageData from "./pageData";

const DatabaseContext = createContext();

const DatabaseContextProvider = ({ children }) => {
  const { userType } = useAuth();

  const [pages, setPages] = useState([]);

  useEffect(() => {
    if (userType === "internal") {
      setPages(sitePages);
    } else if (userType === "public") {
      setPages(sitePages.filter((page) => page.access === "public"));
    }
  }, [userType]);

  const getPageData = useCallback(
    async (pageID) => {
      let data = pageData[pageID];
      if (userType === "public") {
        delete data.keyTakeaways;
      }
      return data;
    },
    [userType]
  );

  return (
    <DatabaseContext.Provider value={{ pages, getPageData }}>
      {children}
    </DatabaseContext.Provider>
  );
};

export default DatabaseContextProvider;

export const useDatabase = () => useContext(DatabaseContext);
