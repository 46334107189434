import React from "react";
import styled from "styled-components/macro";
import { ParallaxBanner } from "react-scroll-parallax";
import { useSpring, animated, config } from "react-spring";

const CategoryHeader = ({ imageURL, title, overlayImageURL, children }) => {
  const textAnimations = useSpring({
    from: { translateX: "-100vw" },
    to: { translateX: "0" },
    config: { mass: 1, tension: 400, friction: 22 },
    delay: 1000
  });

  const imageAnimations = useSpring({
    from: { translateX: "100vw" },
    to: { translateX: "0" },
    config: config.molasses,
    delay: 1000
  });

  return (
    <Wrapper>
      <StyledParallaxBanner layers={[{ image: imageURL, speed: -15 }]}>
        <AnimatedTextOverlay style={textAnimations}>
          {title}
        </AnimatedTextOverlay>
        <StyledImageOverlay style={imageAnimations}>
          <img src={overlayImageURL} alt='' />
        </StyledImageOverlay>
        {children}
      </StyledParallaxBanner>
    </Wrapper>
  );
};

const Wrapper = styled.div``;

const StyledParallaxBanner = styled(ParallaxBanner)`
  //Heights here include fixes for mobile
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  /* transition: height 1.5s; */
  //End of Height fixes for mobile
  display: flex;
  justify-content: center;

  div {
    background-position: top; !important;
  }
`;

const TextOverlay = styled.div`
  z-index: 2;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: white;
  opacity: 0.9;
  text-shadow: 3px 5px 2px rgb(0 0 0 / 0.4);
  /* mix-blend-mode: screen; */
  font-size: 10vw;
  text-transform: uppercase;

  @media screen and (max-width: 700px) {
    font-size: 20vw;
    align-items: flex-start;
    padding-top: 12vh;
  }
`;
const AnimatedTextOverlay = animated(TextOverlay);

const ImageOverlay = styled.div`
  position: absolute;
  right: 5vw;
  top: 3vh;
  z-index: 1;
  height: 70%;
  background-color: rgb(255 255 255 /0.7);
  img {
    height: 100%;
  }

  @media screen and (max-width: 700px) {
    top: unset;
    bottom: 10vh;
    height: 55%;
  }
`;
const StyledImageOverlay = animated(ImageOverlay);

export default CategoryHeader;
