import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";

import { useAuth } from "components/auth/useAuth";
import Header from "./Header";
import ImageMask from "components/shared/ImageMask";
import NavFooter from "components/shared/NavFooter";
import AmbilightVideo from "components/shared/AmbilightVideo";

const Home = () => {
  const { i18n } = useTranslation();
  const { userType } = useAuth();

  const divRef = useRef();
  const scrollToBottom = () => {
    window.scrollTo({
      top: divRef.current.offsetTop,
      behavior: "smooth"
    });
  };

  return (
    <Page>
      <Header onScrollClick={scrollToBottom} />
      <SizzleWrapper
        ref={divRef}
        backgroundURL={`https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fhome%2Fpage_assets%2FBrandSplashBackground.jpg?alt=media`}
      >
        <ImageMask maskOpacity={0.8} />
        <Content>
          <SizzleLeft>
            <div>{i18n.language === "en" ? "BRAND" : "COEUR DE LA"}</div>
            <div>{i18n.language === "en" ? "HEAT" : "MARQUE"}</div>
          </SizzleLeft>
          <ContentCenter>
            <VideoWrapper>
              <AmbilightVideo
                videoSrc='https://stream.mux.com/NrF4uTy1XoC1ZB1RAE885UIWbNH5DRvP4lLCbgzwVKw.m3u8'
                thumbnail='https://image.mux.com/NrF4uTy1XoC1ZB1RAE885UIWbNH5DRvP4lLCbgzwVKw/thumbnail.jpg?time=96.5'
                subtitles={[
                  {
                    kind: "subtitles",
                    label: "English",
                    src: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fcaptions%2FSS23-Brand Video_en.vtt?alt=media`,
                    srcLang: "en"
                  },
                  {
                    kind: "subtitles",
                    label: "French",
                    src: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fcaptions%2FSS23-Brand Video_fr.vtt?alt=media`,
                    srcLang: "fr",
                    default: i18n.language === "fr-CA"
                  }
                ]}
              />
            </VideoWrapper>
          </ContentCenter>
          <div></div>
        </Content>
      </SizzleWrapper>
      <IntroWrapper
        backgroundURL={`https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fhome%2Fpage_assets%2Fsizzle_background.jpg?alt=media`}
      >
        <ImageMask maskOpacity={0.8} />
        <Content>
          <IntroLeft>
            <div>{i18n.language === "en" ? "SETTING" : "MISE EN PLACE"}</div>
            <div>{i18n.language === "en" ? "EXPECTATIONS" : "ATTENTES"}</div>
          </IntroLeft>
          <ContentCenter>
            <VideoWrapper>
              {userType === "public" ? (
                <AmbilightVideo
                  videoSrc='https://stream.mux.com/8N2W6dhbGZrVURnvFHlolg01A3xillVbLx1Cpq0201TjSc.m3u8'
                  thumbnail={`https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fhome%2Fpage_assets%2Fwelcome_feltrin%20cover_2.png?alt=media`}
                  subtitles={[
                    {
                      kind: "subtitles",
                      label: "English",
                      src: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fcaptions%2Ffeltrin-video-public_en.vtt?alt=media`,
                      srcLang: "en"
                    },
                    {
                      kind: "subtitles",
                      label: "French",
                      src: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fcaptions%2Ffeltrin-video-public_fr.vtt?alt=media`,
                      srcLang: "fr",
                      default: i18n.language === "fr-CA"
                    }
                  ]}
                />
              ) : (
                <AmbilightVideo
                  videoSrc='https://stream.mux.com/G2MPShKOGdb79f601NTWmSy7nGG2StiRvGhlSc9VZiP00.m3u8'
                  thumbnail={`https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fhome%2Fpage_assets%2Fwelcome_malcs_cover.png?alt=media`}
                  subtitles={[
                    {
                      kind: "subtitles",
                      label: "English",
                      src: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fcaptions%2FMalcs_SS23_Video_en.vtt?alt=media`,
                      srcLang: "en"
                    },
                    {
                      kind: "subtitles",
                      label: "French",
                      src: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Fcaptions%2FMalcs_SS23_Video_fr.vtt?alt=media`,
                      srcLang: "fr",
                      default: i18n.language === "fr-CA"
                    }
                  ]}
                />
              )}
            </VideoWrapper>
          </ContentCenter>
          <div></div>
        </Content>
      </IntroWrapper>
      <Footer>
        <NavFooter />
      </Footer>
    </Page>
  );
};

const Page = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  padding-bottom: 100px;
`;

const SizzleWrapper = styled.div`
  position: relative;
  //Heights here include fixes for mobile
  /* height: 100vh; */
  /* height: calc(var(--vh, 1vh) * 100); */
  //End of Height fixes for mobile
  width: 100%;
  padding: 10% 0;
  background-image: url(${({ backgroundURL }) => backgroundURL});
  background-position: center center;
  background-size: cover;
`;

const IntroWrapper = styled.div`
  position: relative;
  width: 100%;
  padding: 10% 0;
  background-image: url(${({ backgroundURL }) => backgroundURL});
  background-position: center center;
  background-size: cover;
`;

const Content = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 0.2fr 0.6fr 0.2fr;
  justify-content: center;
  align-items: center;
`;

const SizzleLeft = styled.div`
  width: 100%;
  height: 100%;
  color: white;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  /* justify-content: center; */
  /* writing-mode: vertical-rl; */
  /* text-orientation: upright; */
  font-size: 3vw;
  letter-spacing: 5px;
  line-height: 3vw;
  filter: drop-shadow(3px 5px 2px rgb(0 0 0 / 0.8));
`;

const IntroLeft = styled.div`
  width: 100%;
  height: 100%;
  color: white;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  /* justify-content: center; */
  /* writing-mode: vertical-rl; */
  /* text-orientation: upright; */
  font-size: 3vw;
  letter-spacing: 5px;
  line-height: 3vw;
  filter: drop-shadow(3px 5px 2px rgb(0 0 0 / 0.8));
`;

const ContentCenter = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const VideoWrapper = styled.div`
  /* margin-top: 40px; */
  /* height: 60vh; */
  width: 100%;
  aspect-ratio: 16 / 9;
  /* background-color: black; */
  z-index: 3;

  .react-player__preview {
    opacity: 0.9;
  }

  img {
    max-width: 100%;
    max-height: 100%;
    aspect-ratio: 16 / 9;
    opacity: 0.9;
  }
`;

const Footer = styled.div`
  position: relative;
  width: 100vw;
  height: 100px;
  overflow: hidden;
`;

export default Home;
