import { useEffect, useState } from "react";

const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  });

  const throttle = (fn, wait) => {
    let inThrottle, lastFn, lastTime;
    return function () {
      const context = this,
        args = arguments;
      if (!inThrottle) {
        fn.apply(context, args);
        lastTime = Date.now();
        inThrottle = true;
      } else {
        clearTimeout(lastFn);
        lastFn = setTimeout(function () {
          if (Date.now() - lastTime >= wait) {
            fn.apply(context, args);
            lastTime = Date.now();
          }
        }, Math.max(wait - (Date.now() - lastTime), 0));
      }
    };
  };

  useEffect(() => {
    const throttleChangeWindowSize = () => {
      throttle(
        setWindowSize({ width: window.innerWidth, height: window.innerHeight }),
        200
      );
    };

    window.addEventListener("resize", throttleChangeWindowSize);

    return () => {
      window.removeEventListener("resize", throttleChangeWindowSize);
    };
  }, []);

  return windowSize;
};

export default useWindowSize;
