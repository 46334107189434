import React, { forwardRef } from "react";

import styled from "styled-components/macro";
import {
  COLORS,
  FONTSIZE,
  FONTWEIGHT,
  CONSTANTS
} from "helpers/designConstants";

//PROPS: labelText, type, id, name, placeholder, marginBottom, className
const LabelledTextInput = forwardRef(
  (
    {
      labelText,
      type,
      id,
      name,
      capitalize,
      placeholder,
      marginBottom,
      className,
      shouldAutoFocus = false,
      value,
      ...props
    },
    ref /*from react-hook-forms*/
  ) => {
    return (
      <Wrapper className={className}>
        <StyledLabel htmlFor={id}>{labelText}</StyledLabel>
        <StyledTextInput
          {...props}
          autoFocus={shouldAutoFocus}
          marginBottom={marginBottom}
          type={type}
          id={id}
          name={name}
          value={value}
          capitalize={capitalize}
          placeholder={placeholder}
          ref={ref}
          {...props}
        ></StyledTextInput>
      </Wrapper>
    );
  }
);

LabelledTextInput.defaultProps = {
  type: "text"
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const StyledLabel = styled.label`
  font-family: "adiNeue PRO Condensed Bold";
  text-transform: uppercase;
  font-size: ${FONTSIZE.large};
  font-weight: ${FONTWEIGHT.medium};
  color: ${COLORS.primaryInverted};
  padding-left: 5px;
`;

const TextInput = styled.input.attrs({})`
  /* border: 1px solid ${COLORS.tertiary}; */
  font-family: "adineuePRORegularWeb";
  letter-spacing: 5px;
  border: 1px solid ${COLORS.primaryInverted};
  border-radius: ${CONSTANTS.borderRadius};
  padding: 10px;
  /* background-color: black; */
  background-color: ${COLORS.primaryInverted};
  font-size: ${FONTSIZE.large};
  color: darkolivegreen;
  -webkit-appearance: none;

  &:focus {
    outline: none !important;
    /* border: 1px solid ${COLORS.text}; */
  }

  &::placeholder {
    opacity: 40%;
  }
`;

const StyledTextInput = styled(TextInput)`
  margin-bottom: ${(props) => (props.marginBottom ? props.marginBottom : null)};
  text-transform: ${(props) => (props.capitalize ? "capitalize" : "none")};
  box-shadow: 3px 2px 5px rgb(0 0 0 / 0.4);
`;

export default LabelledTextInput;
