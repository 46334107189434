import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";
import CustomImageGallery from "components/shared/imageGallery/CustomImageGallery";
import useIntersection from "helpers/useIntersection";
import { useSpring, animated } from "react-spring";

const ProductCard = ({ pageID, product, index = 0 }) => {
  const { i18n } = useTranslation();
  const ref = useRef();
  const inViewport = useIntersection(ref, "0px");

  const detailsAnimation = useSpring({
    from: { translateX: index % 2 === 0 ? "-100vw" : "100vw" },
    to: { translateX: "0" },
    pause: !inViewport,
    delay: 200
  });

  const imageGalleryAnimation = useSpring({
    from: { translateX: index % 2 === 0 ? "100vw" : "-100vw" },
    to: { translateX: "0" },
    pause: !inViewport,
    delay: 500
  });

  return (
    <Wrapper ref={ref}>
      <AnimatedDetails style={detailsAnimation} index={index}>
        <Name>{i18n.language === "en" ? product.name : product.name_fr}</Name>
        {product.groupedItemDetails != null ? (
          <>
            {product.groupedItemDetails.map((detail) => (
              <GroupedDetails key={detail.text}>
                {i18n.language === "en" ? detail.text : detail.text_fr}
              </GroupedDetails>
            ))}
          </>
        ) : (
          <>
            <IdPrice>{product.IdPrice}</IdPrice>
            <Sizes>
              {i18n.language === "en" ? product.sizes : product.sizes_fr}
            </Sizes>
            <Description>
              {i18n.language === "en"
                ? product.description
                : product.description_fr}
            </Description>
            {i18n.language === "en" ? (
              <KeyPoints>
                {product.keyPoints.map((point) => (
                  <li key={point}>{point}</li>
                ))}
              </KeyPoints>
            ) : (
              <KeyPoints>
                {product.keyPoints_fr.map((point) => (
                  <li key={point}>{point}</li>
                ))}
              </KeyPoints>
            )}
          </>
        )}
      </AnimatedDetails>
      <AnimatedImageGalleryWrapper index={index}>
        {/* <CustomImageGallery items={product.images} /> */}
        <CustomImageGallery
          items={[...Array(product.imageCount)].map((item, index) => ({
            fullscreen: `https://firebasestorage.googleapis.com/v0/b/${
              process.env.REACT_APP_FIREBASE_STORAGE_ROOT
            }.appspot.com/o/sho%2F${pageID}%2Farticle_assets%2F${
              product.articleCode
            }_${index + 1}_fullscreen.jpg?alt=media`,
            original: `https://firebasestorage.googleapis.com/v0/b/${
              process.env.REACT_APP_FIREBASE_STORAGE_ROOT
            }.appspot.com/o/sho%2F${pageID}%2Farticle_assets%2F${
              product.articleCode
            }_${index + 1}_original.jpg?alt=media`,
            thumbnail: `https://firebasestorage.googleapis.com/v0/b/${
              process.env.REACT_APP_FIREBASE_STORAGE_ROOT
            }.appspot.com/o/sho%2F${pageID}%2Farticle_assets%2F${
              product.articleCode
            }_${index + 1}_thumbnail.jpg?alt=media`
          }))}
        />
      </AnimatedImageGalleryWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  /* margin: 30px; */
  margin: 30px 10vw;
  padding: 50px 0 70px;
  display: flex;
  /* align-items: center; */

  :not(:last-child) {
    border-bottom: 1px solid black;
  }

  @media screen and (max-width: 900px) {
    flex-direction: column;
    align-items: center;
    margin: 0px 15px;
  }
`;

const Details = styled.div`
  order: ${({ index }) => (index % 2 === 0 ? 0 : 1)};
  max-width: 40%;

  @media screen and (max-width: 700px) {
    max-width: unset;
    order: 0;
  }
`;
const AnimatedDetails = animated(Details);

const Name = styled.div`
  font-family: "adiNeue PRO Condensed Bold";
  font-size: 30px;
`;

const GroupedDetails = styled.div`
  font-family: "adiNeue PRO Condensed Regular";
  font-size: 25px;
  padding: 15px 0;
`;

const IdPrice = styled.div`
  font-family: "adiNeue PRO Condensed Regular";
  font-size: 25px;
`;
const Sizes = styled.div`
  font-family: "adiNeue PRO Condensed Regular";
  font-size: 25px;
`;

const Description = styled.div`
  font-family: "adineuePROLightWeb";
  font-size: 16px;
`;
const KeyPoints = styled.ul`
  font-family: "adineuePROLightWeb";
  padding: 0 25px;

  li {
    font-family: "adineuePROLightWeb";
    font-size: 16px;
  }
`;

const ImageGalleryWrapper = styled.div`
  order: ${({ index }) => (index % 2 === 0 ? 1 : 0)};
  width: 60%;
  margin: 0 30px;

  @media screen and (max-width: 900px) {
    width: 100%;
    margin: unset;
    order: 1;
  }
`;
const AnimatedImageGalleryWrapper = animated(ImageGalleryWrapper);

export default ProductCard;
